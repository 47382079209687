/* eslint-disable no-unused-vars */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import Icon from '@ant-design/icons'
import { faBuilding } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Menu, Dropdown, Tabs } from 'antd'
import { ActionSheet, Button, Dialog, Space, Toast } from 'antd-mobile'
import type {
  Action,
  ActionSheetShowHandler,
} from 'antd-mobile/es/components/action-sheet'
import { checkContractRequest } from 'store/modules/contract/actions'
import { setUnitSelected } from 'store/modules/unitSelected/actions'

const { TabPane } = Tabs

function DropdownMobile() {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const unitSelected = useSelector(state => state.unitSelected.data)
  const allUnits = useSelector(state => state.units.data)

  function formatUnitLabel(unit) {
    if (unit) {
      return `${unit.plt_codigo} - ${unit.ele_descri}`
    }

    return null
  }

  function getUnit(pltCode) {
    return allUnits.find(u => u.plt_codigo === pltCode)
  }

  async function handleChangeUnit(option) {
    dispatch(setUnitSelected(getUnit(option.key)))
    dispatch(checkContractRequest())

    history.push('/')
  }

  const menuUnit = (
    <Menu onClick={handleChangeUnit}>
      {allUnits.map(item => (
        <Menu.Item
          key={item.plt_codigo}
          style={{
            background: unitSelected === item.plt_codigo ? '#e6f7ff' : '#fff',
            fontWeight: unitSelected === item.plt_codigo ? 'bold' : 'normal',
          }}
        >
          {formatUnitLabel(item)}
        </Menu.Item>
      ))}
    </Menu>
  )

  return (
    <>
      <Icon
        data-testid="dropdown-units"
        onClick={() => setDropdownOpen(true)}
        style={{
          fontSize: 18,
          lineHeight: '64px',
          alignSelf: 'center',
          cursor: 'pointer',
          padding: '0 15px',
        }}
        component={() => <FontAwesomeIcon icon={faBuilding} />}
      />

      <ActionSheet
        visible={dropdownOpen}
        actions={allUnits.map(item => ({
          key: item.plt_codigo,
          text: formatUnitLabel(item),
        }))}
        onClose={() => setDropdownOpen(false)}
        onAction={handleChangeUnit}
      />
    </>
  )
}

export default DropdownMobile
