import React from 'react'
import ReactDOM from 'react-dom'

import { App as CapacitorApp } from '@capacitor/app'
import { SplashScreen } from '@capacitor/splash-screen'
import { CapacitorUpdater } from '@capgo/capacitor-updater'

import api from 'services/api'

import App from './App'
import * as serviceWorker from './serviceWorker'

// const whyDidYouRender = require('@welldone-software/why-did-you-render')

// whyDidYouRender(React, {
//   trackAllPureComponents: true,
// })

CapacitorUpdater.notifyAppReady()
CapacitorApp.addListener('appStateChange', async state => {
  let version
  if (state.isActive) {
    try {
      const response = await api.get('/miscellaneous/signals/healthcheck')
      const newVersion = response.data?.version
      const currentVersion = await CapacitorUpdater.getBuiltinVersion() // Get the current app version

      if (newVersion && newVersion !== currentVersion.version) {
        // Ensure download occurs while the app is active, or download may fail
        version = await CapacitorUpdater.download({
          version: newVersion,
          url: `https://intelup-version.s3.amazonaws.com/${newVersion}/com.intelup.mobile.zip`,
        })
      }
    } catch (error) {
      console.error('Healthcheck failed:', error)
    }
  }

  // Activate the update when the application is sent to background
  if (!state.isActive && version) {
    SplashScreen.show()
    try {
      await CapacitorUpdater.set(version)
      // At this point, the new version should be active, and will need to hide the splash screen
      SplashScreen.hide()
    } catch (Exception) {
      console.log(Exception)
      SplashScreen.hide() // Hide the splash screen again if something went wrong
    }
  }
})

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
