import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
// import PropTypes from 'prop-types'

import { Table, Switch, Select, Input, Space, message } from 'antd'
import PropTypes from 'prop-types'

import {
  links,
  configLinks,
} from 'components/Layout/Sidebar/Components/Menu/links'
import useAccessControlMenu from 'components/Layout/Sidebar/Components/Menu/useAccessControlMenu'

import axiosClient from 'services/api'

const { Option, OptGroup } = Select

const UnitAccess = ({ unit, user, setList }) => {
  const [accessControl, setAccessControl] = useState({})
  const [accessControlID, setAccessControlID] = useState(null)
  const [initialPage, setInitialPage] = useState(null)
  const [dashboardList, setDashboardList] = useState([])
  const [mainRoutes, setMainRoutes] = useState(links)
  const [loading, setLoading] = useState(false)

  const intl = useIntl()

  const { newMainRoutes } = useAccessControlMenu({
    hasAccess: user?.usu_tipo === '1',
    accessControl,
    mainRoutes,
    configRoutes: configLinks,
  })

  useEffect(() => {
    async function getDashboardItems() {
      const response = await axiosClient
        .get(`GDV/entities/ALLVISAO/${user?.id}`)
        .then(resp => resp.data)

      setDashboardList(response.data)
    }
    if (user) {
      getDashboardItems()
    }
  }, [user])

  useEffect(() => {
    let childrenDashboard = []

    childrenDashboard = [
      ...childrenDashboard,
      ...dashboardList.map(link => ({
        id: `/dashboard/${link.id_visao}`,
        label: link.vis_descricao || `Dashboard #${link.id_visao}`,
        exact: true,
        activeClassName: 'active',
        to: {
          pathname: `/dashboard/${link.id_visao}`,
        },
      })),
    ]

    setMainRoutes(prevState =>
      prevState.map(currentMain => {
        if (currentMain.id === '/dashboard') {
          return {
            ...currentMain,
            children: childrenDashboard,
          }
        }
        return currentMain
      })
    )
  }, [dashboardList])

  useEffect(() => {
    setLoading(true)

    async function getData() {
      await axiosClient
        .post(`CAC/queries/accessControl`, {
          id: user.id_usuario,
          plt: unit.plt_codigo,
        })
        .then(resp => resp.data)
        .then(response => setAccessControl(response.data))

      await axiosClient
        .get(`CAC/entities/ELEMENTO/${user.id_usuario}`)
        .then(resp => resp.data)
        .then(response => {
          const currentUnit = response.data.find(
            i => i.plt_codigo === unit.plt_codigo
          )
          setAccessControlID(currentUnit?.id)
          setInitialPage(currentUnit?.initial_page)
        })

      setLoading(false)
    }

    getData()
  }, [unit, user])

  useEffect(() => {
    if (initialPage) {
      axiosClient.put(`CAC/entities/ELEMENTO/${accessControlID}`, {
        initial_page: initialPage,
      })
    }
  }, [accessControlID, initialPage])

  const handleProfileChange = async (value, row) => {
    try {
      if (value) {
        await axiosClient.post(`CAC/entities/PERFIL_USU`, {
          id_usuario: user.id_usuario,
          id_perfil: row.id_perfil,
        })
      } else {
        await axiosClient.put(`CAC/entities/PERFIL_USU`, {
          id_usuario: user.id_usuario,
          id_perfil: row.id_perfil,
        })
      }

      const newUnit = {
        ...unit,
        children: [
          ...unit.children.filter(r => r.id_perfil !== row.id_perfil),
          { ...row, selected: value },
        ],
      }

      const childrenIsSelected = newUnit.children.some(elem => elem.selected)

      if (!accessControlID) {
        await axiosClient.post(`CAC/entities/ELEMENTO`, {
          id_usuario: user.id_usuario,
          id_elemento: unit.id_elemento,
        })
      } else if (!childrenIsSelected) {
        await axiosClient.put(`CAC/queries/ELEMENTO`, {
          id_usuario: user.id_usuario,
          id_elemento: unit.id_elemento,
        })
      }

      setList(prevList => [
        ...prevList.filter(l => l.key !== newUnit.key),
        {
          ...newUnit,
          selected: childrenIsSelected,
        },
      ])
    } catch (error) {
      message.error(
        intl.formatMessage({ id: 'global.error.messages.defaultError' })
      )
    }
  }

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Input.Group compact>
        <Input
          style={{ width: '20%' }}
          defaultValue={intl.formatMessage({
            id: 'accessControl.initialPage',
          })}
          disabled
        />
        <Select
          value={initialPage}
          style={{ width: '80%' }}
          onChange={setInitialPage}
        >
          {newMainRoutes.map(menu => {
            if (menu.children) {
              return (
                <OptGroup label={intl.formatMessage({ id: menu.label })}>
                  {menu.children.map(subMenu => (
                    <Option value={subMenu.to.pathname}>
                      {intl.formatMessage({ id: subMenu.label })}
                    </Option>
                  ))}
                </OptGroup>
              )
            }

            return (
              <Option value={menu.to.pathname}>
                {intl.formatMessage({ id: menu.label })}
              </Option>
            )
          })}
        </Select>
      </Input.Group>
      <Table
        size="small"
        dataSource={unit.children.sort((a, b) => a.id_perfil - b.id_perfil)}
        columns={[
          {
            title: intl.formatMessage({ id: 'global.labels.profile' }),
            dataIndex: 'per_descri',
            key: 'per_descri',
          },
          {
            title: intl.formatMessage({ id: 'global.button.edit' }),
            dataIndex: 'selected',
            key: 'selected',
            render: (text, row) => (
              <Switch
                loading={loading}
                checked={row.selected}
                onChange={value => {
                  handleProfileChange(value, row)
                }}
              />
            ),
          },
        ]}
      />
    </Space>
  )
}

UnitAccess.propTypes = {
  setList: PropTypes.func.isRequired,
  unit: PropTypes.shape({
    children: PropTypes.shape({
      filter: PropTypes.func,
      sort: PropTypes.func,
    }),
    id_elemento: PropTypes.number,
    plt_codigo: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    id_usuario: PropTypes.number,
    usu_tipo: PropTypes.string,
  }).isRequired,
}

export default UnitAccess
