import { takeLatest, call, put, all } from 'redux-saga/effects'

import api from 'services/api'

import { updateProfileSuccess, updateProfileFailure } from './actions'

export function* updateProfile({ payload }) {
  try {
    const { name, email, oldPassword, password, confirmPassword } = payload

    const { data } = yield call(api.put, '/users', {
      name,
      email,
      oldPassword: String(oldPassword).length ? oldPassword : undefined,
      password: String(password).length ? password : undefined,
      confirmPassword,
    })

    yield put(updateProfileSuccess(data))
  } catch (error) {
    yield put(updateProfileFailure())
  }
}

export default all([takeLatest('@profile/UPDATE_REQUEST', updateProfile)])
