import isUnixTimestamp from "./isUnixTimeStamp";

const flattenObject = (nestedObjects, prefix = '') => {
    if (!nestedObjects || typeof nestedObjects !== 'object') {
    return {};
  }
  if (nestedObjects === null) {
    return {}
  }
  return Object.keys(nestedObjects).reduce((properties, key) => {
    const value = nestedObjects[key]
    const prefixedKey = prefix ? `${prefix}.${key}` : key
    const isUnix = isUnixTimestamp(value)

    if (typeof value === 'string' || isUnix) {
      Object.assign(properties, { [prefixedKey]: `${value}` })
    } else {
       Object.assign(properties, flattenObject(value, prefixedKey))
    }
    return properties
  }, {})
}

export default flattenObject
