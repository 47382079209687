import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import {
  NotificationOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Badge, notification, Button } from 'antd'
import moment from 'moment'
import Modal from 'pages/alert/Components/Modal'

import useAccessControl from 'hooks/useAccessControl'

import axiosClient from 'services/api'
import history from 'services/history'

import Content from './Content'
import { StyledPopover } from './styles'

const AlertHeader = () => {
  const user = useSelector(state => state.user.profile)

  const intl = useIntl()

  const acl = useAccessControl('operational-alerts')

  const [items, setItems] = useState([])
  const [currentAlert, setCurrentAlert] = useState({})
  const [visible, setVisible] = useState(false)
  const [visiblePopover, setVisiblePopover] = useState(false)

  const setItemViewed = item => {
    axiosClient.get(
      `ALE/queries/updateAlertReadByUser/${item.id_mvu}?userID=${user.id_usuario}`
    )

    setItems(prevItems =>
      prevItems.map(i => {
        if (i.id_mvu === item.id_mvu) {
          return {
            ...i,
            dt_visualizado: moment().format('DD/MM/YYYY HH:mm:ss'),
          }
        }

        return i
      })
    )
  }

  const updateAlert = () => {
    if (user?.id_usuario) {
      axiosClient
        .get(`ALE/queries/lastNotifications/${user?.id_usuario}`)
        .then(resp => {
          setItems(resp.data.data)

          const popupAlert = resp.data.data.find(
            dt => !dt.dt_visualizado && dt.ale_exibe_popup === 'S'
          )

          if (popupAlert) {
            const key = 'Pop-up alert notification'
            const btn = (
              <>
                {popupAlert.ale_redireciona === 'S' && (
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => {
                      // setItemViewed(popupAlert)
                      history.push(
                        `/equipment-management/stopping-point?idFrame=${popupAlert.id_frame}`
                      )
                      notification.close(key)
                    }}
                    style={{ marginRight: 5 }}
                  >
                    {intl.formatMessage({ id: 'global.button.show' })}
                  </Button>
                )}
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    setItemViewed(popupAlert)
                    notification.close(key)
                  }}
                >
                  {intl.formatMessage({ id: 'global.labels.close' })}
                </Button>
              </>
            )

            notification.open({
              message: popupAlert.mov_titulo,
              description: popupAlert.mov_msg_alerta,
              icon: (
                <ExclamationCircleOutlined
                  style={{ color: '#FFF000', fontSize: 30 }}
                />
              ),
              style: { width: 450 },
              btn,
              key,
              duration: 0,
            })
          }
        })
    }
  }

  useEffect(() => {
    const seconds = 60
    updateAlert()
    const interval = setInterval(updateAlert, 1000 * seconds)

    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const toggleModal = (v = undefined) =>
    setVisible(prevVisible => v && !prevVisible)

  const handleCurrentAlert = item => {
    setVisible(true)
    setVisiblePopover(false)
    setCurrentAlert(item)
    setItems(prevItems =>
      prevItems.map(i => {
        if (i.id_mvu === item.id_mvu) {
          return {
            ...i,
            dt_visualizado: moment().format('DD/MM/YYYY HH:mm:ss'),
          }
        }

        return i
      })
    )
  }

  const activityCount = items.filter(item => !item.dt_visualizado).length

  return (
    <span id="activity" style={{ paddingTop: 5, margin: '0px 15px' }}>
      <Badge style={{ boxShadow: 'none' }} count={activityCount}>
        <StyledPopover
          content={<Content items={items} currentAlert={handleCurrentAlert} />}
          title={intl.formatMessage({
            id: 'components.layout.header.alert.notification',
          })}
          trigger="click"
          placement="bottom"
          getPopupContainer={() =>
            document.getElementById('divScrollAlertHeader')
          }
          visible={visiblePopover}
          onVisibleChange={setVisiblePopover}
        >
          <div id="divScrollAlertHeader" />
          <NotificationOutlined
            style={{
              fontSize: 18,
              alignSelf: 'center',
              cursor: 'pointer',
              transition: 'color 0.3s',
            }}
          />
        </StyledPopover>
      </Badge>
      {visible && (
        <Modal
          visible={visible}
          currentAlert={currentAlert}
          toggleModal={toggleModal}
          items={items}
          setItemViewed={setItemViewed}
          acl={acl}
        />
      )}
    </span>
  )
}

export default AlertHeader
