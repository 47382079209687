import React, { useCallback, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { Capacitor } from '@capacitor/core'
import { Form } from 'antd'
import adalProvider from 'pages/auth/ADFS/adalProvider'
import PropTypes from 'prop-types'
import {
  signInRequest,
  signInRequestADFS,
  cleanError,
} from 'store/modules/auth/actions'
import { changeCurrentTheme } from 'store/modules/theme/actions'

import useLocalStorage from 'hooks/useLocalStorage'

import axiosClient from 'services/api'

import DesktopView from './Desktop.view'
import MobileView from './Mobile.view'

const Signin = () => {
  const [form] = Form.useForm()
  const location = useLocation()
  const dispatch = useDispatch()
  const intl = useIntl()
  const whiteLabel = useSelector(state => state.whiteLabel)
  const [loading, errorMessage, attempt] = useSelector(state => [
    state.auth.loading,
    state.auth.error,
    state.auth.attempt,
  ])

  const [adfs, setAdfs] = useLocalStorage('persist:intelup@adfs', null)

  const loginADFS = () => {
    adalProvider.login()
  }

  async function handleSubmit(values) {
    dispatch(signInRequest(values.username, values.password))
  }

  useEffect(() => {
    if (adfs && Object.keys(adfs).length) {
      adalProvider.init({
        instance: adfs.instance,
        tenant: adfs.tenant,
        clientId: adfs.clientId,
        cacheLocation: 'localStorage',
      })

      if (adalProvider.getUser()) {
        const {
          profile: { upn, oid, email, unique_name },
        } = adalProvider.getUser()

        dispatch(
          signInRequestADFS(upn || email || unique_name, oid, adfs.cli_codigo)
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adfs])

  useEffect(() => {
    const values = new URLSearchParams(location.search)

    if (values.has('domain')) {
      axiosClient
        .get(`ADFS/queries/DOMAIN?domain=${values.get('domain')}`)
        .then(resp => resp.data)
        .then(resp => setAdfs(resp))
    }

    if (window.location.hash) {
      adalProvider._adal.saveTokenFromHash(
        adalProvider._adal.getRequestInfo(window.location.hash)
      )
      window.location = window.location.origin
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search])

  useEffect(() => {
    dispatch(cleanError())
  }, [dispatch])

  const directLogin = useCallback(() => {
    const values = new URLSearchParams(location.search)
    const username = values.get('user')
    const password = values.get('password')
    const hash = values.get('hash')

    values.set('user', null)
    values.set('password', null)
    values.set('hash', null)

    if (
      hash === 'da39a3ee5e6b4b0d3255bfef95601890afd80709' &&
      username &&
      password
    ) {
      dispatch(signInRequest(username, password))
    }
  }, [dispatch, location.search])

  useEffect(() => {
    directLogin()
  }, [directLogin])

  useEffect(() => {
    const vars = {
      '@primary-color': whiteLabel.color,
    }

    window.less.modifyVars(vars)
    dispatch(changeCurrentTheme('light'))
  }, [whiteLabel.color, dispatch])

  useEffect(() => {
    if (errorMessage) {
      form.setFieldsValue({ password: '' })
    }
  }, [form, errorMessage])

  return Capacitor.isNativePlatform() || window._env_?.CAPACITOR_DEBUG ? (
    <MobileView
      intl={intl}
      errorMessage={errorMessage}
      whiteLabel={whiteLabel}
      loading={loading}
      loginADFS={loginADFS}
      form={form}
      handleSubmit={handleSubmit}
      adfs={adfs}
      attempt={attempt}
    />
  ) : (
    <DesktopView
      intl={intl}
      errorMessage={errorMessage}
      whiteLabel={whiteLabel}
      loading={loading}
      loginADFS={loginADFS}
      form={form}
      handleSubmit={handleSubmit}
      adfs={adfs}
      attempt={attempt}
    />
  )
}

Signin.defaultProps = {
  user: null,
}

Signin.propTypes = {
  user: PropTypes.shape({}),
}

export default Signin
