import { takeLatest, call, put, all } from 'redux-saga/effects'

import api from 'services/api'

import { unitsSuccess, unitsFailure } from './actions'

export function* unitsRequest() {
  try {
    const { data } = yield call(api.get, 'EST/entities/ELEMENTO')

    yield put(unitsSuccess(data))
  } catch (err) {
    yield put(
      unitsFailure(
        err.message ||
          'Houve um problema com a api, por favor, entre em contato conosco e ajude-nos a resolver.'
      )
    )
  }
}

export default all([takeLatest('@units/UNITS_REQUEST', unitsRequest)])
