import { all } from 'redux-saga/effects'

import accessControl from './accessControl/sagas'
import auth from './auth/sagas'
import contract from './contract/sagas'
import dateConfig from './dateConfig/sagas'
import frames from './frames/sagas'
import rehydrate from './rehydrate/sagas'
import tags from './tags/sagas'
import units from './units/sagas'
import unitSelected from './unitSelected/sagas'
import user from './user/sagas'
import whiteLabel from './whiteLabel/sagas'

export default function* rootSaga() {
  return yield all([
    rehydrate,
    auth,
    user,
    units,
    accessControl,
    contract,
    whiteLabel,
    unitSelected,
    dateConfig,
    tags,
    frames,
  ])
}
