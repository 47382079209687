export function setAllTags(data) {
  return {
    type: '@tags/SET_ALL',
    payload: { data },
  }
}

export function setAllTagsFailure(error) {
  return {
    type: '@tags/SET_ALL_FAILURE',
    payload: { error },
  }
}

export function setUnitTags(data) {
  return {
    type: '@tags/SET_UNIT_TAGS',
    payload: { data },
  }
}

export function setUnitTagsFailure(error) {
  return {
    type: '@tags/SET_UNIT_TAGS_FAILURE',
    payload: { error },
  }
}

export function setSpcTags(data) {
  return {
    type: '@tags/SET_SPC_TAGS',
    payload: { data },
  }
}

export function setAllSpcTags(data) {
  return {
    type: '@tags/SET_ALL_SPC_TAGS',
    payload: { data },
  }
}

export function getAllTags() {
  return {
    type: '@tags/GET_ALL',
  }
}

export function getAllTagsFailure(error) {
  return {
    type: '@tags/GET_ALL_FAILURE',
    payload: { error },
  }
}

export function getUnitTags() {
  return {
    type: '@tags/GET_UNIT_TAGS',
  }
}

export function getUnitTagsFailure(error) {
  return {
    type: '@tags/GET_UNIT_TAGS_FAILURE',
    payload: { error },
  }
}

export function getSpcTags() {
  return {
    type: '@tags/GET_SPC_TAGS',
  }
}

export function getAllSpcTags() {
  return {
    type: '@tags/GET_ALL_SPC_TAGS',
  }
}

export function updateTags(data) {
  return {
    type: '@tags/UPDATE',
    payload: { data },
  }
}

export function updateTagsFailure(error) {
  return {
    type: '@tags/UPDATE_FAILURE',
    payload: { error },
  }
}

export function addTags(data) {
  return {
    type: '@tags/ADD',
    payload: { data },
  }
}

export function addTagsFailure(error) {
  return {
    type: '@tags/ADD_FAILURE',
    payload: { error },
  }
}

export function deleteTags(data) {
  return {
    type: '@tags/UPDATE',
    payload: { data },
  }
}

export function deleteTagsFailure(error) {
  return {
    type: '@tags/DELETE_FAILURE',
    payload: { error },
  }
}
