/* eslint-disable react/prop-types */

import React from 'react'

import { SafeArea } from 'antd-mobile'

import Notification from 'utils/notification'

import BaseHeader from './Header'
import Sidebar from './Sidebar'

const MobileView = ({ children }) => (
  <div
    style={{
      height: '100vh',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div style={{ background: '#ace0ff' }}>
      <SafeArea position="top" />
    </div>
    <div
      style={{
        flex: 0,
        borderBottom: 'solid 1px var(--adm-color-border)',
      }}
    >
      <BaseHeader />
    </div>
    <div
      style={{
        flex: 1,
        display: 'flex',
        overflow: 'auto',
        background: '#fafbfc',
      }}
    >
      <Notification />
      {children}
    </div>
    <div
      style={{
        flex: 0,
        borderTop: 'solid 1px var(--adm-color-border)',
      }}
    >
      <Sidebar />
    </div>
    <div style={{ background: '#ffcfac' }}>
      <SafeArea position="bottom" />
    </div>
  </div>
)

export default MobileView
