import { REHYDRATE } from 'redux-persist/lib/constants'
import {
  takeLatest,
  takeLeading,
  call,
  put,
  all,
  select,
  delay,
  fork,
} from 'redux-saga/effects'

import api from 'services/api'

import {
  setAllFramesFailure,
  setAllFrames,
  setUnitFrames,
  getAllFrames,
} from './actions'
import { getUnitSelected } from './selectors'

export function* checkTick() {
  const minutes = 15
  const minutesToMs = m => m * 60 * 1000

  while (true) {
    yield delay(minutesToMs(minutes))
    yield put(getAllFrames())
  }
}

function* checkTimer() {
  // starts the task in the background
  yield fork(checkTick)
}

export function* getFrames() {
  try {
    const unitSelected = yield select(getUnitSelected)
    let data = null

    if (api.defaults.headers.common.Authorization) {
      const response = yield call(api.get, 'EM/entities/FRAME')

      data = response.data.data

      if (data) {
        yield put(setAllFrames(data))

        yield put(
          setUnitFrames(
            data.filter(frame => frame.element?.plt_codigo === unitSelected)
          )
        )
      }
    }
  } catch (err) {
    yield put(
      setAllFramesFailure(
        err.message ||
          'Houve um problema com a api, por favor, entre em contato conosco e ajude-nos a resolver.'
      )
    )
  }
}

export default all([
  takeLatest('@frames/GET_ALL', getFrames),
  takeLeading(REHYDRATE, checkTimer),
])
