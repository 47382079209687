/* eslint-disable react/prop-types */
import React from 'react'

import { Preferences } from '@capacitor/preferences'
import {
  SafeArea,
  Button,
  Form,
  Input,
  Card,
  Image,
  Space,
  Result,
} from 'antd-mobile'

import axiosClient from 'services/api'

const MobileView = ({ intl, errorMessage, loading, handleSubmit, attempt }) => {
  const onFinish = async values => {
    window._env_.ENV_API_URL = values.apiUrl
    axiosClient.defaults.baseURL = `${values.apiUrl}/intelup/`

    localStorage.setItem('CapacitorStorage.apiUrl', values.apiUrl)

    await Preferences.set({
      key: 'apiUrl',
      value: values.apiUrl,
    })

    handleSubmit(values)
  }

  return (
    <div>
      <div style={{ background: '#ace0ff' }}>
        <SafeArea position="top" />
      </div>
      <Space justify="center" block>
        <Image
          src={`${process.env.PUBLIC_URL}img/logo.png`}
          alt="Logo"
          width={250}
          height={100}
          fit="contain"
        />
      </Space>
      <Card>
        <Form
          onFinish={onFinish}
          layout="horizontal"
          mode="card"
          footer={
            <>
              <Button
                block
                type="submit"
                htmlType="submit"
                size="large"
                color="primary"
                className="login-form-button"
                loading={loading}
              >
                {intl.formatMessage({ id: 'global.button.enter' })}
              </Button>
            </>
          }
        >
          <Form.Header>Login</Form.Header>
          <Form.Item
            name="apiUrl"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'auth.signin.apiUrl',
                }),
              },
            ]}
          >
            <Input
              size="large"
              disabled={loading}
              autocorrect="off"
              autocapitalize="off"
              placeholder="URL API"
            />
          </Form.Item>
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'auth.signin.typeUser',
                }),
              },
            ]}
          >
            <Input
              size="large"
              disabled={loading}
              autocorrect="off"
              autocapitalize="off"
              placeholder={intl.formatMessage({
                id: 'global.labels.user',
              })}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: intl.formatMessage({
                  id: 'auth.signin.typePassword',
                }),
              },
            ]}
          >
            <Input
              size="large"
              disabled={loading}
              autocorrect="off"
              type="password"
              autocapitalize="off"
              placeholder={intl.formatMessage({
                id: 'global.labels.password',
              })}
            />
          </Form.Item>
        </Form>
        {errorMessage && (
          <Result
            status="error"
            title={
              attempt
                ? `${attempt} ${intl.formatMessage({
                    id: errorMessage,
                  })}`
                : intl.formatMessage({ id: errorMessage })
            }
            description={
              errorMessage === 'global.error.messages.serverError'
                ? window._env_.ENV_API_URL
                : null
            }
          />
        )}
      </Card>
      <div style={{ background: '#ffcfac' }}>
        <SafeArea position="bottom" />
      </div>
    </div>
  )
}

export default MobileView
