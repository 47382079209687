import produce from 'immer'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: null,
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const newDraft = draft

    switch (action.type) {
      case '@accessControl/REQUEST': {
        newDraft.loading = true
        break
      }
      case '@accessControl/SUCCESS': {
        newDraft.data = action.payload.accessControl
        newDraft.loading = false
        break
      }
      case '@accessControl/FAILURE': {
        newDraft.loading = false
        newDraft.error = action.payload.error
        break
      }
      default:
    }
  })
