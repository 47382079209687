/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'

function useAccessControlMenu({
  hasAccess,
  accessControl,
  mainRoutes = [],
  configRoutes = [],
}) {
  const [newMainRoutes, setNewMainRoutes] = useState([])
  const [newConfigRoutes, setNewConfigRoutes] = useState([])

  // Controle de acesso baseado no modelo antigo
  useEffect(() => {
    if (hasAccess) {
      setNewMainRoutes(mainRoutes)
      setNewConfigRoutes(configRoutes)
      return
    }

    if (accessControl) {
      setNewMainRoutes(
        mainRoutes
          // Filter menus
          .filter(
            mRoute =>
              accessControl[mRoute.id]?.read ||
              (Array.isArray(mRoute.children) &&
                mRoute.children.filter(
                  subMenu =>
                    accessControl[subMenu.id]?.read ||
                    String(subMenu.to.pathname).includes('dashboard')
                ).length > 0)
          )
          // Filter submenus
          .map(mRoute => ({
            ...mRoute,
            children:
              Array.isArray(mRoute.children) &&
              mRoute.children.filter(
                subMenu =>
                  accessControl[subMenu.id]?.read ||
                  String(subMenu.to.pathname).includes('dashboard')
              ),
          }))
      )

      setNewConfigRoutes(
        configRoutes
          // Filter menus
          .filter(
            mRoute =>
              accessControl[mRoute.id]?.read ||
              (Array.isArray(mRoute.children) &&
                mRoute.children.filter(
                  subMenu => accessControl[subMenu.id]?.read
                ).length > 0)
          )

          // Filter submenus
          .map(mRoute => ({
            ...mRoute,
            children:
              Array.isArray(mRoute.children) &&
              mRoute.children.filter(
                subMenu => accessControl[subMenu.id]?.read
              ),
          }))
      )
    }
  }, [
    hasAccess,
    JSON.stringify(accessControl),
    JSON.stringify(mainRoutes),
    JSON.stringify(configRoutes),
  ])

  return {
    newMainRoutes,
    newConfigRoutes,
  }
}

export default useAccessControlMenu
