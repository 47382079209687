import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import Icon from '@ant-design/icons'
import { faStickyNote } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import AntModal from 'components/AntModal'

import Logbook from './Components/Logbook'

function LogbookHeader() {
  const [visible, setVisible] = useState(false)
  const intl = useIntl()

  return (
    <>
      <Icon
        onClick={() => setVisible(!visible)}
        style={{
          fontSize: 18,
          alignSelf: 'center',
          cursor: 'pointer',
          margin: '0 24px',
        }}
        component={() => <FontAwesomeIcon icon={faStickyNote} />}
      />

      <AntModal
        destroyOnClose
        toggleModal
        visibleModal
        width="70%"
        title={intl.formatMessage({
          id: 'dataEntry.notes.title',
        })}
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Logbook />
      </AntModal>
    </>
  )
}

export default LogbookHeader
