import React, { Component } from 'react'
import { injectIntl } from 'react-intl'

import { notification, Button } from 'antd'
import PropTypes from 'prop-types'
// import packageJson from '../../../package.json'

// global.appVersion = packageJson.version

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = versionA.split(/\./g)

  const versionsB = versionB.split(/\./g)
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift())

    const b = Number(versionsB.shift())
    // eslint-disable-next-line no-continue
    if (a === b) continue
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b)
  }
  return false
}

class CacheBuster extends Component {
  constructor(props) {
    super(props)
    const { intl } = this.props
    this.state = {
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: async () => {
        if (caches) {
          const keys = await caches.keys()
          keys.forEach(names => {
            caches.delete(names)
            names.map(name => caches.delete(name)) // new one, substitute the next line due to eslint
            // for (const name of names) {
            //   caches.delete(name)
            // }
          })
        }

        const key = 'Service-worker only one notification cache buster'
        const btn = (
          <Button
            type="primary"
            size="small"
            onClick={() => window.location.reload(true)}
          >
            {intl.formatMessage({ id: 'global.button.update' })}
          </Button>
        )
        notification.open({
          message: intl.formatMessage({
            id: 'components.cacheBuster.newUpdate',
          }),
          description: intl.formatMessage({
            id: 'components.cacheBuster.updateDescription',
          }),
          btn,
          key,
          duration: 0,
        })
      },
    }
  }

  async componentDidMount() {
    const currentVersionFile = await fetch('/meta.json', {
      cache: 'force-cache',
    }).then(response => response.json())

    const newVersionFile = await fetch('/meta.json', {
      cache: 'reload',
    }).then(response => response.json())

    const currentVersion = currentVersionFile.version
    const latestVersion = newVersionFile.version

    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion)
    if (shouldForceRefresh) {
      console.log(
        `We have a new version - ${latestVersion}. Should force refresh with command CTRL+F5`
      )
      this.setState({ loading: false, isLatestVersion: false })
    } else {
      console.log(
        `You already have the latest version - ${latestVersion}. No cache refresh needed.`
      )
      this.setState({ loading: false, isLatestVersion: true })
    }
  }

  render() {
    const { children } = this.props
    const { loading, isLatestVersion, refreshCacheAndReload } = this.state
    return children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
    })
  }
}

CacheBuster.propTypes = {
  children: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
}

export default injectIntl(CacheBuster)
