import React, { useState, useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroller'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import {
  UserOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  CloseOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import {
  message,
  Row,
  Col,
  Select,
  Input,
  Button,
  Divider,
  Timeline,
  Empty,
  Spin,
  DatePicker,
  Popconfirm,
  Typography,
} from 'antd'
import moment from 'moment'

import AvatarList from 'components/AvatarList'
import RichTextInput from 'components/RichTextInput'
import SpinLoading from 'components/SpinLoading'

import axiosClient from 'services/api'

import { LeftTimeline, ContainerSpin, ContainerScroll } from './styles'

const { Option } = Select
const { Search } = Input
const { Text } = Typography
function Logbook() {
  const [date, setDate] = useState(moment())
  const user = useSelector(state => state.user.profile)
  const [groups, setGroups] = useState([])
  const [items, setItems] = useState([])
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentGroup, setCurrentGroup] = useState(null)
  const [note, setNote] = useState('')
  const [loadingInfinity, setLoadingInfinity] = useState(false)
  const [loadingEdit, setLoadingEdit] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [allItems, setAllItems] = useState([])
  const [searchText, setSearchText] = useState('')
  const [editingKey, setEditingKey] = useState(null)
  const [editingText, setEditingText] = useState('')
  const intl = useIntl()

  useEffect(() => {
    async function getData() {
      setLoading(true)
      try {
        const dataGroup = await axiosClient
          .get('LOGBOOK/entities/GROUP')
          .then(resp => resp.data)

        setGroups(dataGroup.data)

        const usersData = await axiosClient
          .get('CAC/queries/USUARIO')
          .then(resp => resp.data)

        setUsers(usersData.data)
      } catch (error) {
        console.error(error)
        message.error(
          intl.formatMessage({ id: 'global.error.messages.defaultError' })
        )
      } finally {
        setLoading(false)
      }
    }

    getData()
  }, [intl])

  async function addNote() {
    setLoading(true)
    try {
      const response = await axiosClient
        .post('LOGBOOK/entities/DATA', {
          id_logbook_group: currentGroup,
          id_user: user?.id_usuario,
          logbook_annotation: note,
          logbook_date: date.format('YYYY-MM-DDTHH:mm:ss[Z]'),
        })
        .then(resp => resp.data)

      if (
        items.length &&
        items[0]?.id_logbook_group === response.data?.id_logbook_group
      ) {
        setItems(prevItems =>
          [...prevItems, response.data]
            .sort((a, b) => b.id_logbook - a.id_logbook)
            .slice(0, 5)
        )
      }

      message.success(
        intl.formatMessage({ id: 'dataEntry.messages.logbookSuccess' })
      )
    } catch (err) {
      console.log(err)
      message.error(
        intl.formatMessage({ id: 'dataEntry.messages.logbookError' })
      )
    } finally {
      setLoading(false)
      setDate(moment())
      setNote('')
    }
  }

  async function getNotes(id_group) {
    setLoading(true)
    const response = await axiosClient
      .get(`LOGBOOK/entities/DATA/${id_group}`)
      .then(resp => resp.data)

    setItems(response.data.slice(0, 5))
    setAllItems(response.data)
    setCurrentGroup(id_group)
    setLoading(false)
    setHasMore(true)
  }

  function getCurrentUser(id) {
    return users.find(usu => usu.id_usuario === id) || {}
  }

  function handleInfiniteOnLoad() {
    setLoadingInfinity(true)

    if (searchText !== '' || items.length >= allItems.length) {
      setHasMore(false)
    } else {
      setItems(prevItems =>
        prevItems.concat(allItems.slice(prevItems.length, prevItems.length + 5))
      )
    }

    setLoadingInfinity(false)
  }

  function handleSearch(text) {
    const textData = String(text).toUpperCase()
    const filtered = allItems.filter(item =>
      String(item.logbook_annotation).toUpperCase().match(textData)
    )

    setSearchText(textData)

    if (textData && textData !== '' && Array.isArray(filtered)) {
      setItems(filtered)
    } else {
      getNotes(currentGroup)
    }
  }

  async function handleSave(item) {
    try {
      setLoadingEdit(true)
      const response = await axiosClient
        .put(`LOGBOOK/entities/DATA/${item.id_logbook}`, {
          logbook_annotation: editingText,
        })
        .then(resp => resp.data)

      setItems(prevState =>
        prevState.map(prev =>
          prev.id_logbook === item.id_logbook ? response.data : prev
        )
      )

      message.success(
        intl.formatMessage({ id: 'dataEntry.messages.logbookEdit' })
      )
    } catch (e) {
      console.log('error', e)
      message.error(
        intl.formatMessage({ id: 'global.error.messages.defaultError' })
      )
    } finally {
      setEditingKey(null)
      setEditingText('')
      setLoadingEdit(false)
    }
  }

  async function handleDelete(id) {
    try {
      setLoadingDelete(true)
      await axiosClient.delete(`LOGBOOK/entities/DATA/${id}`)

      setItems(prevState => prevState.filter(item => item.id_logbook !== id))

      message.success(
        intl.formatMessage({ id: 'dataEntry.messages.logbookDelete' })
      )
    } catch (e) {
      console.log('error', e)
      message.error(
        intl.formatMessage({ id: 'global.error.messages.defaultError' })
      )
    } finally {
      setEditingKey(null)
      setEditingText('')
      setLoadingDelete(false)
    }
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col flex="35%">
          <Select
            data-testid="logbook-group-select"
            style={{ width: '100%' }}
            onChange={value => {
              getNotes(value)
            }}
            placeholder={intl.formatMessage({ id: 'global.labels.select' })}
          >
            {groups.map(group => (
              <Option
                value={group.id_logbook_group}
                key={group.id_logbook_group}
              >
                {group.logbook_group_description}
              </Option>
            ))}
          </Select>
        </Col>
        <Col flex="30%">
          <DatePicker
            inputReadOnly
            value={date}
            format="DD/MM/YYYY HH:mm:ss"
            showTime
            onChange={value => setDate(value)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col flex="35%">
          <Search
            disabled={!currentGroup}
            placeholder={intl.formatMessage({ id: 'global.labels.search' })}
            onChange={e => handleSearch(e.target.value)}
            allowClear
            style={{ width: '100%' }}
          />
        </Col>

        <Col flex="100%">
          <RichTextInput
            value={note}
            onChange={editorState => {
              setNote(editorState)
            }}
          />
        </Col>
        <Col flex="100%">
          <Button
            style={{ float: 'right' }}
            type="primary"
            onClick={addNote}
            disabled={!note || !currentGroup}
          >
            {intl.formatMessage({ id: 'global.button.add' })}
          </Button>
        </Col>
      </Row>

      <Divider />

      {loading && <SpinLoading spinning={loading} />}

      {items.length && !loading ? (
        <ContainerScroll>
          <InfiniteScroll
            initialLoad={false}
            pageStart={0}
            loadMore={handleInfiniteOnLoad}
            hasMore={!loadingInfinity && hasMore}
            useWindow={false}
          >
            <LeftTimeline mode="left" style={{ marginTop: 20 }}>
              {items.map(item => (
                <Timeline.Item
                  key={item.id_logbook}
                  dot={
                    <AvatarList
                      list={[
                        {
                          id: getCurrentUser(item.id_user)?.id_usuario,
                          title: getCurrentUser(item.id_user)?.usu_nome,
                          usu_login: getCurrentUser(item.id_user)?.usu_login,
                          icon: <UserOutlined />,
                          src: getCurrentUser(item.id_user)?.usu_foto,
                        },
                      ]}
                      listLimit={1}
                    />
                  }
                >
                  <Text style={{ marginRight: 20 }}>
                    {intl.formatDate(item.logbook_date, {
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                    })}
                  </Text>
                  <Text>{getCurrentUser(item.id_user)?.usu_nome}</Text>
                  {' - '}
                  <Text type="warning">
                    {getCurrentUser(item.id_user)?.usu_login}
                  </Text>
                  <div style={{ display: 'flex', margin: 10 }}>
                    {editingKey === item.id_logbook ? (
                      <RichTextInput
                        defaultValue={item.logbook_annotation}
                        onChange={editorState => {
                          setEditingText(editorState)
                        }}
                      />
                    ) : (
                      <span
                        style={{ marginLeft: 20 }}
                        className="ql-editor output-content"
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{
                          __html: item.logbook_annotation,
                        }}
                      />
                    )}
                    <div
                      style={{
                        position: 'absolute',
                        right: '5%',
                      }}
                    >
                      <Button.Group>
                        {editingKey === item.id_logbook ? (
                          <Button
                            type="primary"
                            icon={
                              loadingEdit ? (
                                <LoadingOutlined />
                              ) : (
                                <SaveOutlined />
                              )
                            }
                            size="small"
                            onClick={() => handleSave(item)}
                          />
                        ) : (
                          <Button
                            icon={<EditOutlined />}
                            size="small"
                            onClick={() => {
                              setEditingKey(item.id_logbook)
                              setEditingText(item.logbook_annotation)
                            }}
                            disabled={
                              editingKey ||
                              item.id_user !== user?.id_usuario ||
                              loadingDelete
                            }
                          />
                        )}
                        {editingKey === item.id_logbook ? (
                          <Button
                            icon={<CloseOutlined />}
                            size="small"
                            onClick={() => setEditingKey(null)}
                            disabled={loadingEdit}
                          />
                        ) : (
                          <Popconfirm
                            placement="left"
                            onConfirm={() => handleDelete(item.id_logbook)}
                            title={intl.formatMessage({
                              id: 'global.labels.deleteConfirmation',
                            })}
                            okType="default"
                            cancelButtonProps={{
                              type: 'primary',
                              style: { float: 'right' },
                            }}
                            okText={intl.formatMessage({
                              id: 'global.labels.yes',
                            })}
                            cancelText={intl.formatMessage({
                              id: 'global.labels.no',
                            })}
                            icon={
                              <QuestionCircleOutlined
                                style={{ color: 'red' }}
                              />
                            }
                            disabled={
                              editingKey || item.id_user !== user?.id_usuario
                            }
                          >
                            <Button
                              type="danger"
                              icon={
                                loadingDelete ? (
                                  <LoadingOutlined />
                                ) : (
                                  <DeleteOutlined />
                                )
                              }
                              size="small"
                              disabled={
                                editingKey || item.id_user !== user?.id_usuario
                              }
                            />
                          </Popconfirm>
                        )}
                      </Button.Group>
                    </div>
                  </div>
                </Timeline.Item>
              ))}

              {loadingInfinity && hasMore && (
                <ContainerSpin>
                  <Spin />
                </ContainerSpin>
              )}
            </LeftTimeline>
          </InfiniteScroll>
        </ContainerScroll>
      ) : (
        !loading && <Empty />
      )}
    </>
  )
}

export default Logbook
