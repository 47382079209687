/* eslint-disable react/prop-types */
import React from 'react'
import { useIntl } from 'react-intl'

import { Alert } from 'antd'
import PropTypes from 'prop-types'

import useLogger from 'hooks/useElasticLogger'

function ErrorFallback({ error }) {
  const intl = useIntl()
  const logger = useLogger()

  logger.error('component', String(error))

  return (
    <Alert
      message={intl.formatMessage({ id: 'global.error.messages.defaultError' })}
      type="error"
      showIcon
    />
  )
}

ErrorFallback.propTypes = {
  error: PropTypes.shape({}).isRequired,
}

export default ErrorFallback
