import produce from 'immer'

export const initialState = {
  data: {
    clientTags: [],
    unitTags: [],
    spcTags: [],
    allSpcTags: [],
  },
  error: null,
}

// reducer for tests
export function reducer(state = initialState, { type, payload }) {
  produce(state, draft => {
    const newDraft = draft

    switch (type) {
      case '@tags/SET_ALL': {
        newDraft.data.clientTags = payload.data
        break
      }
      case '@tags/SET_ALL_CHUNKS': {
        newDraft.data.clientTags.push(...payload.data)
        break
      }
      case '@tags/SET_ALL_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/SET_UNIT_TAGS': {
        newDraft.data.unitTags = payload.data
        break
      }
      case '@tags/SET_UNIT_TAGS_CHUNKS': {
        newDraft.data.unitTags.push(...payload.data)
        break
      }
      case '@tags/SET_UNIT_TAGS_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/SET_SPC_TAGS': {
        newDraft.data.spcTags = payload.data
        break
      }
      case '@tags/SET_SPC_TAGS_CHUNKS': {
        newDraft.data.spcTags.push(...payload.data)
        break
      }
      case '@tags/SET_ALL_SPC_TAGS': {
        newDraft.data.allSpcTags = payload.data
        break
      }
      case '@tags/SET_ALL_SPC_TAGS_CHUNKS': {
        newDraft.data.allSpcTags.push(...payload.data)
        break
      }
      case '@tags/GET_ALL': {
        newDraft.error = null
        break
      }
      case '@tags/GET_ALL_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/GET_UNIT_TAGS': {
        newDraft.error = null
        break
      }
      case '@tags/GET_UNIT_TAGS_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/GET_SPC_TAGS': {
        newDraft.error = null
        break
      }
      case '@tags/GET_ALL_SPC_TAGS': {
        newDraft.error = null
        break
      }
      case '@tags/UPDATE': {
        newDraft.data = payload.data
        break
      }
      case '@tags/UPDATE_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/ADD': {
        newDraft.data = payload.data
        break
      }
      case '@tags/ADD_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/DELETE': {
        newDraft.data = payload.data
        break
      }
      case '@tags/DELETE_FAILURE': {
        newDraft.error = payload.error
        break
      }
      default:
    }
  })
}

export default (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const newDraft = draft

    switch (type) {
      case '@tags/SET_ALL': {
        newDraft.data.clientTags = payload.data
        break
      }
      case '@tags/SET_ALL_CHUNKS': {
        newDraft.data.clientTags.push(...payload.data)
        break
      }
      case '@tags/SET_ALL_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/SET_UNIT_TAGS': {
        newDraft.data.unitTags = payload.data
        break
      }
      case '@tags/SET_UNIT_TAGS_CHUNKS': {
        newDraft.data.unitTags.push(...payload.data)
        break
      }
      case '@tags/SET_UNIT_TAGS_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/SET_SPC_TAGS': {
        newDraft.data.spcTags = payload.data
        break
      }
      case '@tags/SET_SPC_TAGS_CHUNKS': {
        newDraft.data.spcTags.push(...payload.data)
        break
      }
      case '@tags/SET_ALL_SPC_TAGS': {
        newDraft.data.allSpcTags = payload.data
        break
      }
      case '@tags/SET_ALL_SPC_TAGS_CHUNKS': {
        newDraft.data.allSpcTags.push(...payload.data)
        break
      }
      case '@tags/GET_ALL': {
        newDraft.error = null
        break
      }
      case '@tags/GET_ALL_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/GET_UNIT_TAGS': {
        newDraft.error = null
        break
      }
      case '@tags/GET_UNIT_TAGS_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/GET_SPC_TAGS': {
        newDraft.error = null
        break
      }
      case '@tags/GET_ALL_SPC_TAGS': {
        newDraft.error = null
        break
      }
      case '@tags/UPDATE': {
        newDraft.data = payload.data
        break
      }
      case '@tags/UPDATE_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/ADD': {
        newDraft.data = payload.data
        break
      }
      case '@tags/ADD_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@tags/DELETE': {
        newDraft.data = payload.data
        break
      }
      case '@tags/DELETE_FAILURE': {
        newDraft.error = payload.error
        break
      }
      default:
    }
  })
