import produce from 'immer'

const navigatorLocale =
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage ||
  'en-US'

const INITIAL_STATE = {
  key: navigatorLocale,
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const newDraft = draft

    switch (action.type) {
      case '@locale/SET_KEY': {
        newDraft.key = action.payload.key
        break
      }

      default:
    }
  })
