import React from 'react'
import { useSelector } from 'react-redux'

import { LogoImg, LogoPlaceholderImg } from './styles'

function Logo() {
  const whiteLabel = useSelector(state => state.whiteLabel)
  const theme = useSelector(state => state.theme.currentTheme)

  return whiteLabel.logo ? (
    <LogoImg
      src={theme === 'dark' ? whiteLabel.logoWhite : whiteLabel.logo}
      alt="Logo"
    />
  ) : (
    <LogoPlaceholderImg />
  )
}

export default Logo
