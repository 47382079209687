/* eslint-disable react/prop-types */
import React from 'react'

import { Grid, Layout, Row, Col } from 'antd'

import AlertHeader from './Components/AlertHeader'
import CollapseButton from './Components/CollapseButton'
import DropdownMobile from './Components/Dropdown'
import LogbookHeader from './Components/LogbookHeader'
// import QRCodeReader from './Components/QRCodeReader'
import SignOut from './Components/SignOut'
import ThemeSwitcher from './Components/ThemeSwitcher'
import UnitSelector from './Components/UnitSelector'
import UserProfile from './Components/UserProfileMenu'

const { Header } = Layout
const { useBreakpoint } = Grid

function DesktopView({
  showCollapse = true,
  showUnitSelector = true,
  showThemeSelector = true,
  showLogbook = true,
  showAlert = true,
  showUserProfile = true,
  showLogout = true,
  user,
}) {
  const screens = useBreakpoint()
  return (
    <Header
      style={{
        background: 'var(--component-background)',
        padding: screens.lg ? '0 50px' : '0px',
      }}
    >
      <Row>
        <Col xs={12} sm={12} md={13} lg={11}>
          {showCollapse && <CollapseButton />}
          {showUnitSelector &&
            (screens.sm ? <UnitSelector /> : <DropdownMobile />)}
        </Col>
        <Col
          xs={12}
          sm={12}
          md={11}
          lg={13}
          style={{ float: 'right', textAlign: 'right' }}
        >
          {/* <QRCodeReader /> */}
          {showThemeSelector && screens.sm && <ThemeSwitcher />}
          {showLogbook && screens.sm && <LogbookHeader />}
          {showAlert && screens.sm && <AlertHeader />}
          {showUserProfile && <UserProfile user={user} />}
          {showLogout && <SignOut />}
        </Col>
      </Row>
    </Header>
  )
}

export default DesktopView
