import { takeLatest, select, all, put } from 'redux-saga/effects'

import api from 'services/api'

import { setUnitFrames } from '../frames/actions'
import { setUnitTags, setSpcTags } from '../tags/actions'

export function* changeUnitSelected() {
  const unitSelected = yield select(state => state.unitSelected)
  const tags = yield select(state => state.tags.data.clientTags)
  const allSpcTags = yield select(state => state.tags.data.allSpcTags)
  const frames = yield select(state => state.frames.data.clientFrames)
  yield (api.defaults.headers.common['plt-code'] = unitSelected.data)
  yield (api.defaults.headers.common['id-element'] = unitSelected.idElement)

  yield put({ type: '@dateConfig/GET' })
  yield put({ type: '@tags/GET_ALL' })
  yield put({ type: '@frames/GET_ALL' })

  const unitTags = tags.filter(tag => tag.plt_codigo === unitSelected.data)
  const spcTagsFiltered = allSpcTags
    .filter(spc => unitTags.find(tag => tag.id_tag === spc.id_tag))
    .filter(
      (item, index, self) =>
        index === self.findIndex(t => t.id_tag === item.id_tag)
    )

  yield put(setUnitTags(unitTags))
  yield put(setSpcTags(spcTagsFiltered))
  yield put(
    setUnitFrames(
      frames.filter(frame => frame.element?.plt_codigo === unitSelected.data)
    )
  )
}

export default all([takeLatest('@unitSelected/SET', changeUnitSelected)])
