import { Avatar } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  /* justify-content: center; */
  align-items: center;
`

export const StyledAvatar = styled(Avatar)`
  /* z-index: ${props => props.zIndex};
  margin-right: -10; */
  color: '#fff';
  background-color: 'red';
  border: '1px solid #fff';
`

export const RestAvatar = styled(Avatar)`
  /* z-index: ${props => props.zIndex};
  margin-right: -10; */
  background-color: '#CECECE';
`
