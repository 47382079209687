import React from 'react'
import { useDispatch } from 'react-redux'

import Icon from '@ant-design/icons'
import { faSignOutAlt } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { signOut } from 'store/modules/auth/actions'

function SignOut() {
  const dispatch = useDispatch()

  function handleSignOut() {
    dispatch(signOut())
    localStorage.setItem('dateConfig', null)
  }

  return (
    <Icon
      onClick={handleSignOut}
      style={{
        fontSize: 18,
        // lineHeight: '64px',
        alignSelf: 'center',
        cursor: 'pointer',
        margin: '0 15px',
      }}
      component={() => <FontAwesomeIcon icon={faSignOutAlt} />}
      data-testid="signoutid"
    />
  )
}

export default SignOut
