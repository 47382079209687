import React from 'react'

import { Comment, List } from 'antd'
import PropTypes from 'prop-types'

export default function CommentList({ comments }) {
  return (
    <List
      dataSource={comments.sort((a, b) => a.date - b.date).reverse()}
      itemLayout="horizontal"
      renderItem={props => (
        <List.Item>
          <Comment {...props} />
        </List.Item>
      )}
    />
  )
}

CommentList.propTypes = {
  comments: PropTypes.shape({
    sort: PropTypes.func,
  }).isRequired,
}
