import styled from 'styled-components'

export const LogoPlaceholderImg = styled.div`
  height: 32px;
  background: rgba(0, 0, 0, 0.2);
  margin: 10px;
`

export const LogoImg = styled.img`
  margin: 10px auto;
  display: block;
  max-width: 100%;
  width: 220px;
`
