import PerfectScrollBar from 'react-perfect-scrollbar'

import { Popover, List } from 'antd'
import styled from 'styled-components'

export const Scroll = styled(PerfectScrollBar)`
  max-height: 300px;
  width: 300px;
`

export const StyledPopover = styled(Popover)`
  .ant-popover-inner-content {
    padding: 0;
  }
  .ant-popover-title {
    font-weight: 'bold';
  }
`

export const StyledList = styled(List)`
  .ant-list-item-meta-title {
    font-weight: 500;
    text-align: 'center';
  }
  .ant-list-item {
    padding-right: 15px;
    padding-left: 15px;
    transition: background-color 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
`

export const StyledListItem = styled(List.Item)`
  background: ${props =>
    props.read
      ? 'var(--component-background)'
      : 'var(--layout-body-background)'};
  opacity: ${props => (props.read ? 0.7 : 1)};
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`
