import React from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, Alert } from 'antd'
import moment from 'moment'

function Notification() {
  const contract = useSelector(state => state.contract)
  const history = useHistory()
  const intl = useIntl()

  const openExpiredScreen = () => history.push('/expired')

  const dayToExpire = moment(contract.expiredDate).diff(moment(), 'days')

  if (dayToExpire > 0 && dayToExpire < 30) {
    return (
      <Alert
        message={intl.formatMessage(
          {
            id: 'expired.timeToExpire',
          },
          { count: dayToExpire }
        )}
        action={
          <Button type="ghost" size="small" onClick={openExpiredScreen}>
            {intl.formatMessage({
              id: 'expired.renewTextButton',
            })}
          </Button>
        }
        banner
      />
    )
  }
  return null
}

export default Notification
