import { REHYDRATE } from 'redux-persist/lib/constants'
import {
  takeLeading,
  takeLatest,
  delay,
  call,
  put,
  all,
  fork,
} from 'redux-saga/effects'

import api from 'services/api'

import { signOut } from '../auth/actions'
import {
  checkContractRequest,
  checkContractSuccess,
  checkContractFailure,
} from './actions'

export function* checkContractTick() {
  const minutes = 1
  const minutesToMs = m => m * 60 * 1000

  while (true) {
    yield put(checkContractRequest())
    yield delay(minutesToMs(minutes))
  }
}

function* checkContractTimer() {
  // starts the task in the background
  yield fork(checkContractTick)
}

export function* checkContract() {
  try {
    if (api.defaults.headers.common.Authorization) {
      const { data } = yield call(api.get, 'EST/queries/checkContractDate')

      const {
        data: { data_fim_contrato },
        status,
      } = data
      const isExpired = status === 'EXPIRED'

      if (status === 'UNCONTRATED') {
        yield put(signOut())
        localStorage.setItem('dateConfig', null)
      } else yield put(checkContractSuccess(isExpired, data_fim_contrato))
    }
  } catch (error) {
    yield put(checkContractFailure('Erro ao tentar consultar o contrato'))
  }
}

export default all([
  takeLatest('@contract/REQUEST', checkContract),
  takeLeading(REHYDRATE, checkContractTimer),
])
