import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { ConfigProvider } from 'antd'
import { ConfigProvider as ConfigProviderMobile } from 'antd-mobile'
import enUSMobile from 'antd-mobile/es/locales/en-US'
import esESMobile from 'antd-mobile/es/locales/es-ES'
import ptBRMobile from 'antd-mobile/es/locales/pt-BR'
import enUS from 'antd/es/locale/en_US'
import esES from 'antd/es/locale/es_ES'
import ptBR from 'antd/es/locale/pt_BR'
import PropTypes from 'prop-types'

const availableMessages = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-ES': esES,
  es: esES,
  pt: ptBR,
  en: enUS,
}

const availableMessagesMobile = {
  'pt-BR': ptBRMobile,
  'en-US': enUSMobile,
  'es-ES': esESMobile,
  es: esESMobile,
  pt: ptBRMobile,
  en: enUSMobile,
}

const getMessages = localeKey => {
  const messages = availableMessages[localeKey]

  return messages
}

const getMessagesMobile = localeKey => {
  const messages = availableMessagesMobile[localeKey]

  return messages
}

export function AntdProvider({ children }) {
  const localeKey = useSelector(state => state.locale.key)
  const locale = useMemo(() => getMessages(localeKey), [localeKey])
  const localeMobile = useMemo(() => getMessagesMobile(localeKey), [localeKey])

  return (
    <ConfigProvider locale={locale}>
      <ConfigProviderMobile locale={localeMobile}>
        {children}
      </ConfigProviderMobile>
    </ConfigProvider>
  )
}

AntdProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
}

export default AntdProvider
