import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'

import {
  Divider,
  Form,
  Layout,
  Input,
  Card,
  Typography,
  Button,
  message,
} from 'antd'
import moment from 'moment'
import { checkContractSuccess } from 'store/modules/contract/actions'

import BaseHeader from 'components/Layout/Header'

import axiosClient from 'services/api'

const { TextArea } = Input
const { Paragraph, Title } = Typography
const { Footer, Content } = Layout

function ExpiredScreen() {
  const history = useHistory()
  const dispatch = useDispatch()
  const contract = useSelector(state => state.contract)
  const whiteLabel = useSelector(state => state.whiteLabel)
  const [loading, setLoading] = useState(false)
  const intl = useIntl()

  const dayToExpire = moment(contract.expiredDate).diff(moment(), 'days')

  async function handleSubmit(values) {
    setLoading(true)

    try {
      await axiosClient
        .post('EST/queries/setNewContractDate', {
          token: values.token,
        })
        .then(resp => resp.data)
        .then(resp => {
          dispatch(checkContractSuccess(false, resp.data.data_fim_contrato))
        })
    } catch (error) {
      console.log(error)
      message.error(
        intl.formatMessage({ id: 'global.error.messages.defaultError' })
      )
    } finally {
      setLoading(false)
    }
  }

  if (!contract.isExpired && dayToExpire >= 30) {
    return <Redirect to="/analysis/analysis-process" />
  }

  return (
    <Layout style={{ minHeight: '100vh' }}>
      <BaseHeader
        showCollapse={false}
        showLogbook={false}
        showAlert={false}
        showUserProfile={false}
      />
      <Content style={{ minHeight: 'auto', padding: '50px' }}>
        <Card bodyStyle={{ minHeight: 280 }} bordered={false}>
          <Typography>
            <Title level={3}>
              <FormattedMessage id="breadcrumbs.expired.title" />
            </Title>
            <Paragraph>
              <FormattedMessage id="expired.paragragh" />
            </Paragraph>
          </Typography>
          <Divider />
          <Paragraph>
            <FormattedMessage id="expired.key" />
          </Paragraph>
          <Form onFinish={handleSubmit}>
            <Form.Item
              name="token"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'global.labels.requiredField',
                  }),
                },
              ]}
            >
              <TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <Button loading={loading} htmlType="submit" type="primary">
                {intl.formatMessage({ id: 'global.button.validateKey' })}
              </Button>
            </Form.Item>
          </Form>
          {!contract.isExpired && (
            <Button onClick={() => history.goBack()}>
              {intl.formatMessage({ id: 'global.button.back' })}
            </Button>
          )}
        </Card>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        {whiteLabel.title} ©{moment().format('YYYY')}
      </Footer>
    </Layout>
  )
}

export default ExpiredScreen
