export function toggleMenuMobile() {
  return {
    type: '@siderMenu/TOGGLE_MOBILE',
  }
}

export function toggleCollapseSider() {
  return {
    type: '@siderMenu/TOGGLE_COLLAPSE_SIDER',
  }
}
