import produce from 'immer'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: null,
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const newDraft = draft

    switch (action.type) {
      case '@units/UNITS_REQUEST': {
        newDraft.loading = true
        break
      }
      case '@units/UNITS_SUCCESS': {
        newDraft.data = action.payload.units
        newDraft.loading = false
        break
      }
      case '@units/UNITS_FAILURE': {
        newDraft.loading = false
        newDraft.error = action.payload.error
        break
      }
      default:
    }
  })
