/* eslint-disable import-helpers/order-imports */
/* eslint-disable func-names */
/* eslint-disable no-extend-native */
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { HighchartsProvider } from 'react-jsx-highcharts'
import { Provider } from 'react-redux'

import { PersistGate } from 'redux-persist/integration/react'
import Routes from 'routes'
import { store, persistor } from 'store'
import HeadStyles from 'styles/head'

import Highcharts from 'highcharts'
import bullet from 'highcharts/modules/bullet'
import treemap from 'highcharts/modules/treemap'
import heatmap from 'highcharts/modules/heatmap'
// import boost from 'highcharts/modules/boost' INT-2455
import addHighchartsMore from 'highcharts/highcharts-more'
import PatternFillModule from 'highcharts/modules/pattern-fill'
import addXRangeModule from 'highcharts/modules/xrange'
import applyDrilldown from 'highcharts/modules/drilldown'
import addSolidGaugeModule from 'highcharts/modules/solid-gauge'

import { AntdProvider } from './contexts/antdContext'
import { LocaleProvider } from './contexts/localeContext'
import { ThemeProvider } from './contexts/themeContext'
import 'styles/main.less'

bullet(Highcharts)
treemap(Highcharts)
heatmap(Highcharts)
addHighchartsMore(Highcharts)
addXRangeModule(Highcharts)
PatternFillModule(Highcharts)
applyDrilldown(Highcharts)
addSolidGaugeModule(Highcharts)
// boost(Highcharts) INT-2455

Highcharts.setOptions({
  colors: Highcharts.getOptions().colors.map((item, index) => {
    if (index === 1) return 'var(--highcharts-second-color)'
    return item
  }),
  plotOptions: {
    series: {
      dataLabels: {
        color: 'var(--highcharts-data-labels-color)',
        style: {
          fontWeight: 'normal',
          textOutline: null,
        },
      },
    },
  },
})

if (!Array.prototype.flat) {
  Object.defineProperty(Array.prototype, 'flat', {
    value(depth = 1) {
      return this.reduce(
        (flat, toFlatten) =>
          flat.concat(
            Array.isArray(toFlatten) && depth > 1
              ? toFlatten.flat(depth - 1)
              : toFlatten
          ),
        []
      )
    },
  })
}

if (!Array.prototype.flatMap) {
  Array.prototype.flatMap = function (cb) {
    return this.map(cb).reduce(
      (destArray, array) => destArray.concat(array),
      []
    )
  }
}

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HelmetProvider>
          <HeadStyles />
          <LocaleProvider>
            <ThemeProvider>
              <AntdProvider>
                <HighchartsProvider Highcharts={Highcharts}>
                  <Routes />
                </HighchartsProvider>
              </AntdProvider>
            </ThemeProvider>
          </LocaleProvider>
        </HelmetProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
