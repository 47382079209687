import produce from 'immer'

const initialState = {
  profile: {},
}

export default (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const newDraft = draft

    switch (type) {
      case '@auth/SIGN_IN_SUCCESS': {
        newDraft.profile = payload.user
        newDraft.profile.id = payload.user.id_usuario
        break
      }
      case '@auth/SIGN_UP_SUCCESS': {
        newDraft.profile = payload.user
        newDraft.profile.id = payload.user.id_usuario
        break
      }
      case '@auth/SIGN_OUT': {
        newDraft.profile = {}
        break
      }
      case '@profile/UPDATE_REQUEST': {
        newDraft.loading = true
        break
      }
      case '@profile/UPDATE_SUCCESS': {
        newDraft.profile = payload.user
        newDraft.loading = false
        break
      }
      case '@profile/UPDATE_PROFILE': {
        newDraft.profile.usu_foto = payload.avatar
        newDraft.profile.usu_nome = payload.name
        newDraft.profile.usu_email = payload.email
        break
      }
      case '@profile/UPDATE_FAILURE': {
        newDraft.loading = false
        break
      }
      default:
    }
  })
