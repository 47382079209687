import produce from 'immer'

export const initialState = {
  data: {
    shifts: [
      { start: '00:00', end: '06:00' },
      { start: '06:00', end: '12:00' },
      { start: '12:00', end: '18:00' },
      { start: '18:00', end: '00:00' },
    ],
    dayOffset: 0,
    day: {
      startHour: '00:00',
    },
    startWeek: 0,
  },
  error: null,
}

// reducer for tests
export function reducer(state = initialState, { type, payload }) {
  produce(state, draft => {
    const newDraft = draft

    switch (type) {
      case '@dateConfig/SET': {
        newDraft.data = payload.data
        break
      }
      case '@dateConfig/GET': {
        newDraft.error = null
        break
      }
      case '@dateConfig/FAILURE': {
        newDraft.error = payload.error
        break
      }
      default:
    }
  })
}

export default (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const newDraft = draft

    switch (type) {
      case '@dateConfig/SET': {
        newDraft.data = payload.data
        break
      }
      case '@dateConfig/GET': {
        newDraft.error = null
        break
      }
      case '@dateConfig/FAILURE': {
        newDraft.error = payload.error
        break
      }
      default:
    }
  })
