/* eslint-disable react/prop-types */
import React from 'react'

import { LockOutlined, UserOutlined } from '@ant-design/icons'
import { Layout, Row, Col, Input, Button, Alert, Form } from 'antd'
import moment from 'moment'

import packageJson from '../../../package.json'
import { Logo, LogoPlaceholder } from './styles'

const { Footer, Content } = Layout

const DesktopView = ({
  intl,
  errorMessage,
  whiteLabel,
  loading,
  loginADFS,
  form,
  handleSubmit,
  adfs,
  attempt,
}) => (
  <Layout className="layout">
    <Content>
      <Row
        type="flex"
        justify="space-around"
        align="middle"
        style={{ height: 'calc(100vh - 70px)' }}
      >
        <Col xs={22} sm={16} md={12} lg={8} xl={6}>
          <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
            <Form form={form} onFinish={handleSubmit}>
              {whiteLabel.logo ? (
                <Logo src={whiteLabel.logo} alt="Logo" height={72} />
              ) : (
                <LogoPlaceholder />
              )}
              {errorMessage && (
                <Alert
                  style={{ marginBottom: 24 }}
                  message={
                    attempt
                      ? `${attempt} ${intl.formatMessage({
                          id: errorMessage,
                        })}`
                      : intl.formatMessage({ id: errorMessage })
                  }
                  description={
                    errorMessage === 'global.error.messages.serverError'
                      ? window._env_.ENV_API_URL
                      : null
                  }
                  type="error"
                  showIcon
                  closable
                />
              )}

              <Form.Item
                name="username"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'auth.signin.typeUser',
                    }),
                  },
                ]}
              >
                <Input
                  size="large"
                  disabled={loading}
                  autocorrect="off"
                  autocapitalize="off"
                  prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={intl.formatMessage({
                    id: 'global.labels.user',
                  })}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({
                      id: 'auth.signin.typePassword',
                    }),
                  },
                ]}
              >
                <Input.Password
                  size="large"
                  disabled={loading}
                  autocorrect="off"
                  autocapitalize="off"
                  prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                  placeholder={intl.formatMessage({
                    id: 'global.labels.password',
                  })}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="login-form-button"
                  loading={loading}
                >
                  {intl.formatMessage({ id: 'global.button.enter' })}
                </Button>
              </Form.Item>
              {!!adfs && Object.keys(adfs).length > 0 && (
                <Form.Item>
                  <Button
                    block
                    type="primary"
                    size="large"
                    className="login-form-button"
                    onClick={loginADFS}
                  >
                    ADFS
                  </Button>
                </Form.Item>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </Content>
    <Footer style={{ textAlign: 'center' }}>
      © {moment().format('YYYY')} - v{packageJson.version}
    </Footer>
  </Layout>
)

export default DesktopView
