/* eslint-disable react/prop-types */
import React from 'react'

import { Layout } from 'antd'
import moment from 'moment'

import Notification from 'utils/notification'

import Scrollbar from '../Scrollbar'
import BaseHeader from './Header'
import Sidebar from './Sidebar'

const { Footer, Content } = Layout
/**
 * Renders the desktop view of the application.
 *
 * @param {Object} props - The props object.
 * @param {React.ReactNode} props.children - The child components to render.
 * @param {Object} props.whiteLabel - The white label object.
 * @param {string} props.whiteLabel.title - The title of the white label.
 * @return {React.ReactNode} The rendered desktop view.
 */
const DesktopView = ({ children, whiteLabel }) => (
  <>
    <Notification />
    <Layout style={{ minHeight: '100vh' }}>
      <Sidebar />
      <Layout>
        <BaseHeader />
        <Scrollbar
          className="scroll-area"
          style={{ maxHeight: 'calc(100vh - 65px)' }}
        >
          <>
            <Content style={{ minHeight: 'calc(100vh - 135px)' }}>
              {children}
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              {whiteLabel.title} ©{moment().format('YYYY')}
            </Footer>
          </>
        </Scrollbar>
      </Layout>
    </Layout>
  </>
)

export default DesktopView
