import { REHYDRATE } from 'redux-persist/lib/constants'
import { select, all, takeLeading } from 'redux-saga/effects'

import api from 'services/api'

import { getAuth, getProfile, getUnitSelected, getIdElement } from './selectors'

export function* init() {
  const auth = yield select(getAuth)
  const userProfile = yield select(getProfile)
  const unitSelected = yield select(getUnitSelected)
  const idElement = yield select(getIdElement)

  if (auth.token) {
    yield (api.defaults.headers.common.Authorization = `Bearer ${auth.token}`)
    yield (api.defaults.headers.common['client-code'] = userProfile.cli_codigo)
    yield (api.defaults.headers.common['plt-code'] = unitSelected)
    yield (api.defaults.headers.common['id-element'] = idElement)
  }
}

export default all([takeLeading(REHYDRATE, init)])
