import React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { faMoon, faSun } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { changeCurrentTheme } from 'store/modules/theme/actions'

function ThemeSwitcher() {
  const dispatch = useDispatch()
  const theme = useSelector(state => state.theme.currentTheme)

  const toggleTheme = value => {
    dispatch(changeCurrentTheme(value))
  }

  return (
    <FontAwesomeIcon
      onClick={() => toggleTheme(theme === 'dark' ? 'light' : 'dark')}
      style={{
        fontSize: '18px',
        alignSelf: 'center',
        margin: '0 15px',
        cursor: 'pointer',
      }}
      icon={theme === 'dark' ? faSun : faMoon}
      data-testid="themeswitchericon"
    />
  )
}

export default ThemeSwitcher
