export function accessControlRequest() {
  return {
    type: '@accessControl/REQUEST',
  }
}

export function accessControlSuccess(accessControl) {
  return {
    type: '@accessControl/SUCCESS',
    payload: { accessControl },
  }
}

export function accessControlFailure(error) {
  return {
    type: '@accessControl/FAILURE',
    payload: { error },
  }
}
