import produce from 'immer'

const INITIAL_STATE = {
  data: null,
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const newDraft = draft

    switch (action.type) {
      case '@unitSelected/SET': {
        newDraft.data = action.payload.unitSelected.plt_codigo
        newDraft.idElement = action.payload.unitSelected.id_elemento
        break
      }
      default:
    }
  })
