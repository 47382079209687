import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Select } from 'antd'
import { checkContractRequest } from 'store/modules/contract/actions'
import { setUnitSelected } from 'store/modules/unitSelected/actions'

import { StyledSelect } from './styles'

const { Option } = Select

function UnitSelector() {
  const dispatch = useDispatch()
  const history = useHistory()

  const unitSelected = useSelector(state => state.unitSelected.idElement)
  const allUnits = useSelector(state => state.units.data)

  function formatUnitLabel(unit) {
    if (unit) {
      return `${unit.plt_codigo} - ${unit.ele_descri}`
    }

    return null
  }

  function getUnit(id) {
    return allUnits.find(u => u.id_elemento === id)
  }

  async function handleChangeUnit(unit) {
    dispatch(setUnitSelected(getUnit(unit)))
    dispatch(checkContractRequest())

    history.push('/')
  }

  return (
    <StyledSelect
      style={{
        width: '80%',
      }}
      value={unitSelected}
      onChange={value => handleChangeUnit(value)}
      data-testid="unitselector-test-id"
    >
      {allUnits.map(unit => (
        <Option key={unit.plt_codigo} value={unit.id_elemento}>
          {formatUnitLabel(unit)}
        </Option>
      ))}
    </StyledSelect>
  )
}

export default UnitSelector
