import { takeLatest, call, put, all, select } from 'redux-saga/effects'

import api from 'services/api'

import { accessControlSuccess, accessControlFailure } from './actions'
import { getProfile, getUnitSelected } from './selectors'

export function* accessControlRequest() {
  try {
    const unitSelected = yield select(getUnitSelected)
    const userProfile = yield select(getProfile)

    const {
      data: { data },
    } = yield call(api.post, `CAC/queries/accessControl`, {
      id: userProfile.id_usuario,
      plt: unitSelected,
    })

    yield put(accessControlSuccess(data))
  } catch (err) {
    yield put(
      accessControlFailure(
        err.message ||
          'Houve um problema com a api, por favor, entre em contato conosco e ajude-nos a resolver.'
      )
    )
  }
}

export default all([
  takeLatest('@accessControl/REQUEST', accessControlRequest),
  takeLatest('@unitSelected/SET', accessControlRequest),
])
