import React, { useMemo } from 'react'
import { IntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'

import Highcharts from 'highcharts'
import enUS from 'locale/en_US'
import esES from 'locale/es_ES'
import ptBR from 'locale/pt_BR'
import moment from 'moment'
import PropTypes from 'prop-types'

import flattenObject from 'utils/flattenObject'
import getMomentLocale from 'utils/getMomentLocale'

/**
 * Use react-intl apply translated terms
 * with FormattedMessage and useIntl
 * more infos: https://formatjs.io/docs/react-intl
 */

const availableMessages = {
  'pt-BR': ptBR,
  'en-US': enUS,
  'es-ES': esES,
  es: esES,
}

const getMessages = localeKey => {
  const messages = availableMessages[localeKey]

  return messages || enUS
}

function getValueByString(object, keyString) {
  return object[keyString] || keyString
}

export function LocaleProvider({ children }) {
  const localeKey = useSelector(state => state.locale.key)
  const messages = useMemo(
    () => flattenObject(getMessages(localeKey)),
    [localeKey]
  )

  getMomentLocale(localeKey)
  Highcharts.setOptions({
    lang: {
      months: moment.months(),
      weekdays: moment.weekdays(),
      shortMonths: moment.monthsShort(),
      drillUpText: `◁ ${getValueByString(messages, 'global.button.back')}`,
    },
  })
  return (
    <IntlProvider locale={localeKey} messages={messages}>
      {children}
    </IntlProvider>
  )
}

LocaleProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any]).isRequired,
}

export default LocaleProvider
