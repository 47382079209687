/* eslint-disable react/prop-types */
import React from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { NavBar } from 'antd-mobile'

import { links } from 'components/Layout/Sidebar/Components/Menu/links'

import DropdownMobile from './Components/Dropdown'
import SignOut from './Components/SignOut'

function MobileView({ showLogout = true }) {
  const { pathname } = useLocation()
  const intl = useIntl()

  const mobileMainRoutes = (function flatten(list, parentLabel, parentIcon) {
    return list.reduce((acc, obj) => {
      const { children, label = parentLabel, icon = parentIcon, ...rest } = obj
      acc.push({ ...rest, label, icon })
      if (children) {
        // eslint-disable-next-line no-param-reassign
        acc = acc.concat(flatten(children, label, icon))
      }
      return acc
    }, [])
  })(links)

  const headerTitle = localStorage.getItem('headerTitle')

  return (
    <NavBar
      back={null}
      left={<DropdownMobile />}
      right={showLogout && <SignOut />}
    >
      {intl.formatMessage({
        id:
          mobileMainRoutes.find(menuItem => menuItem.to.pathname === pathname)
            ?.label ||
          headerTitle ||
          'menu.configuration.title',
      })}
    </NavBar>
  )
}

export default MobileView
