export function signInRequest(username, password) {
  return {
    type: '@auth/SIGN_IN_REQUEST',
    payload: { username, password },
  }
}

export function signInRequestADFS(username, id, client) {
  return {
    type: '@auth/SIGN_IN_REQUEST_ADFS',
    payload: { username, id, client },
  }
}

export function signInSuccess(
  token,
  user,
  userDataPermission,
  forcePasswordChange
) {
  return {
    type: '@auth/SIGN_IN_SUCCESS',
    payload: { token, user, userDataPermission, forcePasswordChange },
  }
}

export function signInFailure(error, attempt) {
  return {
    type: '@auth/SIGN_IN_FAILURE',
    payload: { error, attempt },
  }
}

export function cleanError() {
  return {
    type: '@auth/CLEAN_ERROR',
  }
}

export function signOut() {
  return {
    type: '@auth/SIGN_OUT',
  }
}

export function signOutSuccess(domain) {
  return {
    type: '@auth/SIGN_OUT_SUCCESS',
    payload: { domain },
  }
}
