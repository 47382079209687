import React from 'react'

import { Modal } from 'antd'
import PropTypes from 'prop-types'

import useIsMobileWidth from 'hooks/useIsMobileWidth'

const AntModal = ({ toggleModal, visibleModal, children, width, ...props }) => {
  const [isMobile] = useIsMobileWidth()

  return (
    <Modal
      closable={false}
      visible={visibleModal}
      onOk={toggleModal}
      onCancel={toggleModal}
      footer={null}
      width={isMobile ? '90%' : width}
      {...props}
    >
      {children}
    </Modal>
  )
}

AntModal.defaultProps = {
  visibleModal: false,
  toggleModal: null,
  width: '60%',
}

AntModal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.shape({}),
    PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element),
      ])
    ),
  ]).isRequired,
  toggleModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  visibleModal: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default AntModal
