import { useState } from 'react' // Import useState
import { useSelector } from 'react-redux'

import axios from 'axios'

function useLogger() {
  const [isLoading, setIsLoading] = useState(false)
  const unitSelected = useSelector(state => state.unitSelected)
  const user = useSelector(state => state.user)
  // Check if unitSelected is defined before accessing its data property
  const currentUnit = unitSelected ? unitSelected.data : 'no unit selected'
  // Check if user and user.profile are defined before accessing profile
  const userProfile = user && user.profile ? user.profile : 'no user'

  const log = (level, type, message, extra = {}) => {
    try {
      const allowedLevels = ['info', 'warn', 'error', 'success']
      const allowedTypes = [
        'navigate_to',
        'click',
        'create_item',
        'update_item',
        'delete_item',
        'component',
      ]
      if (!allowedLevels.includes(level)) {
        throw new Error('Invalid log level')
      }
      if (!allowedTypes.includes(type)) {
        throw new Error('Invalid log type')
      }
      if (typeof message !== 'string') {
        console.error(message)
        throw new Error('Invalid log message', message)
      }
      //   setIsLoading(true) // Set loading state
      const client = userProfile?.cliCode
      const userLogin = userProfile?.usu_login
      const unit = currentUnit
      const { userAgent } = navigator
      const browser = userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident)\/?\s*(\.?\d+(\.\d+)*)/i
      )
      const browserVersion = browser ? browser[2].split('.') : []
      const osInfo = {}
      // Detect the OS family
      if (userAgent.indexOf('Win') !== -1) {
        osInfo.family = 'Windows'
      } else if (userAgent.indexOf('Mac') !== -1) {
        osInfo.family = 'Mac OS X'
      } else if (userAgent.indexOf('Linux') !== -1) {
        osInfo.family = 'Linux'
      } else if (userAgent.indexOf('Android') !== -1) {
        osInfo.family = 'Android'
      } else if (userAgent.indexOf('iOS') !== -1) {
        osInfo.family = 'iOS'
      }
      // Detect the OS version
      if (osInfo.family === 'Windows') {
        const versionRegex = /Windows NT \d+.\d+/g
        const versionMatch = versionRegex.exec(userAgent)
        if (versionMatch) {
          const versionParts = versionMatch[0].split('.')
          const [major, minor] = versionParts
          osInfo.major = major.replace('Windows NT ', '')
          osInfo.minor = minor
        }
      } else if (osInfo.family === 'Mac OS X') {
        const versionRegex = /Mac OS X (\d+)_(\d+)_(\d+)/g
        const versionMatch = versionRegex.exec(userAgent)
        if (versionMatch) {
          const [major, minor, patch] = versionMatch.slice(1)
          osInfo.major = major
          osInfo.minor = minor
          osInfo.patch = patch
        }
      } else if (osInfo.family === 'Android') {
        const versionRegex = /Android (\d+)(\.\d+)?/g
        const versionMatch = versionRegex.exec(userAgent)
        if (versionMatch) {
          const [major, minorWithPrefix] = versionMatch.slice(1)
          osInfo.major = major
          if (minorWithPrefix) {
            osInfo.minor = minorWithPrefix.substring(1)
          }
        }
      } else if (osInfo.family === 'iOS') {
        const versionRegex = /OS (\d+)_(\d+)_?(\d+)?/g
        const versionMatch = versionRegex.exec(userAgent)
        if (versionMatch) {
          const [major, minor, patch] = versionMatch.slice(1, 4)
          osInfo.major = major
          osInfo.minor = minor
          if (patch) {
            osInfo.patch = patch
          }
        }
      }
      // Get the platform
      let platform = 'Unknown'
      if (userAgent.indexOf('Mac') > -1) {
        platform = 'Mac'
      } else if (userAgent.indexOf('Windows') > -1) {
        platform = 'Windows'
      } else if (userAgent.indexOf('Linux') > -1) {
        platform = 'Linux'
      }
      // Get the model
      let model = 'Unknown'
      if (platform === 'Mac') {
        if (userAgent.indexOf('iPhone') > -1) {
          model = 'iPhone'
        } else if (userAgent.indexOf('iPad') > -1) {
          model = 'iPad'
        } else if (userAgent.indexOf('iPod') > -1) {
          model = 'iPod'
        } else {
          model = 'Mac'
        }
      }
      // Get the family
      let family = 'Unknown'
      if (platform === 'Mac') {
        if (model === 'iPhone' || model === 'iPad' || model === 'iPod') {
          family = 'iOS'
        } else {
          family = 'Mac'
        }
      } else if (platform === 'Windows') {
        if (userAgent.indexOf('Phone') > -1) {
          family = 'Windows Phone'
        } else {
          family = 'Windows'
        }
      } else if (platform === 'Linux') {
        family = 'Linux'
      }
      // Get the category
      let category = 'Unknown'
      if (
        model === 'iPhone' ||
        model === 'iPad' ||
        model === 'iPod' ||
        model === 'Windows Phone'
      ) {
        category = 'Mobile'
      } else if (
        platform === 'Mac' ||
        platform === 'Windows' ||
        platform === 'Linux'
      ) {
        category = 'Desktop'
      }
      // Get the brand
      let brand = 'Unknown'
      if (platform === 'Mac') {
        brand = 'Apple'
      } else if (platform === 'Windows') {
        brand = 'Microsoft'
      } else if (platform === 'Linux') {
        brand = 'Linux Foundation'
      }
      // Return the computer information
      const deviceInfo = {
        model,
        family,
        category,
        brand,
      }
      axios
        .post(
          'https://elastic.intelup.com.br/elastic/ingest',
          {
            '@timestamp': new Date().toISOString(),
            message,
            type,
            client,
            unit,
            user: userLogin,
            status: level,
            view: {
              url_details: {
                path: window.location.pathname,
                scheme: window.location.protocol.replace(':', ''),
                port:
                  window.location.port ||
                  (window.location.protocol === 'https:' ? 443 : 80),
                host: window.location.hostname,
              },
              url: window.location.href,
            },
            origin: 'react',
            http: {
              useragent: userAgent,
              useragent_details: {
                browser: {
                  family: browser?.length ? browser[1] : 'Unknown',
                  major: browserVersion.length >= 1 ? browserVersion[0] : '0',
                  minor: browserVersion.length >= 2 ? browserVersion[1] : '0',
                  patch: browserVersion.length >= 3 ? browserVersion[2] : '0',
                  patch_minor:
                    browserVersion.length >= 4 ? browserVersion[3] : '0',
                },
                os: osInfo,
                device: deviceInfo,
              },
            },
            extra,
          },
          {
            headers: {
              authorization: `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJkYXRhIjp7InN0YXR1czoiOiJlcnJvciJ9LCJpYXQiOjE3MDYyMDk4MTJ9.UbFEZEw_hVd2IBlGd9tMgzjkVkneyJpufaYRFZ95dvI`,
            },
          }
        )
        .then(() => {
          setIsLoading(false)
        })
        .catch(() => {
          setIsLoading(false)
        })
    } catch (error) {
      console.error(error)
    }
  }
  return {
    info: (type, message, extra) => log('info', type, message, extra),
    warn: (type, message, extra) => log('warn', type, message, extra),
    error: (type, message, extra) => log('error', type, message, extra),
    isLoading, // Expose loading state
  }
}

export default useLogger
