const generateRandomPassword = () => {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+'

  let password = ''
  let hasUppercase = false
  let hasLowercase = false
  let hasNumber = false
  let hasSpecialCharacter = false
  const minLength = 8
  const maxLength = 10

  while (
    password.length < minLength ||
    (password.length < maxLength &&
      (!hasUppercase || !hasLowercase || !hasNumber || !hasSpecialCharacter))
  ) {
    const randomCharacter = characters.charAt(
      Math.floor(Math.random() * characters.length)
    )
    password += randomCharacter

    if (/[A-Z]/.test(randomCharacter)) {
      hasUppercase = true
    } else if (/[a-z]/.test(randomCharacter)) {
      hasLowercase = true
    } else if (/[0-9]/.test(randomCharacter)) {
      hasNumber = true
    } else if (/[\W_]/.test(randomCharacter)) {
      hasSpecialCharacter = true
    }
  }

  if (!hasNumber) {
    const randomIndex = Math.floor(Math.random() * (password.length - 1))
    const randomNumber = Math.floor(Math.random() * 10)
    password =
      password.slice(0, randomIndex) +
      randomNumber +
      password.slice(randomIndex + 1)
  }
  return password
}

export default generateRandomPassword
