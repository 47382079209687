import React from 'react'

import { Spin } from 'antd'
import PropTypes from 'prop-types'

function SpinLoading({ spinning, ...props }) {
  return <Spin spinning={!!spinning} {...props} />
}

SpinLoading.propTypes = {
  spinning: PropTypes.bool.isRequired,
}

export default SpinLoading
