import React from 'react'
import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'

export default () => {
  const whiteLabel = useSelector(state => state.whiteLabel)

  return (
    <Helmet>
      <title>{whiteLabel.title}</title>
      <link rel="icon" type="image/png" href={whiteLabel.favicon} />
    </Helmet>
  )
}
