import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import { Avatar, Tooltip, message } from 'antd'
import Form from 'pages/configuration/user/Components/Form'
import PropTypes from 'prop-types'
import { updateProfile } from 'store/modules/user/actions'

import axiosClient from 'services/api'

import { Container } from './styles'

const UserProfileMenu = () => {
  const [visible, setVisible] = useState(false)
  const [items, setItems] = useState([])

  const userProfile = useSelector(state => state.user.profile)

  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    axiosClient
      .get('CAC/entities/USUARIO')
      .then(response => response.data)
      .then(response => setItems(response.data))
  }, [])

  const handleSubmit = async data => {
    const values = {
      ...data,
      usu_ativo: 'S',
    }

    if (data.usu_senha.length === 0) {
      delete values.usu_senha
    }

    await axiosClient.postOrPut(
      'CAC/entities/USUARIO',
      userProfile.id_usuario,
      values
    )

    dispatch(updateProfile(values.usu_foto, values.usu_nome, values.usu_email))

    setVisible(false)

    message.success(
      intl.formatMessage({
        id: 'global.success.messages.update',
      })
    )
  }

  function profileLabel() {
    return intl.formatMessage({
      id: 'global.labels.profile',
    })
  }

  return (
    <Container>
      <Tooltip
        title={`${profileLabel()}: ${userProfile?.usu_nome} -  ${
          userProfile?.usu_login
        }`}
      >
        <Avatar
          size="default"
          src={userProfile?.usu_foto || '/styles/img/avatars/avatar.png'}
          onClick={() => setVisible(true)}
          style={{
            cursor: 'pointer',
            marginLeft: 16,
            marginRight: 16,
          }}
        />
      </Tooltip>

      <Form
        isProfile
        toggleModal={setVisible}
        handleSubmit={handleSubmit}
        values={userProfile}
        usersList={items}
        visible={visible}
        page
      />
    </Container>
  )
}

UserProfileMenu.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    photo: PropTypes.string,
  }).isRequired,
}

export default UserProfileMenu
