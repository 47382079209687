import React from 'react'

import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { Form, Input, Button } from 'antd'
import PropTypes from 'prop-types'

const { TextArea } = Input
const Editor = ({
  onValueChange,
  onSubmit,
  submitting,
  value,
  nextAlert,
  prevAlert,
  disabledController,
  items,
  intl,
}) => (
  <>
    <Form.Item>
      <TextArea rows={4} onChange={onValueChange} value={value} />
    </Form.Item>
    <Form.Item>
      <Button
        htmlType="submit"
        type="primary"
        loading={submitting}
        onClick={onSubmit}
      >
        {intl.formatMessage({ id: 'global.button.comment' })}
      </Button>
      <Button
        class="btn-alert"
        shape="circle"
        icon={<ArrowRightOutlined />}
        style={{ float: 'right' }}
        onClick={nextAlert}
        disabled={disabledController === items.length - 1}
      />
      <Button
        shape="circle"
        icon={<ArrowLeftOutlined />}
        style={{ float: 'right' }}
        onClick={prevAlert}
        disabled={disabledController === items.length - items.length}
      />
    </Form.Item>
  </>
)

Editor.propTypes = {
  disabledController: PropTypes.bool.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func,
  }).isRequired,
  items: PropTypes.shape({
    length: PropTypes.number,
  }).isRequired,
  nextAlert: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onValueChange: PropTypes.func.isRequired,
  prevAlert: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  value: PropTypes.shape({}).isRequired,
}

export default Editor
