import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import ErrorFallback from 'components/ErrorFallback'

import AlertHeader from './AlertHeader'

export default ({ ...props }) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <AlertHeader {...props} />
  </ErrorBoundary>
)
