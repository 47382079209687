// import process from 'pages/analytics/process/locale/en_US'

const localeValues = {
  locale: 'en-us',
  global: {
    request: {
      put: {
        code: {
          200: 'Record edited successfully',
          401: 'Unauthorized, please log in again',
          409: 'Cannot edit this record as it is already related to other data in the system. Please review and adjust the links before trying again.',
          500: 'Error while trying to edit this record.',
        },
      },
      post: {
        code: {
          200: 'Record inserted successfully',
          401: 'Unauthorized, please log in again',
          409: 'This record cannot be created as it conflicts with an existing record in the system. Please check and correct the data before trying again.',
          500: 'Error while trying to save this record.',
        },
      },
      delete: {
        code: {
          200: 'Record deleted successfully',
          401: 'Unauthorized, please log in again',
          409: 'Cannot delete this record as it is related to other data in the system. Before deleting it, remove any existing links with this record.',
          500: 'Error while trying to delete this record.',
        },
      },
      get: {
        code: {
          401: 'Unauthorized, please log in again',
          500: 'Error while fetching records.',
        },
      },
      error: {
        unknow: 'Connection error. Please try again later.',
      },
    },
    error: {
      messages: {
        serverError: 'Error connecting to the server',
        defaultError: 'There was a problem, please try again',
        loadDataError: 'Error loading data, please try again',
        equipmentError: 'Error when trying to query equipment data',
        reportError: 'Error trying to query the report data',
        reconciliationError: 'Error trying to perform reconciliation',
        sendError: 'There was a problem sending data, please try again',
        deleteError:
          'There was an error when trying to delete the data, please try again',
        syntaxError: 'Text without data, please check the syntax',
        calculationError: 'Check the syntax',
        pdfError: 'There was a problem exporting the PDF, please try again',
        errorImportingFile:
          'There was an error importing, please review the file and try again',
        imageError: 'Inserted file is not an image.',
        attachmentError: 'Error trying to add attachment, please try again',
      },
    },
    info: {
      downsamplePoints:
        'Downsample data. To see all the points, shorten the period',
      noPermission: 'No permission',
      dividerFormAddButton: 'The last stop should last more than 1 second!',
      diffIndicatorsValues:
        'Divergent indicator settings, using string 1 indicator settings',
      blankToAutoGenerated: 'Leave blank to generate automatically',
      noPermissionForItem: 'You do not have permission to access this item',
      loadingComponents:
        'There are components being loaded in this tab or in the other tabs.',
      inactiveUser: 'Inactive user',
    },
    success: {
      messages: {
        download: 'Download successfully completed',
        registration: 'Successful registration',
        update: 'Change made successfully',
        delete: 'Successfully deleted',
        activate: 'Tag group successfully activated',
        deActivate: 'Tag group successfully deactivated',
        save: 'Saved successfully',
        reconciliation: 'Successful reconciliation',
        integrated: 'Successful integration',
        import: 'Successfully imported',
        attachment: 'Attachment added successfully',
        attachmentRefused: 'Attachment extension not allowed',
      },
    },
    warning: {
      messages: {
        pointsQuantity:
          'Number of points for each tags are different, try grouping to perform the analysis',
        pointsQuantityDifferent: 'Number of points for each tags are different',
        pointsInsufficient:
          'Insufficient number of points to perform the calculation',
        noAppointments: 'No notes for the day',
        registerTagLimit: 'Tags limit reached',
        noComponentType: 'Component with no valid type:',
        searchNoGroupBy:
          'Search raw, Tags that do not have points did not show up for preview.',
      },
    },
    loading: {
      messages: {
        downloading: 'Preparing file for download...',
        saving: 'Saving ...',
        loading: 'Loading...',
        finished: 'Finished',
      },
    },
    labels: {
      selectProductionOrder: 'Select production order',
      selectShift: 'Select shift',
      deletedGroup: 'Group deleted',
      confirmText: 'Confirm',
      between: 'between',
      underOf: 'under',
      aboveOf: 'above',
      selectUsers: 'Select users',
      available: 'Available',
      used: 'Used',
      stock: 'Stock',
      qrCode: 'QR Code',
      operators: 'Operators',
      createdAt: 'Created at',
      updatedAt: 'Updated at',
      time: 'Time',
      permissions: 'Permissions',
      color: 'Color',
      placeholder: 'Please choose',
      code: 'Code',
      codeExists: 'Code already exists',
      action: 'Action',
      actions: 'Actions',
      configuration: 'Configuration',
      exitConfiguration: 'Exit setup',
      deleteConfirmation: 'Are you sure you want to delete the selected item?',
      cancelConfirmation: 'Are you sure you want to cancel?',
      notAllowConfirmation: 'Are you sure you do not want to allow it?',
      revisionConfirmation: 'Are you sure you want to create a new revision?',
      revisionChecklistConfirmation: 'To proceed, you must fill out a note!',
      typeObservation: 'Enter a note',
      revisionInserted: 'Revision inserted successfully',
      revision: 'Revision',
      notFound: 'Sorry, the page you visited does not exist',
      yes: 'Yes',
      no: 'No',
      changeForm: 'Change form',
      registrationForm: 'Registration form',
      close: 'Close',
      lot: 'Lot',
      description: 'Description',
      recents: 'Recent',
      undefined: 'Undefined',
      dateStart: 'Initial date',
      dateEnd: 'Final date',
      tag: 'Tag',
      tags: 'Tags',
      more: 'More',
      upload: 'Upload',
      profile: 'Profile',
      period: 'Period',
      periods: 'Periods',
      type: 'Type',
      types: 'Types',
      group: 'Group',
      groups: 'Groups',
      all: 'All',
      message: 'Message',
      date: 'Data',
      user: 'User',
      userExists: 'User already exists',
      users: 'Users',
      userGroups: 'Resources Group',
      password: 'Password',
      form: 'Form',
      notes: 'Notes',
      limit: 'Limit',
      limits: 'Limits',
      value: 'Value',
      unt: 'und',
      general: 'General',
      generals: 'General',
      name: 'Name',
      auto: 'Automatic',
      manual: 'Manual',
      atributes: 'Attributes',
      standardDeviation: 'Standard deviation',
      average: 'Average',
      variance: 'Variance',
      better: 'Best',
      worst: 'Worse',
      groupBy: 'Group',
      noGroupBy: 'Raw',
      hour: 'Hour',
      shift: 'Shift',
      shiftGrouped: 'Shift - Grouped',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      custom: 'Personalized',
      vertical: 'Vertical',
      order: 'Order',
      noOrder: 'No order',
      ascendingOrder: 'Ascending',
      descendingOrder: 'Descending',
      horizontal: 'Horizontal',
      total: 'Total',
      items: 'items',
      editMode: 'Editing mode',
      chart: 'Chart',
      boxplot: 'BoxPlot',
      selectTagsChart: 'Select tags to generate the chart',
      selectFramesChart: 'Select events to generate the chart',
      pValue: 'P-value',
      quantity: 'Amount',
      difference: 'Difference',
      upperQuartile: 'Upper quartile',
      lowerQuartile: 'Lower quartile',
      maximum: 'Maximum',
      minimum: 'Minimum',
      median: 'Median',
      sum: 'Sum',
      options: 'Options',
      enable: 'Enable',
      disable: 'Disable',
      enablePointsInChart: 'Enable point usage in the chart and calculations',
      disablePointsInChart:
        'Disable use of the point in the chart and calculations',
      needToBeInEditionMode: 'You must enter edit mode to change the status',
      data: 'Data',
      motive: 'Reason',
      motives: 'Reasons',
      accumulated: 'Accumulated',
      event: 'Event',
      start: 'Start',
      end: 'End',
      status: 'Status',
      settings: 'Settings',
      observation: 'Note',
      noObservation: 'No observation',
      criticality: 'Criticality',
      equipment: 'Equipment',
      element: 'Element',
      requiredField: 'This field is required',
      text: 'Text',
      texts: 'Texts',
      outEspecificationLimit: 'Out of specification limit',
      outValidLimit: 'Outside valid limit',
      noData: 'No data',
      withData: 'With data',
      system: 'System',
      unit: 'Unity',
      units: 'Units',
      allUnits: 'All Units',
      allUsers: 'All Users',
      allTags: 'All tags',
      allInstruments: 'All instruments',
      allElements: 'All elements',
      other: 'Other',
      list: 'List',
      lists: 'Lists',
      details: 'Details',
      filter: 'Filter',
      inProduction: 'In production',
      concluded: 'Concluded',
      selectDate: 'Select a date',
      upperLimit: 'Upper limit',
      lowerLimit: 'Lower limit',
      search: 'Search',
      lsc: 'Upper control limit',
      lc: 'Center line',
      lic: 'Lower control limit',
      hideYAxis: 'Hide y axis',
      showYAxis: 'Show y axis',
      showXAxis: 'Show x axis',
      hideValues: 'Hide values',
      showValues: 'Display values',
      sequence: 'Sequence',
      line: 'Line',
      column: 'Column',
      onlyLessThan500Points: 'Only when less than 500 points',
      min: 'Min',
      max: 'Max',
      amplitude: 'Amplitude',
      select: 'Select',
      closedEvent: 'Closed event',
      openEvent: 'Open event',
      frame: 'Event',
      frames: 'Events',
      information: 'Information',
      conditions: 'Conditions',
      count: 'Number of points',
      durationFrame: 'Duration',
      lastValue: 'Last value',
      firstValue: 'First value',
      mean: 'Average',
      title: 'Title',
      result: 'Outcome',
      key: 'Key',
      operator: 'Operator',
      maxValue: 'Maximum value',
      minValue: 'Minimum value',
      centralValue: 'Central value',
      sumValues: 'Sum of values',
      tagNotFound: 'Tag not found',
      or: 'OR',
      and: 'AND',
      component: 'Component',
      display: 'Display',
      advanced: 'Advanced',
      visualization: 'Visualization',
      fields: 'Fields',
      annul: 'Annul',
      default: 'Default',
      automaticAppointment: 'Automatic appointment',
      tagValue: 'Tag value',
      automaticAppointmentTag: 'Auto-point tag',
      referenceValue: 'Reference value',
      alarmsReferenceValue: 'Inactive alarm value',
      activeTag: 'Active tag',
      now: 'Now',
      attachments: 'Attachments',
      file: 'File',
      noUser: 'No user',
      os: 'S.O.',
      height: 'Height',
      width: 'Width',
      displayNoData: 'Display "no data"',
      sector: 'Sector',
      confirm: 'Confirm',
      currentDate: 'Current date',
      item: 'Item',
      expandAll: 'Expand all',
      minute: 'Minute',
      bold: 'Bold',
      italic: 'Italic',
      underline: 'Underline',
      textColor: 'Color',
      separator: 'Separator',
      link: 'Link',
      fontSize: 'Font size',
      why: 'Why',
      what: 'What',
      how: 'How',
      isActive: 'Active',
      expiresIn: 'Expires in',
      batch: 'Batch',
      noTemplates:
        'There are no templates registered, the PDF will be exported in the default template',
      reverse: 'Reverse',
      reversed: 'Reversed',
      toggleReverse: 'Toggle reverse',
      images: 'Images',
      registeredQuantity: 'Registered quantity',
      tagCode: 'Tag code',
      tagDescription: 'Tag description',
      frequency: 'Frequency',
      quantityNotallowed: 'Quantity greater than permitted',
      request: 'Request',
      justification: 'Justification',
      justificationPlaceholder: 'fill in the justification to proceed',
      noPoints: 'There are no data points for the selected period',
    },
    button: {
      insertTags: 'Insert tags',
      settings: 'Settings',
      annulPoint: 'Annul Point',
      deActivate: 'Deactivate',
      activate: 'Activate',
      enablePoint: 'Enable Point',
      splitPoint: 'Split Point',
      historic: 'Historic',
      delete: 'Delete',
      edit: 'Edit',
      process: 'Process',
      show: 'View',
      update: 'Update',
      insert: 'Insert',
      cancel: 'Cancel',
      save: 'Save',
      saveAndGo: 'Save and open',
      comment: 'Comment',
      change: 'Change',
      backToInitialPage: 'Return to home page',
      back: 'Back',
      insertNew: 'Insert new',
      registerNew: 'Register new',
      enter: 'Log in',
      export: 'Export',
      import: 'Import',
      addPeriod: 'Add date range',
      remove: 'Remove',
      add: 'Add',
      approve: 'Approve',
      notApprove: 'Not approve',
      reprove: 'Reprove',
      integrate: 'Integrate',
      reconciliate: 'Reconcile',
      download: 'Download',
      validateKey: 'Validate key',
      cleanData: 'Clear data',
      exclude: 'Delete',
      addItem: 'Add item',
      simulate: 'Simulate',
      changeScales: 'Change scales',
      reset: 'Reset',
      filter: 'Filter',
      saveChanges: 'Save editions',
      ok: 'Ok',
      execute: 'Run',
      qrCode: 'QR Code',
      addAttachment: 'Add attachment',
      clearAppointments: 'Clear appointments',
      allow: 'Allow',
      notAllow: 'Not allow',
      create: 'Create',
      language: 'en',
      newRevision: 'New revision',
      showPdf: 'Show PDF',
      orientation: 'Orientation',
      portrait: 'Portrait',
      landscape: 'Landscape',
      discardChanges: 'Discard changes',
      viewQrCode: 'View QR Code',
      print: 'Print',
      archive: 'Archive',
      unarchive: 'Unarchive',
    },
    fcm: {
      countChildrenNotBool: 'Invalid parameter (countChildren)',
      qtdGatilhosGPA: {
        notTagGroup: 'Enter the tag group',
        tagNotInGroupOrElement:
          'The tag does not belong to the group or element informed',
        emptyTagGroup: 'The tag group entered does not exist or has no tags',
      },
      actionPlanTime: {
        noTriggersAvailable:
          'There are no triggers available for this p_pltCode in this period',
        noPlansApproval:
          'There are no plans submitted for approval for this p_pltCode in this period',
        noCompletedActions:
          "There are no actions completed for this p_pltCode's plans in this period",
        noActionsCompletedForPlan:
          'There are no actions completed for this plan',
      },
      qtyAlarms: {
        notTagGroup: 'Enter the tag group',
      },
      qtdOs: {
        noStatus: 'Check the existence of the status',
        noOsType: 'Check the existence of the SO type',
        noUserGroup: 'Check the existence of the user group',
        noElement: 'Check the existence of the element',
        showType: 'show parameter only accepts hour (hour) or quantity (qty)',
      },
      qtdPreventiveOs: {
        noStatus:
          'status parameter accepts only (initial) or (inProgress) or (waitingApproval) or (finished)',
      },
    },
  },
  menu: {
    dashboard: {
      title: 'Dashboard',
    },
    analytics: {
      title: 'Data Analyze',
      submenu: {
        analysisProcess: 'Process Analysis',
        eventManager: 'Event Management',
        correlation: 'Correlation',
        operator: 'Operator Analysis',
        comparativeBetweenPeriods: 'Comparison Between Periods',
        reportPerPeriods: 'Report by Periods',
      },
    },
    dataMining: {
      title: 'Data Mining',
      submenu: {
        regression: 'Regression',
        clustering: 'Clustering',
      },
    },
    controlLoopManagement: {
      title: 'Control Loop Management',
      submenu: {
        performance: 'Performance',
        overview: 'Control Loop Health',
      },
    },
    statisticalControl: {
      title: 'Statistical Process Control',
      submenu: {
        exploratory: 'Exploratory',
        production: 'Production',
        management: 'Deviation Management',
      },
    },
    rootCause: {
      title: 'Root Cause Analysis',
      submenu: {
        actionsAndPlans: 'Causes and Action Plans',
        generateAnalysis: 'Generate Root Cause Analysis',
        actionList: 'Action list',
      },
    },
    equipmentManagement: {
      title: 'Equipment Management',
      submenu: {
        stoppingPoint: 'Downtime Reasons',
        stoppingChart: 'Downtime Chart',
        approveStop: 'Approve Stops',
      },
    },
    opsManagement: {
      title: 'PO Management',
    },
    manufacture: {
      title: 'Manufacture',
      submenu: {
        panel: 'Production Order Panel',
        junkReport: 'Junk Report',
        approvals: 'Document Approval',
      },
    },
    operationalSecurity: {
      title: 'Operational Security',
      submenu: {
        impairments: 'Impairments',
        routineTests: 'Routine Tests',
        onlineMonitoring: 'Online Monitoring',
      },
    },
    managementOfChanges: {
      title: 'Management of Change',
      submenu: {},
    },
    dataEntry: {
      title: 'Data input',
      submenu: {
        form: 'Form',
        manual: 'Manual',
        csv: 'CSV',
        logbook: 'Notes',
      },
    },
    export: {
      title: 'Export',
      submenu: {
        data: 'Data',
        event: 'Events',
      },
    },
    scale: {
      title: 'Industrial Scale',
    },
    operationalAlerts: {
      title: 'Operational Alerts',
    },
    alarmManagement: {
      title: 'Alarm Management',
      submenu: {
        report: 'Report',
      },
    },
    consistency: {
      title: 'Consistency',
      submenu: {
        productionAndProcess: 'Production and Process',
        equipmentStopping: 'Equipment Downtime',
      },
    },
    log: {
      title: 'Log',
    },
    integratedMaintenance: {
      title: 'Integrated Maintenance',
      submenu: {
        serviceOrderPanel: 'Service Order Panel',
        serviceOrderProgramming: 'Service Order Programming',
        osGenerator: 'Preventive S.O. Generator',
        osOverview: 'S.O. Overview',
        osHistory: 'S.O. History',
        plannedExecuted: 'Planned x Executed',
        benchAppointment: 'Bench Appointment',
        reports: 'Reports',
        telemetry: 'Telemetry',
      },
    },
    configuration: {
      operationalStructure: {
        title: 'Operational Structure',
        submenu: {
          barcodeLabel: 'Barcode',
          parameters: 'Parameters',
          elementsTree: 'Elements Tree',
          operator: 'Operator',
          shiftsAndTimes: 'Shifts and Schedules',
          pdfTemplate: 'PDF Templates',
          token: 'Token',
          globalVariables: 'Global Variables',
          unitMeasurement: 'Unit Measurement',
          product: 'Product',
        },
      },
      tags: {
        title: 'Tags',
        submenu: {
          tags: 'Tags',
          tagsGroup: 'Tag Group',
          goals: 'Goals',
        },
      },
      alerts: {
        title: 'Alerts',
        submenu: {
          alertsGroup: 'Alert Group',
          alertsConfiguration: 'Configuring Alerts',
        },
      },
      manufacture: {
        title: 'Manufacture',
        submenu: {
          defect: 'Defect',
          status: 'Status',
          variation: 'Variation',
          checklist: 'Checklist',
          checklistItems: 'Items from Checklist',
          srcOrder: 'Production Order Routing',
          checklistApprovalMatrix: 'Approval Matrix',
          complementaryTable: 'Complementary Table',
          motiveGroup: 'Group of Motives',
          inspectionLevel: 'Inspection Level',
          acceptableQualityLevel: 'Acceptable Quality Level',
        },
      },
      operationalSecurity: {
        title: 'Operational Security',
        submenu: {
          instrumentSeverity: 'Severity',
          instrumentInterlock: 'Interlock',
          instrumentRisk: 'Risk',
          instrumentFrequency: 'Frequency',
          impairmentRule: 'Impairment Rules',
          instrumentMitigationMeasures: 'Mitigation Measures',
          instrumentPrefix: 'Prefix',
          instrument: 'Instruments',
          userGroupTree: 'User Group Tree',
          approvalMatrix: 'Approval Matrix',
        },
      },
      managementOfChanges: {
        title: 'Management of Change',
        submenu: {
          areaCriticality: 'Area Criticality',
          risk: 'Risk',
          responsibleArea: 'Responsible Area',
          approvalMatrix: 'Approval Matrix',
        },
      },
      eventManager: {
        title: 'Event management',
        submenu: {
          frames: 'Events',
          motives: 'Reasons',
          complementaryTypes: 'Complementary Types',
        },
      },
      controlLoopManagement: {
        title: 'Control Loop Management',
      },
      oee: {
        title: 'OEE',
      },
      rootCause: {
        title: 'Root Cause Analysis',
        submenu: {
          causes: 'Causes',
          rules: 'Rules',
        },
      },
      logbookGroup: {
        title: 'Notes Group',
      },
      integration: {
        title: 'Integration',
        submenu: {
          api: 'API',
          system: 'System',
          laboratory: 'Laboratory',
          integrationParameters: 'Integration Parameters',
        },
      },
      statisticalControl: {
        title: 'Statistical Process Control',
        submenu: {
          cards: 'Charts',
          rules: 'Rules',
          help: 'Help',
          diagnosticsList: 'Diagnosis List',
          correctiveActions: 'Corrective actions List',
        },
      },
      manualCollector: {
        title: 'Manual Collection',
        submenu: {
          collectGroup: 'Collection Group',
          collectTags: 'Collection Tags',
        },
      },
      integratedMaintenance: {
        title: 'Integrated Maintenance',
        submenu: {
          reportRegistration: 'Report Registration',
          inspectionRoutes: 'Inspection Routes',
          materialsServices: 'Materials and Services',
          modalities: 'Modalities',
          frequency: 'Frequency',
          status: 'Status',
          maintenanceActivity: 'Maintenance Activity',
          priority: 'Priority',
          serviceOrderTypes: 'Service Order Types',
          laborRules: 'Labor Rules',
          services: 'Services',
        },
      },
      accessControl: {
        title: 'Access control',
        submenu: {
          userRegistration: 'User',
          userGroup: 'Resources Group',
          accessProfile: 'Access Profile',
        },
      },
    },
  },
  tinyMCE: {
    cellLocked: 'Cell locked',
    cellUnlocked: 'Cell unlocked',
    columnLocked: 'Column locked',
    columnUnlocked: 'Column unlocked',
    rowLocked: 'Row locked',
    rowUnlocked: 'Row unlocked',
    cell: 'Cell',
    column: 'Column',
    row: 'Row',
    blockEditing: 'Block editing',
    enableEditing: 'Enable editing',
    lockCell: 'Lock cell editing',
    unlockCell: 'Unlock cell editing',
  },

  breadcrumbs: {
    home: 'Home',
    dashboard: {
      title: 'Dashboard',
    },
    analytics: {
      title: 'Analyze',
      page: {
        analysisProcess: 'Process analysis',
        eventManager: 'Event management',
        correlation: 'Correlation',
        operator: 'Operator',
        comparativeBetweenPeriods: 'Comparison Between Periods',
        reportPerPeriods: 'Report by Periods',
      },
    },
    dataMining: {
      title: 'Data Mining',
      page: {
        regression: 'Regression',
        clustering: 'Clustering',
      },
    },
    controlLoopManagement: {
      title: 'Control Loop Management',
      page: {
        performance: 'Performance',
        overview: 'Control Loop Health',
      },
    },
    statisticalControl: {
      title: 'Statistical Process Control',
      page: {
        exploratory: 'Exploratory',
        production: 'Production',
        management: 'Deviation Management',
      },
    },
    rootCause: {
      title: 'Root Cause Analysis',
      page: {
        actionsAndPlans: 'Causes and Action Plans',
        effects: 'Effects',
        charts: 'Charts',
        generateAnalysis: 'Generate analysis',
        actionList: 'Action list',
      },
    },
    equipmentManagement: {
      title: 'Equipment Management',
      page: {
        stoppingPoint: 'Downtime Reasons',
        stoppingChart: 'Downtime Chart',
        approveStop: 'Approve Stops',
      },
    },
    opsManagement: {
      title: 'PO Management',
    },
    manufacture: {
      title: 'Manufacture',
      submenu: {
        panel: 'Production Order Panel',
        junkReport: 'Junk Report',
      },
      page: {
        steps: 'Steps',
      },
    },
    dataEntry: {
      title: 'Data input',
      page: {
        form: 'Form',
        manual: 'Manual',
        csv: 'CSV',
        logbook: 'Notes',
      },
    },
    export: {
      title: 'Export',
      page: {
        data: 'Data',
        event: 'Events',
      },
    },
    scale: {
      title: 'Industrial Scale',
    },
    operationalAlerts: {
      title: 'Operational Alerts',
    },
    consistency: {
      title: 'Consistency',
      page: {
        productionAndProcess: 'Production and Process',
        equipmentStopping: 'Equipment Downtime',
      },
    },
    log: {
      title: 'Log',
    },
    expired: {
      title: 'Expired Contract',
    },
    integratedMaintenance: {
      title: 'Integrated Maintenance',
      page: {
        serviceOrderPanel: 'Service Order Panel',
        serviceOrderProgramming: 'Service Order Programming',
        osGenerator: 'Preventive S.O. Generator',
        osOverview: 'S.O. Overview',
        osHistory: 'S.O. History',
        plannedExecuted: 'Planned x Executed',
        benchAppointment: 'Bench Appointment',
        telemetry: 'Telemetry',
      },
    },
    configuration: {
      operationalStructure: {
        title: 'Operational Structure',
        page: {
          barcodeLabel: 'Barcode',
          parameters: 'Parameters',
          elementsTree: 'Elements Tree',
          shiftsAndTimes: 'Shifts and Schedules',
          pdfTemplate: 'PDF Templates',
          operator: 'Operator',
          token: 'Token',
          globalVariables: 'Global Variables',
          unitMeasurement: 'Unit Measurement',
          product: 'Product',
        },
      },
      tags: {
        title: 'Tags',
        page: {
          tags: 'Tags',
          tagsGroup: 'Tag Group',
          goals: 'Goals',
        },
      },
      operationalSecurity: {
        title: 'Operational Security',
        page: {
          instrumentSeverity: 'Severity',
          instrumentRisk: 'Risk',
          instrumentInterlock: 'Interlock',
          instrumentFrequency: 'Frequency',
          impairmentRule: 'Impairment rules',
          instrumentMitigationMeasures: 'Mitigation Measures',
          instrumentPrefix: 'Prefix',
          instrument: 'Instruments',
          userGroupTree: 'User group tree',
          approvalMatrix: 'Approval matrix',
        },
      },
      managementOfChanges: {
        title: 'Management of Change',
        page: {
          areaCriticality: 'Area Criticality',
          risk: 'Risk',
          responsibleArea: 'Responsible Area',
          approvalMatrix: 'Approval Matrix',
        },
      },
      alerts: {
        title: 'Alerts',
        page: {
          alertsGroup: 'Alert Group',
          alertsConfiguration: 'Configuring Alerts',
        },
      },
      eventManager: {
        title: 'Event management',
        page: {
          frames: 'Events',
          motives: 'Reasons',
          complementaryTypes: 'Complementary Types',
        },
      },
      controlLoopManagement: {
        title: 'Control Loop Management',
      },
      oee: {
        title: 'OEE',
      },
      rootCause: {
        title: 'Root Cause Analysis',
        page: {
          causes: 'Cause',
          rules: 'Rules',
        },
      },
      logbookGroup: {
        title: 'Notes Group',
      },
      integration: {
        title: 'Integration',
        page: {
          api: 'API',
          system: 'System',
          laboratory: 'Laboratory',
          integrationParameters: 'Integration Parameters',
          details: 'Details',
        },
      },
      statisticalControl: {
        title: 'Statistical Process Control',
        page: {
          cards: 'Charts',
          rules: 'Rules',
          help: 'Help',
          diagnosticsList: 'Diagnosis List',
          correctiveActions: 'Corrective actions List',
        },
      },
      manualCollector: {
        title: 'Manual Collection',
        page: {
          collectGroup: 'Collection Group',
          collectTags: 'Collection Tags',
        },
      },
      integratedMaintenance: {
        title: 'Integrated Maintenance',
        page: {
          reportRegistration: 'Report Registration',
          inspectionRoutes: 'Inspection Routes',
          materialsServices: 'Materials and Services',
          modalities: 'Modalities',
          frequency: 'Frequency',
          status: 'Status',
          maintenanceActivity: 'Maintenance Activity',
          priority: 'Priority',
          serviceOrderTypes: 'Service Order Types',
          laborRules: 'Labor Rules',
          services: 'Services',
        },
      },
      manufacture: {
        title: 'Manufacture',
        page: {
          defect: 'Defect',
          status: 'Status',
          variation: 'Variation',
          checklist: 'Checklist',
          checklistItems: 'Items from Checklist',
          srcOrder: 'Production Order Routing',
          checklistApprovalMatrix: 'Approval Matrix',
          complementaryTable: 'Complementary Table',
          motiveGroup: 'Group of Motives',
          inspectionLevel: 'Inspection Level',
          acceptableQualityLevel: 'Acceptable Quality Level',
        },
      },
      accessControl: {
        title: 'Access control',
        page: {
          userRegistration: 'User',
          userGroup: 'Resources Group',
          accessProfile: 'Access Profile',
        },
      },
      unitParameters: {
        title: 'Unit Parameters',
      },
      reports: {
        title: 'Reports',
      },
    },
  },
  components: {
    shortcuts: {
      title: 'Shortcuts',
      shortcut: 'Shortcut',
      new: 'New Shortcut',
      creator: 'Creator',
      moreThanOnePeriod: 'More than one period selected',
      seeAll: 'See all',
      saved: 'Saved',
    },
    shareButton: {
      copyLink: 'Copy link',
      print: 'Print',
      exportPdf: 'Export to PDF',
      exportCsv: 'Export to CSV',
      exportHorizontalCsv: 'Export horizontal CSV',
      share: 'Share',
      copied: 'Link copied!',
      loadingFile: 'Generating file ...',
    },
    dateSelector: {
      today: 'Today',
      yesterday: 'Yesterday',
      lastWeek: 'Last week',
      lastMonth: 'Last month',
      harvest: 'Harvest (*)',
      weekSelect: 'Select week',
    },
    cacheBuster: {
      newUpdate: 'New update!',
      updateDescription:
        'There is a new update available, click "Update" for the system to be updated',
    },
    tagSelect: {
      recents: 'Recent',
    },
    frameSelect: {
      recents: 'Recent',
    },
    layout: {
      menu: {
        dashboard: {
          addingDashboard: 'Adding new dashboard ...',
          noTitle: 'Untitled',
          dashboardSuccess: 'Dashboard successfully created',
          dashboardError:
            'There was a problem trying to create the dashboard, please try again ...',
          newDashboard: 'New dashboard',
        },
        buttonConfig: {
          exitConfiguration: 'Exit setup',
          configuration: 'Configuration',
        },
      },
      header: {
        alert: {
          notification: 'Notifications',
        },
        dropdown: {
          refeshApply: 'Refresh the page to apply it',
          refreshNow: 'Update now',
          unitSuccess: 'Unit data loaded successfully!',
          translationSuccess: 'Translation loaded successfully!',
          industrialUnits: 'Industrial units',
          languages: 'Languages',
          language: 'Language',
        },
      },
    },
    richTextInput: {
      small: 'Small',
      normal: 'Normal',
      large: 'Large',
      huge: 'Huge',
    },
  },
  expired: {
    title: 'Expiration',
    paragragh:
      'Your contract time has expired, please contact your system administrator',
    key: 'Enter the key for renewing the contract here',
    timeToExpire:
      'The contract will expire in {count, plural, one {# day} other {# days}}',
    renewTextButton: 'click here to renew',
  },
  statisticalControl: {
    title: 'Statistical Process Control',
    noTagCep: 'Tag does not apply to SPC',
    byPeriod: 'By period',
    card: 'Chart',
    cards: 'Charts',
    zones: 'Zones',
    limits: 'Limits',
    diagnostic: 'Diagnosis',
    diagnosticConsolidated: 'Consolidated diagnosis in the period',
    diagnosticList: 'Diagnosis list',
    action: 'Action',
    actionConsolidated: 'Consolidated action in the period',
    actionList: 'Action list',
    yScales: 'Y scale',
    pleaseInput: 'Please insert',
    exploratory: {
      imr: 'I-MR',
      ewma: 'EWMA',
      xbarR: 'XBarra - R',
      xbarS: 'XBarra - S',
      lambda: 'Lambda constant',
      k: 'Opening coefficient of control limits',
      mean: 'Target average',
      standardDeviation: 'Target standard deviation',
      standardDeviationWithin: 'Standard deviation (within)',
      standardDeviationOverall: 'Standard deviation (overall)',
      analysis: 'Statistical analysis of the process',
      graphType: 'Chart type',
      capacityAnalysis: 'Process capability analysis',
      cp: 'CP',
      cpi: 'CPI',
      cps: 'CPS',
      cpk: 'CPK',
      pp: 'PP',
      ppi: 'PPI',
      pps: 'PPS',
      ppk: 'PPK',
      input: 'Input',
      outSector: 'Out of the sector',
      expected: 'Expected',
      real: 'Real',
      li: 'LL',
      ls: 'UL',
      lic: 'LCL',
      lc: 'CL',
      lsc: 'UCL',
      histogram: 'Histogram',
      normalDistribution: 'Normal distribution',
      quantity: 'Sample quantity',
      especificationLimits: 'Specification limits',
      simulateControlLimits: 'Simulate control limits',
      hidePoints: 'Hide points',
      pointsAverage: 'Points Average',
      startTime: 'Beginning of the analyzed period',
      endTime: 'End of the analyzed period',
      colectedValue: 'Value collected',
    },
    production: {
      diagnosticItems: 'Diagnosis items',
      actionItems: 'Corrective action items',
      inserted: 'Inserted',
      edited: 'Edited',
      newPoint: 'New point',
      outLimit: 'Out of bounds',
      autoUpdate: 'Autoplay',
      help: 'Help',
      lastUser: 'Last user',
      point: 'Point',
      finishNote: 'Finish note',
    },
    management: {
      noInformation: 'No information for the day',
      noIndication: 'No indication',
      showAll: 'View all',
      showPointed: 'Show pointed',
      pareto: 'Pareto',
      percent: 'Percentage',
      pie: 'Pizza',
      spc: 'SPC',
      finished: 'Finished',
      notFinished: 'Not Finished',
    },
    rules: {
      title: 'Rules',
      of: 'of',
      outEspecificationLimit: 'point outside specification limits',
      outControlLimit: 'point out of control limits',
      sameSide: 'consecutive points on the same side of the center line',
      increasingOrDescreasing: 'consecutive points increasing or decreasing',
      aboveOrBelow: 'consecutive points, alternating above and below',
      twoStandardDeviation:
        'consecutive points greater than 2 standard deviations from the center line (same side)',
      oneStandardDeviation:
        'consecutive points greater than 1 standard deviation from the center line (same side)',
      insideOneStandardDeviation:
        'consecutive points within 1 standard deviation from the center line (either side)',
      anySide:
        'consecutive points greater than a standard deviation from the center line (either side)',
    },
    configuration: {
      card: {
        newRevision: 'New revision',
        deleteCard:
          'Are you sure you want to delete the chart? (All your revisions will also be deleted)',
        createCardRevision: 'Are you sure you want to create a new review?',
        rulingToShowTags:
          'Displaying only tags with registered rules and that do not have another chart',
        cardProject: 'Draft chart',
        revisionMotive: 'Reason for review',
        scale: 'Scale',
        yAxis: 'Y Scale',
        yAxisUnit: 'Y scale unit',
        lower: 'lower',
        cannotBeGreaterMax: 'It cannot be greater than the maximum',
        center: 'Central',
        upper: 'Superior',
        cannotBeLowerMin: 'It cannot be less than the minimum',
        lowerEspecification: 'Lower specification limit',
        upperEspecification: 'Upper specification limit',
        helpVariable: 'Variable help list',
        revisionDate: 'Chart revision date',
        revisionPeriod: 'Period of validity of this review',
        revisionInserted: 'Revision inserted successfully',
        existingCard:
          'Revision not inserted, there are other revisions with the same date. Revisions:',
        controlLimitsMessage:
          'If control limits are not entered, the system will calculate them automatically',
      },
      rules: {
        title: 'Rule',
        ruleZero: "'K' point (s) outside the specification limits",
        ruleOne: "'K' point (s) outside the control limits",
        ruleTwo:
          "'K' consecutive point (s) on the same side of the center line",
        ruleThree: "'K' consecutive point (s), all increasing or decreasing",
        ruleFour: "'K' consecutive point (s), alternating above and below",
        ruleFive:
          "'K-1' of 'K' consecutive point (s) greater than 2 standard deviations from the center line (same side)",
        ruleSix:
          "'K-1' of 'K' consecutive points (s) greater than 1 standard deviation from the center line (same side)",
        ruleSeven:
          "'K' consecutive point (s) within a standard deviation from the center line (on both sides)",
        ruleEight:
          "'K' consecutive point (s) greater than 1 standard deviation from the center line (both sides)",
        kFactor: 'K-factor',
        tagQuantity: 'Number of tags',
        rulesQuantity: 'Number of rules',
        cantDeleteRule:
          'It is not possible to delete this rule as there are cards created with one or more related tags',
        cantDeselectTags:
          'It is not possible to disassociate tags already related to a card',
      },
    },
  },
  alert: {
    title: 'Alert',
    notSeen: 'Not viewed',
    noCause: 'Without cause',
    withStaggering: 'With staggering',
    notificationsSent: 'Notifications sent',
    seen: 'Viewed',
    cause: 'Cause',
    chat: 'Chat',
    notificate: 'Notify',
    sendNotification: 'Send notification',
    configuration: {
      defaultMessage: 'Standard message',
      helpChain: 'Help chain',
      initialGroups: 'Starting groups',
      lastGroup: 'Last group',
      groupNotFound: 'Group not found',
      permissions: 'Permissions',
      maxTimeView: 'Maximum viewing time (minutes)',
      userPermission: 'Permission for users',
      showPopup: 'Show alert in pop-up',
      redirect: 'Redirect to downtime reasons when viewing the pop-up',
      redirectExtra:
        'This option will only work if the option to display pop-up is selected',
    },
  },
  auth: {
    signin: {
      typeUser: 'Enter your username',
      typePassword: 'Type your password',
      failure: 'The user or password is incorrect, verify your data',
      unauthorized: "The user doesn't have access to any unit",
      inactiveUser: 'Inactive user, contact your administrator',
      withoutContract: 'The user does not have contracted units',
      apiUrl: 'Missing the URL API, check your configuration',
      failurePassword:
        'remaining attempts, check your details or contact the administrator',
      failurePasswordLimit:
        'Exceeded login attempts, request a new password from the administrator',
      welcomeToIntelup: 'Welcome to Intelup platform',
      permissionNotification:
        'To continue using the platform, it will be necessary to allow the storage of data such as name, email and photo. If you do not allow it, you will not be able to use the platform.',
      permissionNotificationInformations:
        'The system uses personal data such as name, email and photo. This data will NOT be used for advertising and will NOT be sold to third parties. The use is unique and exclusive for the proper functioning of the platform.',
      permissionNotificationQuestion:
        'Do you allow the platform to store this data?',
    },
    changePassword: {
      title: 'Change password',
      comparePassword: 'Passwords entered are inconsistent',
      newPasswordNecessity: 'Password change required:',
      newPassword: 'New password',
      typeNewPassword: 'Enter the new password!',
      confirmPassword: 'Confirm new password',
      typeConfirmPassword: 'Enter confirmation for new password!',
    },
    strength: {
      eightCaracters: '8 characters',
      oneUppercase: '1 capital',
      oneLowercase: 'Lowercase 1',
      oneNumber: '1 number',
      oneSpecial: '1 special character',
    },
  },
  controlLoopManagement: {
    title: 'Control Loop',
    manipulatedVariable: 'Manipulated Variable',
    processVariable: 'Process Variable',
    setPoint: 'Set point',
    performance: {
      title: 'Performance',
      mainChart: {
        operationMode: 'Operation mode',
      },
    },
    tune: {
      title: 'Tuning',
      target: 'Target',
      kp: 'Kp',
      ki: 'Ki',
      kd: 'Kd',
      standardDeviationTarget: 'Standard deviation target',
    },
    reliability: {
      title: 'Reliability',
    },
    stats: {
      processAnalysis: 'Statistical analysis of the process',
      permanenceTime: 'Lenght within control',
      absoluteError: 'Mean absolute error',
      setPointNumber: 'Number of set point crossings',
    },
    operationMode: {
      title: 'Operation mode',
    },
    overview: {
      loopGroup: 'Control Loop group',
      autoTime: 'Automatic time',
    },
    configuration: {
      mnemony: 'Mnemonia',
      newGroup: 'New group',
      mnemonyExist: 'Mnemonics already exists',
      autoCreate: 'Automatically create',
      percentage: 'Percentage',
      percentuals: {
        automaticManual: 'Percentages - AM',
        automaticManualDescription:
          'Gauges ranges displayed in Control Loop Health - AM (higher - better)',
        standardDeviation: 'Percentages - Standard Deviation',
        standardDeviationDescription:
          'Gauges ranges displayed in Control Loop Health - Standard Deviation (lower - better)',
      },
      percentageInfo:
        'Enter the lower limit that will represent the beginning of the gauge range being defined',
      color: 'Color',
      afterCreation: 'Available after Control Loop creation',
    },
  },
  dataMining: {
    regression: {
      relatedTags: 'Related tags',
      r2: 'r²',
      r2Adjusted: 'Adjusted r²',
      significanceF: 'Significance F',
      coefficients: 'Coefficients',
      standardError: 'Standard error',
      matrix: 'Matrix',
      selectTagsMatrix: 'Select tags yo show matrix',
    },
    clustering: {
      cluster: 'Cluster',
      clusters: 'Clusters',
      yAxis: 'Scale y',
      xAxis: 'Scale x',
    },
  },
  equipmentManagement: {
    stoppingChart: {
      sector: 'Sector',
      stopTime: 'Downtime',
      percentage: 'Percent',
      cumulativePercentage: 'Cumulative percentage',
      notPointed: 'Not pointed',
      showNotPointed: 'Show not pointed',
      notPlanned: 'Not planned',
      planned: 'Planned',
      quantity: 'Quantity',
      hours: 'Hours',
      dataToShow: 'Data to show',
      inOperation: 'In operation',
      paretoByMotive: 'Pareto diagram for motive',
      paretoByGroup: 'Pareto diagram by group of motives',
      paretoByFrame: 'Pareto diagram by event',
      pizzaByMotive: 'Percentage by motive',
      pizzaByGroup: 'Percentage by group of motives',
      pizzaByFrame: 'Percentage per event',
      ganttByMotive: 'Timeline for motive',
      ganttByGroup: 'Timeline by group of motives',
      ganttByFrame: 'Timeline for event',
    },
    stoppingPoint: {
      manualPoint: 'Manual pointing',
      totalTime: 'Total time',
      finished: 'Finished',
      open: 'Open',
      annul: 'Annulled',
      stillOpen: 'The downtime is still occurring',
      splitStop: 'Split downtime',
      deleteStop: 'Delete stop',
      split: 'Split',
      serviceOrder: 'Service Order',
      separatedByCalendar:
        'This event may be separated by a calendar, if it is necessary to point out different reasons, complements or observations, please divide the stop',
      historic: 'Historic',
      deletePoint: 'Delete Point',
      updates: 'Updates',
      notPointed: 'Not pointed',
      pointed: 'Pointed',
      showMicroStop: 'Show micro-stop',
      motivesGroup: 'Motives group',
      annulStoppingConfirmTitle: 'Do you want to annul this item?',
      annulStoppingConfirmContent:
        'When you annul this stop, it will not be deleted, but it will no longer be counted.',
      enableStoppingConfirmTitle: 'Do you want to enable this item?',
      enableStoppingConfirmContent:
        'When you enable this stop, it will be counted again.',
    },
    approveStop: {
      show: 'Show',
      approve: 'Approve',
      reprove: 'Repprove',
      approved: 'Approved',
      reproved: 'Reproved',
      historic: 'Historic',
    },
    configuration: {
      importItems: 'Import items',
      sussesImportingEvent: 'Event imported successfully!',
      relationImportWarning:
        'Some settings were not found in the current plant, so they were not imported!',
    },
  },
  consistency: {
    equipmentStop: {
      integratedStatus: 'Integration status',
      notApproved: 'Not approved',
      approved: 'Approved',
      integrating: 'In integration',
      integrated: 'Integrated',
      integratedError: 'Integration error',
      homologation: 'Homologation',
      frameDescription: 'Description of the event',
      daysReleased: 'Days released',
      currentDay: 'Current day',
      stopsList: 'List of downtime',
      loadingReconciliation:
        'Reconciling equipment downtime, this may take a few minutes!',
    },
    productionProcess: {
      releasedBulletin: 'Report released',
      daysBlocked: 'Days blocked',
      daysReleased: 'Days released',
      currentDay: 'Current day',
      wantReconciliation: 'Do you want to reconcile?',
      tagExternal: 'External tag',
      obtainedValues: 'Values obtained',
      limits: 'Specification limits',
      substituteValue: 'Substitute value',
      justification: 'Justification',
      changes: 'Changes',
      textValue: 'Text value',
      numberValue: 'Numeric value',
      substituteVariableType: 'Substitute variable type',
      numeric: 'Numeric',
      alphanumericValue: 'Alphanumeric value',
      substituteValueDecimalsInfo:
        'Numeric values ​​will be formatted according to the decimal places of the tag',
      approved: 'Approved',
      available: 'Provide',
      formEditAlertMessage:
        'The integration status will be reset, do you want to continue?',
      clickToShowData: "Select the period and click 'View' to display the data",
    },
  },
  log: {
    title: 'Log',
    login: 'Login',
    navigation: 'Navigation',
    integration: 'Integration',
    productionProcess: 'Production and process',
    equipmentStop: 'Equipment Downtime',
    dataReconciliation: 'Data reconciliation',
    dataEntry: 'Data input',
    colect: 'Manual collection',
    manual: 'Manual',
    dateSchedule: 'Date/Schedule',
    consolidation: 'Consolidation',
    consolidatedLog: 'Consolidated log',
    appointmentStop: 'Appointment stop',
    rootCause: 'Root cause',
    equipmentManager: 'Equipment manager',
    system: 'System',
    action: 'Action',
    logged: 'Logged in/out',
    approveStop: 'Approve stop',
    errors: {
      conversion: 'Conversion error',
      eventManager: 'Event Manager error',
      consolidation: 'Consolidation error',
    },
    actions: {
      execution: {
        conversion: 'Conversion executed',
        eventManager: 'Event Manager executed',
        integrationAPI: 'Integration API executed',
        productionOrder: 'Production order executed',
        productionOrderAutomaticAppointment:
          'Production order automatic appointment executed',
        route: 'Inspection route executed',
      },
      access: 'Access',
      insert: 'Insert',
      delete: 'Delete',
      update: 'Update',
      reversed: 'Reversed',
      login: 'Login',
      logoff: 'Logoff',
      loginApp: 'Login - APP',
      logoffApp: 'Logoff - APP',
      integrate: 'Integrate',
      reconciliate: 'Reconciliate',
      insertPoint: 'Insert point',
      approve: 'Approve',
      approved: 'Approved',
      reproved: 'Reproved',
      insertTag: 'Inserted tag',
      editTag: 'Edited tag',
      deletedTag: 'Deleted tag',
      tagsDeletedInfo:
        'First date point: {firstDate}, last date point: {lastDate}, quantity of points: {count}',
      tagsInsertedInfo: 'Value: {currentValue}, point date: {pointDate}',
      tagsEditedInfo:
        'Previous value: {prevValue}, current value: {currentValue}, point date: {pointDate}',
      insertStepItemNote: 'Insert note (production order)',
      updateStepItemNote: 'Edit note (production order)',
      deleteStepItemNote: 'Delete note (production order)',
      reverseStepItemNote: 'Reverse note (production order)',
      insertManufactureMotive: 'Insert motive (manufacture)',
      updateManufactureMotive: 'Edit motive (manufacture)',
      insertManufactureMotiveGroup: 'Insert motive group (manufacture)',
      updateManufactureMotiveGroup: 'Edit motive group (manufacture)',
      updateShortcut:
        'New Description: {description} | New Date init: {date_init} | New Date end: {date_end} | New Tags ID: {tags_id} | Old Description: {old_description} | Old Date init: {old_date_init} | Old Date end: {old_date_end} | Old Tags ID: {old_tags_id}',
      insertShortcut:
        'Description: {description} | Date init: {date_init} | Date end: {date_end} | Tags ID: {tags_id}',
      deleteShortcut:
        'Description: {description} | Date init: {date_init} | Date end: {date_end} | Tags ID: {tags_id}',
      updateShortcutFrame:
        'New Description: {description} | New Date init: {date_init} | New Date end: {date_end} | New Frame ID: {frames_id} | Old Description: {old_description} | Old Date init: {old_date_init} | Old Date end: {old_date_end} | Old Frame ID: {old_frames_id}',
      insertShortcutFrame:
        'Description: {description} | Date init: {date_init} | Date end: {date_end} | Frame ID: {frames_id}',
      deleteShortcutFrame:
        'Description: {description} | Date init: {date_init} | Date end: {date_end} | Frame ID: {frames_id}',
    },
  },
  dataEntry: {
    messages: {
      lessEqualThan: 'Enter a value less than or equal than',
      greaterEqualThan: 'Enter a value greater than or equal than',
      outLowerEspecificationLimit:
        'Value outside the lower specification limit',
      outUpperEspecificationLimit:
        'Value outside the upper specification limit',
      outEspecificationLimit: 'Out of specification limit',
      outValidLimit: 'Outside valid limit',
      logbookSuccess: 'Successful note',
      logbookError: 'Error when trying to save note',
      logbookEdit: 'Edited note successfully',
      logbookDelete: 'Deleted note successfully',
    },
    csv: {
      dataError: 'The date format is incorrect',
      downloadExample: 'Download example',
      selectCsv: 'Select CSV',
    },
    manual: {
      dateAndTime: 'Date and time',
    },
    colect: {
      colectGroupAndTags: 'Collection / Collection group with tags',
      selectGroup: 'Select group',
      inputManualTags: 'Manual entry type tags',
      noneInputManualTagSelected: 'No manual entry tag selected',
      reloadData: 'Reload table data',
    },
    notes: {
      title: 'Notes',
      notesField: 'Notes field',
    },
  },
  opsManagement: {
    redo: 'Recalculate production',
    externalCode: 'External code',
    description: 'Description',
    codeDescription: 'Code / Description',
    plannedExecuted: 'Produced / Planned',
    order: 'Order',
    inProduction: 'In production...',
    productionStartIn: 'Production will start in',
    lastProcessing: 'Last processed',
    validatingOrder: 'Validating production order',
    reviewOrder: 'Waiting for Review',
    concludedIn: 'Finished at',
    addProductionOrder: 'Add production order',
    filterByDate: 'Filter by date',
    filterByCode: 'Filter by code',
    productionOrderForm: 'Production order form',
    typeCode: 'Enter the item code',
    typeExternalCode: "Enter the item's external code",
    typeDescription: 'Type description',
    planned: 'Planned quantity',
    typeMin: 'Enter the minimum production quantity',
    typeUniqueCode: 'Enter a unique code',
    dateToStart: 'Start date',
    dateToEnd: 'End date',
    productionList: 'Production List',
    percentageTolerance: 'Tolerance',
    produced: 'Quantity Produced',
    reviewForm: 'Review Production Order Item',
    review: 'Review',
    viewReview: 'View Review',
    calculatedQuantity: 'Calculated Quantity',
    revisedQuantity: 'Revised Quantity',
  },
  manufacture: {
    appointmentNotAllowedByStatus:
      'This appointment cannot be made as the order is in status "{status}"',
    actionNotAllowedByStatus: 'The order is in status "{status}"',
    archivedList:
      'This appointment cannot be made because the checklist is archived.',
    noPermissionForAction: 'You do not have permission for this action.',
    reopenConfirmation: 'Are you sure you want to reopen this order?',
    itemVinculatedToChecklist: 'The item is being used in a checklist.',
    productionOrderHistoric: 'Production order history',
    conflictNoteInfo: 'This period conflicts with an existing appointment',
    duplicatedCode:
      'The order code already exists for this product, please choose another',
    duplicatedSrcOrderCode:
      'There is already a production order routing with this code ({description}), please choose another code.',
    showDefectLocation: 'Show defect location',
    exportHorizontalList: 'Horizontal List',
    exportExpandedList: 'Expanded List',
    serialCode: 'Serial code',
    searchSerialByDate: 'Search serial by period',
    searchPoBySerialCode: 'Search by P.O.',
    searchSerialCode: 'Search by serial code',
    searchLot: 'Search by lot',
    extraFields: 'Extra fields',
    defects: 'Defects',
    status: 'Status',
    line: 'Line',
    product: 'Product',
    noOpsToShow: 'No production orders to show',
    orderCode: 'Order code',
    itemCode: 'Item code',
    quantityPlanned: 'Quantity planned',
    quantityFulfilled: 'Quantity fulfilled',
    quantityGood: 'Quantity good',
    quantityJunk: 'Quantity scrap',
    quantityProduced: 'Quantity produced',
    quantityAvailable: 'Quantity available',
    sourceLot: 'Lot origin',
    destinationLot: 'Lot destination',
    junkMotiveAppointments: 'Junk appointment motive',
    defect: 'Defect',
    itemUnit: 'Unit',
    planner: 'Planner',
    operator: 'Operator',
    itemDescription: 'Item description',
    generated: 'Generated',
    planned: 'Planned',
    scheduled: 'Scheduled',
    running: 'Running',
    inCompletion: 'In completion',
    finished: 'Finished',
    canceled: 'Canceled',
    stageList: 'Stages',
    step: 'Step',
    steps: 'Steps',
    components: 'Components',
    pieceHour: 'Piece per hour',
    location: 'Location',
    generalPoData: 'General production order data',
    srcOrderCode: 'Source order code',
    umInventory: 'U.M. Inventory',
    umProduction: 'U.M. Produção',
    totalTime: 'Runtime performed',
    startTime: 'Generation date',
    generationUser: 'Generation user',
    changeTime: 'Date of change',
    changeUser: 'Change user',
    startHour: 'Start hour',
    endHour: 'End hour',
    referenceDate: 'Reference date',
    externalCode: 'External code',
    production: 'Production',
    junk: 'Junk',
    good: 'Good',
    subProduct: 'Subproduct',
    consumption: 'Consumption',
    consumptionSummary: 'Summary of consumption components',
    stopsList: 'List of downtime',
    pO: 'P.O.',
    searchProductionOrder: 'Search P.O.',
    boolean: 'Compliance status',
    optionList: 'List of options',
    validate: 'Validate',
    validateAppointment: 'Validate appointment',
    notPointed: 'Not pointed',
    pointed: 'Pointed',
    itemType: 'Item type',
    selectedOptionText: 'Selected option text',
    notSelectedOptionText: 'Not selected option text',
    ok: 'OK',
    notOk: 'NOK',
    element: 'Element',
    defaultValue: 'Default value',
    checklist: 'Checklist',
    importJustification: 'Import justification',
    frequency: 'Frequency',
    productCode: 'Product code',
    scriptCode: 'Script Code',
    productDescription: 'Product description',
    scriptDescription: 'Script description',
    addChecklist: 'Add checklist',
    hour: 'Every hour',
    shift: 'Every shift',
    start: 'When starting production order',
    end: 'When finalizing production order',
    volume: 'By volume produced',
    every: 'Every',
    addProduct: 'Add product',
    addStep: 'Add step',
    addStepItem: 'Add step item',
    addComponent: 'Add component',
    addSubstituteComponent: 'Add substitute component',
    calculationStep: 'Step completion',
    appointmentType: 'Appointment type',
    productionOrder: 'Production order',
    unitMeasurement: 'Unit measurement',
    newProductionOrder: 'New production order',
    checkListItem: 'Checklist item',
    checkListItemAlreadyAssociated:
      'This item is already associated with a checklist, changes cannot be made.',
    itemAlreadyAssociated:
      'This item is already associated with a route, changes cannot be made.',
    itemAlreadyAssociatedPO:
      'This item is already associated with a production order, changes cannot be made.',
    appointment: 'Appointment',
    seeCompletePo: 'See complete P.O.',
    cancelPo: 'Cancel P.O.',
    poCanceled: 'P.O. Canceled',
    workInstruction: 'Work instruction',
    variation: 'Variation',
    derivation: 'Derivation',
    newDerivation: 'New derivation',
    createDerivation: 'Are you sure you want to create a new derivation?',
    derivationInserted: 'Derivation entered successfully',
    substituteProduct: 'Substitute products',
    automatic: 'Automatic',
    manual: 'Manual',
    startType: 'Start type',
    endType: 'End type',
    expectedStartDate: 'Expected start date',
    expectedEndDate: 'Expected end date',
    startConfirmation: 'Are you sure you want to start the P.O.?',
    endConfirmation: 'Are you sure you want to end the P.O.?',
    startPo: 'Start P.O.',
    endPo: 'Send to completion',
    allowInstruments: 'Allow linking instruments',
    groupSelectionView: 'Show only for selected groups',
    instruments: 'Instruments',
    elements: 'Elements',
    instrumentsIsVinculated:
      'Instruments that are linked to the element will be used.',
    blockedForInformativeType:
      'This registration is not available for “informational” type items',
    ignoreChecklist: 'Ignore checklist?',
    alreadyIgnoredChecklist:
      'This order is already set to ignore the checklist.',
    confirmIgnoreChecklist: 'Are you sure you want to ignore the checklist?',
    finalizationForm: 'Form for finalization',
    quantitative: 'Quantitative',
    infoText: 'Information text',
    info: 'Informative',
    altText: 'Alt text',
    additionalInformation: 'Additional information',
    sequenceInputHelp: 'There is already an item with this value',
    batchCreationWarning:
      'Note: Created batches cannot be edited. Do you want to proceed?',
    productTagHelp:
      'Tag not associated with the element tree, which prevents automatic production of this product through this tag in the manufacturing module.',
    addElement: 'Add element',
    noInstrumentsAvailable: 'There is no instrument associated with list items',
    startOfStep: 'Start of step',
    startOfOrder: 'Start of order',
    componentsWithoutAppointmentInfo:
      'Attention: Some components are missing references. Would you like to proceed?',
    percentageExceededToleranceInfo:
      'The tolerance percentage has been exceeded. Please add a justification in the comments field.',
    percentageExceededToleranceInfoBlockSaveButton:
      'The tolerance percentage has been exceeded, therefore, the annotation cannot be carried out.',
    reverseStepItemNoteInfo:
      'The specified quantity will be refunded entirely, and this action cannot be undone. Do you wish to proceed?',
    itemsWithoutInstrument:
      'There are items checked without instrument selected',
    samples: 'Samples',
    fixedRange: 'Fixed range',
    minRange: 'Min range',
    maxRange: 'Max range',
    normalSampling: 'Normal sampling',
    reinspectionSampling: 'Reinspection sampling',
    range: 'Range',
    needToInformRange: 'Need to inform range',
    approvedItems: 'Approved items: ',
    disapprovedItems: 'Disapproved items: ',
    pendingItems: 'Pending items: ',
    useSample: 'Use sampling',
    linkTo: 'Link to',
    dependency: 'Depenency',
    dependencies: 'Dependencies',
    addDependency: 'Add dependency',
    triggerType: 'Trigger type',
    dependsOn: 'Depends on',
    ifResult: 'If the result is',
    independent: 'Independent',
    emissionDate: 'Emission date',
    functions: 'Functions',
    allowCancelSrcOrder: 'Allow cancel OP',
    generateChecklist: 'Generate list',
    checkListWithoutSampleInfo:
      'There is no registered sampling, or the quantity provided is outside the allowed limits.',
    orderQuantity: 'Order quantity',
    center: 'Center',
    dateCreation: 'Date creation',
    timeStandard: 'Time STD',
    dependentLists: 'Dependent lists',
    reinspectionBatch: 'Reinspection',
    appointedQuantity: 'Appointed quantity',
    samplingQuantity: 'Sampling Quantity',
    lotUsedIsDifferentInfo: 'Lot used different from the planned lot',
    reviewers: 'Reviewers',
    approvers: 'Approvers',
    revisionButtonInfo:
      'You can only create reviews for checklists that have already been approved or do not have an associated approval matrix.',
    checkListItemClassification: 'Classification of items',
    inspectionType: 'Inspection type',
    sampling: 'Sampling',
    inspection100: '100% inspection',
    unique: 'Unique',
    informationOfLevelIncomplete:
      'Some levels may not be displayed as they are not fully registered.',
    inspection: 'Inspection',
    quickAppointmentInfo:
      'Quick appointment is only available for items of type “Compliance status”',
    quickAppointment: 'Quick appointment',
    appoint: 'Appoint',
    itemNotFound: 'Item not found',
    searchingItems: 'Searching items...',
    searchCheckItem: 'Enter the code or description',
    searchCheckItemInfo:
      'Enter the item code or description to search. The search may take a few moments.',
    productBalanceUnavailable: 'Product balance unavailable.',
    module: 'Module',
    noPendingDocuments: 'There are no pending documents',
    checklistDescription: 'Checklist description',
    limitBy: 'Limit by',
    hasNotOkAppointments:
      'There are items noted as non-conforming, are you sure you want to continue?',
    opNotFound: 'Production order not found.',
    error: {
      orderDeleted:
        'Production order deleted, please return to the production order panel and refresh the page.',
      orderNotFound:
        'Production order not found, please return to the production order panel and refresh the page.',
      lockByGeneratedStatus:
        "The requested action cannot be performed because the status of the production order is set to 'Generated'. Please refresh the page.",
      lockByCanceledStatus:
        "The requested action cannot be performed because the status of the production order is set to 'Canceled'. Please refresh the page.",
      lockByFinishedStatus:
        "The requested action cannot be performed because the status of the production order is set to 'Finished'. Please refresh the page.",
      lockByRunningStatus:
        "The requested action cannot be performed because the status of the production order is set to 'Running'. Please refresh the page.",
      lockByInCompletionStatus:
        "The requested action cannot be performed because the status of the production order is set to 'In completion'. Please refresh the page.",
    },
    checklistForm: {
      process: 'Process',
      observations: 'Observations',
      classification: 'Classification',
      generateByClassification: 'Generate only critical items',
      moldNumber: 'Mold number',
      drawerRevision: 'Drawing revision',
      addObservation: 'Add observation',
      scripts: 'Scripts',
      quality: 'Quality',
      production: 'Production',
      sampling: 'Sampling',
      unique: 'Unique',
      all: '100% inspection',
      deleteComplementaryTableWarning: 'The table cannot be deleted!',
      matrixButtons: {
        sendToReview: 'Send for review',
        sendToApprove: 'Submit for approval',
        reproveReview: 'Reprove review',
        reprove: 'Reprove',
        approve: 'Approve',
        makeCurrent: 'Make current',
      },
      status: {
        created: 'Created',
        sentToReview: 'Sent to review',
        reviewReproved: 'Review reproved',
        sentToApproval: 'Sent to approval',
        approved: 'Approved',
        reproved: 'Reproved',
        current: 'Current',
        filed: 'Filed',
      },
    },
    checkListAppointment: {
      status: {
        created: 'List created',
        archive: 'List archived',
        unarchive: 'List unarchived',
      },
      error: {
        userRequired: 'User and justification are required.',
        quantityNotAllowed:
          'It was not possible to unarchive this list because there is not enough available quantity.',
        quantityUnavailable: 'Quantity unavailable',
        quantityNotAllowedUpdatePage:
          'Quantity greater than allowed for list generation, please reload the page.',
        notPossibleUpdateArchivedChecklist:
          'It was not possible to save the notes because the list is archived. Please refresh the page.',
        checklistNotFound: 'Checklist not found, please reload the page.',
        checklistAlreadyArchived:
          'Checklist already archived, please reload the page.',
        checklistNotArchived: 'Checklist not archived, please reload the page.',
      },
      archiveConfirmMessage: 'Are you sure you want to archive this checklist?',
      archiveConfirmInfo: 'Your appointments will no longer be counted.',
      unarchiveConfirmMessage:
        'Are you sure you want to unarchive this checklist?',
      unarchiveConfirmInfo: 'Your appointments will be counted again.',
    },
    complementaryTable: {
      complementaryTable: 'Complementary table',
      selectComplementaryTable: 'Select a complementary table',
      matrixButtons: {
        sendToReview: 'Send for review',
        sendToApprove: 'Submit for approval',
        reproveReview: 'Reprove review',
        reprove: 'Reprove',
        approve: 'Approve',
        makeCurrent: 'Make current',
      },
      status: {
        created: 'Created',
        sentToReview: 'Sent to review',
        reviewReproved: 'Review reproved',
        sentToApproval: 'Sent to approval',
        approved: 'Approved',
        reproved: 'Reproved',
        current: 'Current',
        filed: 'Filed',
      },
    },
    destOrderLog: {
      status: {
        start: 'Start P.O',
        inCompletion: 'Send to completion',
        cancel: 'Cancel P.O',
        reopen: 'Reopen P.O',
        ignoreChecklist: 'Ignore checklist',
      },
    },
    createdBy: 'Created by',
    reviewedBy: 'Reviewed by',
    approvedBy: 'Approved by',
    effectiveFrom: 'Effective from',
    changeHistory: 'Change history',
    checklistOptionDisabledInfo: 'The checklist is not valid.',
    motiveGroup: {
      duplicatedCode:
        'A motive group with this code ({description}) already exists. Please choose another code.',
      motive: {
        duplicatedCode:
          'A motive with this code ({description}) already exists. Please choose another code.',
      },
    },
    reports: {
      junk: {
        qtyPerMotive: 'Qty X Motive',
        qtyPerComponentPerMotive: 'Qty X Component X Motive',
        qtyPerElementPerMotive: 'Qty X Element X Motive',
        qtyPerLotPerMotive: 'Qty X Lot X Motive',
        qtyPerProductionOrderPerMotive: 'Qty X PO X Motive',
        groupCode: 'Group code',
        groupDescription: 'Group description',
      },
    },
    inspectionLevel: {
      s: 'S',
      l: 'L',
      lotSize: 'Lot size',
      sampleSize: 'Sample size',
      specialInspectionLevels: 'Special inspection levels',
      generalInspectionLevels: 'General inspection levels',
      duplicatedInferiorLimit:
        'A record with this minimum range already exists',
      duplicatedSuperiorLimit:
        'A record with this maximum range already exists',
      s1: 'S1',
      s2: 'S2',
      s3: 'S3',
      s4: 'S4',
      l1: 'N1',
      l2: 'N2',
      l3: 'N3',
    },
    acceptableQualityLevel: {
      aql: 'AQL',
      ac: 'Approve',
      re: 'Reject',
      sampleSize: 'Sample size',
      duplicatedSample: 'A record with this sample already exists',
      aql1: '0.01',
      aql2: '0.015',
      aql3: '0.025',
      aql4: '0.04',
      aql5: '0.065',
      aql6: '0.1',
      aql7: '0.15',
      aql8: '0.25',
      aql9: '0.4',
      aql10: '0.65',
      aql11: '1',
      aql12: '1.5',
      aql13: '2.5',
      aql14: '4',
      aql15: '6.5',
      aql16: '10',
      aql17: '15',
      aql18: '25',
      aql19: '40',
      aql20: '65',
      aql21: '100',
      aql22: '150',
      aql23: '250',
      aql24: '400',
      aql25: '650',
      aql26: '1000',
    },
  },
  operationalSecurity: {
    instrumentWithoutImpairment:
      'Some instruments are inoperative without impairments created',
    userNotInMatrix:
      'Only applicants for this instrument are allowed to create impairment',
    instrumentHasImpairment: 'This instrument has an open impairment',
    allInstruments: 'All instruments',
    withImpairments: 'With impairments created',
    withoutImpairments: 'No impairments created',
    riskLevel: 'Risk level',
    impairmentMaxDuration: 'Maximum duration allowed for impairment',
    routineTestMaxDuration: 'Maximum duration allowed routine test',
    intervalBeteweenImpairments: 'Minimum interval between two impairments',
    routineTestRepeatTime: 'Maximum time for repeat routine test',
    frequencyMitigation: 'Frequency after mitigation',
    riskLevelWithoutMitigation: 'Level of risk without mitigation',
    riskLevelWithMitigation: 'Risk level after mitigation',
    externalCode: 'External code',
    externalDescription: 'External description',
    atuation: 'Atuation',
    logic: 'Logical',
    physical: 'Physical',
    asynchronousInstruments: 'Instruments that cannot run simultaneously',
    isRoutineTest: 'Routine test?',
    testPeriodicity: 'Test periodicity',
    weekly: 'Weekly',
    biweekly: 'Biweekly',
    monthly: 'Monthly',
    semiannual: 'Semiannual',
    yearly: 'Yearly',
    criticalInstrumentTag: 'Tag linked with the critical instrument',
    controlLoopTag: 'Tag linked to the control mesh',
    interlockTag: 'Tag linked with the interlock',
    requesters: 'Requesters',
    requestersAndPerformers: 'Requesters e performers',
    approvers: 'Approvers',
    solicitationForm: 'Request form',
    createdAt: 'Request creation date',
    observations: 'Observations',
    addImpairment: 'Add impairment',
    lastTest: 'Last test',
    tests: 'Tests',
    impairmentWithMitigation:
      'It is only possible to add impairment to this instrument if there is a mitigation measure',
    requesterPermission:
      'Only requesters of this instrument are allowed to create routine test',
    unfinishedRoutineTest:
      'There is already a test in progress for this instrument',
    onlineMonitoring: 'Online monitoring',
    withoutMitigation: 'Without mitigation',
    generatingFile: 'Generating file...',
    impairment: {
      impairment: 'impairment',
      mitigationMeasureQuestion: 'Will mitigation measures be implemented?',
      routineTestMitigation: 'Will mitigation measures be implemented?',
      impairmentJustification: 'Impairment justification',
      minimumTimeInformation:
        'The impairment does not meet the minimum period required between two impairments of the same instrument. The request will be submitted to a higher level of the approval scale. Do you want to proceed?',
      concurrentInstrumentInformation:
        'The impairment does not meet the requirements for the simultaneity of impairments. The request will be sent to a higher level of the approval matrix. Do you want to proceed?',
      concurrentInstruments: 'Concurrent instruments',
      risks: 'Risks',
      mitigationMeasure: 'Mitigation measure',
      characterLimit: 'Character limit: 2000',
      solicitationDate: 'Solicitation date',
      restoreDate: 'Restore date',
      requester: 'Requester',
      approver: 'Approver',
      approvalStatus: 'Approval status',
      executionStatus: 'Execution status',
      solicitation: 'Solicitation',
      reproveJustification: 'Reprove justification',
      approveSolicitation: 'Approve solicitation',
      execution: 'Execution',
      instrument: 'Instrument',
      approved: 'Aproved',
      waiting: 'Waiting',
      waitingExtension: 'Waiting for extension',
      processing: 'Processing',
      disapproved: 'Disapproved',
      routineTest: 'Teste de rotina',
      late: 'Disabled/Late',
      onTime: 'Disabled/On time',
      approvedAt: 'Approved at',
      disapprovedAt: 'Disapproved at',
      approvedDisapproved: 'Approved/Disapproved at',
      executed: 'Executed',
      notExecuted: 'Not executed',
      tested: 'Tested',
      notTested: 'Not tested',
      executedAt: 'Execution date',
      restorationLimit: 'Date for restoration',
      instrumentState: 'Current instrument status',
      durationExtensionForm: 'Duration extension form',
      invalidDurationMessage:
        'The date needs to be greater than the current date for restoration',
      extendTo: 'Extend deadline for',
      instrumentOperatingStatus: 'Instrument operating status',
      controlLoopStatus: 'Control loop status',
      interlockStatus: 'Interlock status',
      inoperative: 'Inoperative',
      operant: 'Operant',
      manual: 'Manual',
      automatic: 'Automatic',
      disabled: 'Disabled',
      able: 'Able',
      concurrentLevelIncrease: 'Level increase due to concurrency',
      maxTimeRequestLevelIncrease: 'Level increase due to duration increase',
      currentLevel: 'Current level',
    },
    restoration: {
      beenRestored: 'Has the instrument been restored?',
      restoredAt: 'Restoration date',
      restoration: 'Restoration',
      restored: 'Restored',
      notRestored: 'Not restored',
      waiting: 'Waiting',
    },
    execution: {
      date: 'Date',
      wasExecuted: 'The impairment was executed?',
      executedAt: 'Executed at',
    },
    routineTest: {
      testJustification: 'Test justification',
      testMaxDuration: 'Test max duration',
      routineTests: 'Routine tests',
      routineTest: 'Routine test',
      routineTestQuestion: 'Was a routine test performed?',
      impairmentDuringTestQuestion:
        'Impairment will be performed during the test?',
      restorationQuestion: 'Is the SCI restored after the test?',
      successfulQuestion: 'Was the test successful?',
      repeatQuestion: 'Will the test need to be repeated?',
      instrumentPassedQuestion:
        'Did the instrument satisfactorily meet the test?',
      testLimitDate: 'Data limit for the test',
      repeatTestLimit: 'You have {time} to repeat the test',
      completedOk: 'Completed - on time',
      completedLate: 'Completed - late',
      waitingOk: 'Waiting - on time',
      waitingLate: 'Waiting - late',
    },
  },
  managementOfChanges: {
    noAreaAvailable: 'There are no areas registered',
    ssma: 'SSMA',
    engineering: 'Engineering',
    industry: 'Industrial Area',
    mocNumber: 'MOC Number',
    area: 'Area',
    solicitation: 'Solicitation',
    requester: 'Requester',
    standardArea: 'Standard Area',
    criticalArea: 'Critical Area',
    addSolicitation: 'Add solicitation',
    nameOfMOC: 'Name of MOC',
    type: 'Type',
    model: 'Model',
    emergency: 'Emergency',
    technique: 'Technique',
    default: 'Default',
    temporary: 'Temporary',
    deadline: 'Deadline',
    currentModelDescription: 'Descripción del modelo actual',
    changeDescription: 'Description of the proposed change',
    mocSolicitation: 'MOC Solicitation',
    riskAssessment: 'Risk assessment',
    riskList: 'Risk list',
    risk: 'Risks',
    probability: 'Probability',
    comments: 'Comments',
    notApplicable: 'n/a',
    actionPlan: {
      title: 'Action plan',
      progressStatus: 'Progress status',
      deadlineStatus: 'Deadline status',
      executed: 'Concluded',
      waiting: 'Waiting',
    },
    additionalInformation: 'Additional information',
    isCriticalArea: 'Requested change area',
    responsibleArea: 'Responsible area',
    responsibleUser: 'Responsible user',
    initialDate: 'Inicial date',
    durationInDays: 'Duration in days',
    expectedDate: 'Expected date',
    endDate: 'End date',
    observation: 'Observation',
    approvalSector: {
      ssma: 'Approval form SSMA',
      engineering: 'Approval form engineering',
      industrialArea: 'Approval form industrial area',
    },
    approver: 'Approver',
    authorizer: 'Authorizer',
    approved: 'Aproved',
    waiting: 'Waiting',
    disapproved: 'Disapproved',
    onTime: 'On time',
    late: 'Late',
    executed: 'Executed',
    waitingExecution: 'Waiting execution',
    execution: 'Execution',
    restoration: 'Restoration',
    changeWasExecuted: 'Has the change been executed?',
    changeWasRestored: 'Has the change been reinstated?',
    approvals: 'Approvals',
    finalStatus: 'Final status',
    changeImplementation: 'Implementation of MOC',
    restored: 'Restored',
    notRestored: 'Not restored',
    notStarted: 'Not started',
    inProgress: 'In progress',
    actionPlanStatus: 'Action plan status',
    changeStatus: 'General request status',
    waitingApproval: 'Waiting approval',
    waitingRestoration: 'Waiting restoration',
    notExecuted: 'Not executed',
    restorationOnTime: 'Restored on time',
    restorationLate: 'Restored overdue',
    riskLegend: '5 = very likely; 1 = unlikely; n/a = does not apply',
    maxLength200: 'Max length is 200',
    maxLength2000: 'Max length is 2000',
    cancelMoc: 'Cancel MOC',
    canceledMoc: 'Canceled MOC',
    canceled: 'Canceled',
    cancelConfirmation: 'Are you sure you want to cancel this MOC?',
    deActivateConfirmation: 'Deactivating will make this tag not be considered',
    activateConfirmation: 'Activating will make this tag be considered',
    solicitationDate: 'Solicitation date',
    mocPerformer: 'MOC Performer',
    restorationPerformer: 'Restoration Performer',
    approvers: 'Approvers',
  },
  scale: {
    ticketNumber: 'Ticket number',
    dateEntry: 'Start date',
    dateOut: 'End date',
    weight: 'Liquid weight',
    board: 'Plate',
    inputCode: 'Input code',
    input: 'Input',
    provider: 'Provider',
    truck: 'Truck',
    conveyer: 'Conveyor',
    nfeWeight: 'NFE weight',
    nf: 'Tax note',
    filterByDate: 'Filter by date',
  },
  analytics: {
    comparativeBetweenPeriods: {
      skew: 'Asymmetry',
      kurtosis: 'Kurtosis',
      shapiro: 'P-value (shapiro-wilk normality test)',
      varianceGlobal: 'Global variance',
      standardDeviationGlobal: 'Global standard deviation',
      addGroup: 'Add group',
      enterTitle: 'Enter a title for the group',
      overlaid: 'Overlaid',
      parameter: 'Parameter',
      groupOne: 'Group 1',
    },
    correlation: {
      title: 'Correlation',
      regressionLine: 'Regression line',
      absoluteValue: 'Absolute value',
      a: 'A',
    },
    eventManager: {
      addedEvent:
        'Event added to the processing queue, this may take a few minutes!',
      remakeError:
        'An error ocurred while recreating the event, please try again!',
      remakeSucess: 'Event recreated successfully!',
      selectFrame: 'Select an event with data to generate the chart',
      recreateEvents: 'Are you sure you want to recreate the events?',
      recreate: 'Recreate',
      open: 'Open',
      close: 'Close',
      canceled: 'Anull',
      moreInformation: 'Select an event to see more information',
      closingDate: 'Closing date',
      insertType: 'Insert type',
      auto: 'Automatic',
      manual: 'Manual',
      alertCalendarActive:
        'The period viewed has an active calendar, so some events may not be shown.',
      showCalendar: 'Show calendar',
      divided: 'Divided',
      openEventDescription:
        'The allowed end date/time is the current time, but there may be delays in the automatic identification of the end of the event, causing the real end time to differ from the selected time when the event is processed',
      configuration: {
        itemCode: 'Item ID',
        itemDescription: 'Item Description',
        selectAlert: 'Select an alert',
        rangeIn: 'Range (IN)',
        rangeOut: 'Range (OFF)',
        maxTimeExceeded: 'Maximum time exceeded',
        equipmentManagement: 'Equipment management',
        active: 'Active',
        preview: 'Preview',
        conditionsStart: 'Conditions - start',
        conditionsEnd: 'Conditions - end',
        advancedOptions: 'Advanced options',
        time: 'Time',
        minDuration: 'Minimum duration time',
        inSeconds: 'In seconds',
        seconds: 'seconds',
        minInterval: 'Minimum interval between events',
        maxTimeMicroStop: 'Maximum micro downtime time',
        executionInterval: 'Execution interval',
        lastExecution: 'Last execution',
        minutes: 'minute',
        standardMicroStop: 'Standard reason micro stop',
        complementaryTypes: 'Complementary types',
        motivesGroup: 'Motives Group',
        selectItem: 'Select an item',
        selectEvents: 'Select the events',
        typeHelp:
          'Supplementary items directly associated with the Event appear in the Stop Record regardless of the reason selected.',
        referenceValueHelp:
          'Reference value to check the next point after the event starts.',
        breakDay: 'End event at end of day',
        motives: {
          planned: 'Planned',
          motiveCode: 'Cause code',
          motiveCodeDuplicate: 'Cause code already in use',
          motiveDescription: 'Cause description',
          needApprove: 'Need approve',
        },
        permissions: 'Permissions',
        relatedFrames: 'Related Frames',
        relatedFramesTime: 'Difference in seconds of frames',
        userPermissions: 'Users permissions',
        enableCancelStopping: 'Allow to annul stop',
        alreadyAssociatedAutomaticTag:
          'there is already an associated auto-pointing group',
      },
    },
    operator: {
      title: 'Operator',
      shiftScheduling: 'Shift scheduling',
      noneOperator: 'No operator scheduled for this period',
      fillDates: 'Paste to next dates',
      duplicatedScheduleMessage:
        'Impossible to continue, scheduling already registered in',
      showGoal: 'Show Goal',
      hideGoal: 'Hide Goal',
      column: {
        goal: 'Goal',
      },
    },
    report: {
      diary: 'Day',
      weekly: 'Weekly',
      monthly: 'Monthly',
      accumulated: 'Accumulated',
    },
    process: {
      recalculateQuestion: 'Do you want to recalculate converted points?',
      recalculate: 'Recalculate',
      reconsolidateQuestion: 'Do you want to recalculate consolidated points?',
      reconsolidate: 'Reconsolidate',
    },
  },
  rootCause: {
    statusHistory: 'Status history',
    active: 'Active',
    inactive: 'Inactive',
    rule: 'Rule',
    maxTimeStatus: 'No fill in {time} hours',
    removed: 'Successfully removed!',
    effectNotFound: 'This effect could not be found',
    fiveWTwoH: '5W, 2H',
    deleteConfirmation:
      'Are you sure you want to delete this problem / effect?',
    causeConfirmation: 'Are you sure you want to cancel this cause?',
    removeCauseConfirmation: 'Are you sure you want to remove this cause?',
    removeProblemEffect: 'Remove this problem / effect',
    causeError: 'There was a problem saving the cause, please try again ...',
    insertCause: 'Insert cause',
    waitingConclusion: 'Awaiting completion',
    waitingVerification: 'Awaiting verification',
    pendingVerification: 'Pending verification',
    checkedEffective: 'Verified - effective',
    checkedIneffective: 'Verified - ineffective',
    efficiencyError: 'Error when trying to save verification',
    attachmentError:
      'Error when trying to delete the attachment for verification',
    late: 'Late',
    onTime: 'On time',
    efficiencyAction: 'Effectiveness of the action',
    responsable: 'Responsible',
    examiner: 'Examiner',
    change: 'Change',
    status: 'Verification status',
    approvalStatus: 'Approval Status',
    verificationDate: 'Verification date',
    selectDate: 'Select the date',
    commentary: 'Comment',
    conclusionDate: 'Conclusion date',
    writeCommentary: 'Write a review',
    editActionNotAllowed:
      'It is not allowed to edit an action that has already been completed or canceled',
    attachments: 'Attachments',
    saveAvaliation: 'Save review',
    needStatus: 'Need to inform the status along with the verification date',
    canceled: 'Canceled',
    causeCanceled: 'Cause canceled',
    causeCanceledDescription:
      'This cause has been canceled, the activities of this cause will be suspended',
    noneRootCause: 'There are no root causes',
    askApproval: 'Request approval',
    completed: 'Concluded',
    pending: 'Pending',
    open: 'Open',
    removeActionConfirmation: 'Are you sure you want to remove this action?',
    duplicateActionConfirmation:
      'Are you sure you want to duplicate this action?',
    onlyPendingStatus:
      'You can only duplicate the action if the status is pending',
    situation: 'Situation',
    manager: 'Manager',
    applicator: 'Applicator',
    deadline: 'Deadline',
    why: 'Link with why',
    listActionsError:
      'There was a problem trying to list the actions, please try again',
    actionPlan: 'Action plan',
    addAction: 'Add action',
    writeTitle: 'Please write a title for this action',
    actionDetails: 'Action details',
    whatToDo: 'What should be done',
    whyToDo: 'Why it should be done',
    howToDo: 'How it should be done',
    assistance: 'Follow up',
    whysSave: 'Why successfully saved',
    whys: 'Why',
    emptyWhys: 'Saving with empty sockets is not allowed',
    origin: 'Source',
    cause: 'Cause',
    causes: 'Causes',
    addCause: 'Add cause',
    classification: 'Classification',
    gravity: 'Gravity',
    urgency: 'Urgency',
    tendency: 'Trend',
    searchUser: 'Search user',
    allWithFilter: 'All (with filter)',
    lastFive: 'Last 5 registered (without filter)',
    successEffect: 'Effect added successfully!',
    errorNewEffect: 'Error when trying to create a new effect',
    errorRequestEffect:
      'There was a problem requesting the effects, please try again',
    addEffect: 'Add effect',
    analysisStatus: 'Analysis status',
    byDescription: 'By description',
    filterByDescription: 'Filter by description',
    effectForm: 'Effect form',
    effectDescription: 'Effect description',
    typeUser: 'Type user',
    addExaminer: 'Add Examiner',
    errorListActions:
      'There was a problem trying to list the actions, please try again',
    registerIn: 'Registred in',
    access: 'Access',
    efficiencySituation: 'General analysis status',
    noDate: 'No date set',
    addAttachment: 'Click to add attachment',
    file: 'file',
    tagsGroup: 'Group of tags',
    generateAnalysis: 'Generate analysis',
    generateRootCauseAnalysis: 'Generate root cause analysis',
    errorGetEffects:
      'Error when trying to consult the registered effects, please, try again',
    createdAt: 'Root cause created at:',
    goToRootCause: 'Go to root cause',
    verificationDeadline: 'Deadline for verification',
    sendToApprove: 'Send to approval',
    alreadySentToApprove: 'Sent to approval',
    approvedApproval: 'Approved',
    disapprovedApproval: 'Disapproved',
    canceledStatus: 'Canceled',
    notStartedOk: 'Not started - on time',
    notStartedLate: 'Not started - late',
    inProgressOk: 'In progress - on time',
    inProgressLate: 'In progress - late',
    pendingVerificationOk: 'Pending verification - on time',
    pendingVerificationLate: 'Pending verification - late',
    completedOk: 'Completed - on time',
    completedLate: 'Completed - late',
    ishikawa: 'Ishikawa',
    efficiency: 'Efficiency',
    extremelySerious: 'Extremely serious',
    verySerious: 'Very serious',
    serious: 'Serious',
    lowSerious: 'Low serious',
    notSerious: 'Not serious',
    worseQuickly: 'Will get worse quickly',
    worseInShortPeriod: 'Will get worse in short period',
    worseInMediumPeriod: 'Will get worse in medium period',
    worseInLongPeriod: 'Will get worse in long period',
    notChange: 'Will not change',
    needActionNow: 'Need action now',
    veryUrgent: 'Very urgent',
    urgentShortPeriod: 'Urgent, attention in short period',
    lowUrgent: 'Low urgent',
    canWait: 'Can wait',
    machine: 'Machine',
    environment: 'Environment',
    method: 'Method',
    measure: 'Measure',
    labor: 'Labor',
    feedStock: 'Raw material',
    approved: 'Approved',
    disapproved: 'Disapproved',
    waitingApproval: 'Waiting Approval',
    indicator: 'Indicator',
    selectUser: 'Select user',
    mostRecent: 'Most recent:',
    formatMustBe: 'Format must be HH:mm or HHH:mm',
    description: 'Description:',
    tag: 'Tag:',
    useActionPlanLimits: 'Use action plan limits',
    allowSimultaneousAnalyzes: 'Allow to generate concurrent analyzes',
    pointsOutOfBounds: 'Number of consecutive points outside the limit',
    dateFieldOrientation: 'Use vertical date orientation',
    defaultAggregation: 'Default aggregation',
    insufficientPoints:
      'Insufficient number of points outside the limits for analysis generation',
    needToSave:
      'To be able to add "child" whys, first you must save the "parents" whys',
  },
  dashboard: {
    maxLengthSubview:
      'Cannot add more widgets to this tab as the widget limit has been reached',
    maxLengthWidget:
      'No more components can be added to this widget because the component limit has been reached',
    treemapEmptyDescription:
      'No points outside specification limits. All data is within normal parameters.',
    exhibitionType: 'Comparative display option',
    exhibitionTypeInfo:
      'To enable this configuration, the graph must be of the “line” type and have one of the limit or goal display options active',
    redCircle: 'Red circles',
    redLine: 'Red line',
    noExhibition: 'No display',
    redCircleInfo:
      'The point outside the limits or goal will be displayed through a red circular representation',
    redLineInfo:
      'Points outside the limits or goal will be displayed through a solid red line',
    notExistsPoints: 'No points found',
    tabIsSelected:
      'The selected tab is of type “{type}”, only tabs of the same type can be selected',
    selectTabs: 'Select the tabs to be exported',
    exportingDashboard: 'Exporting, this may take a few minutes. Please wait',
    moreThanOneTabType:
      'There is more than one different type of tab, so it is not possible to export the complete dashboard',
    exportDashboard: 'Export dashboard to PDF',
    onlySpcTags: 'Only tags belonging to the SPC are displayed',
    useGoal: 'Use goal',
    fixIndicatorColumn: 'Fix indicator column',
    deleteDashboard: 'The dashboard has been deleted',
    errorDeleteDashboard: 'There was a problem trying to delete the dashboard',
    deleteDashboardConfirmation:
      'Are you sure you want to delete this dashboard?',
    fullscreen: 'Full screen',
    exitFullscreen: 'Exit full screen',
    reproduction: 'Play',
    exitReproduction: 'Exit TV mode',
    startTvMode: 'TV mode - dashboard',
    startTvModeTab: 'TV mode - current tab',
    tvMode: 'TV mode',
    editDashboard: 'Edit dashboard',
    excludeDashboard: 'Delete dashboard',
    unauthorized: 'Sorry, you are not authorized to access this page',
    cannotEditType:
      'It is not allowed to change the dashboard type when tabs are created',
    initialPage: 'Home page',
    daysReleased: 'Days released',
    currentDay: 'Current day',
    dashboardForm: 'Dashboard form',
    writeDescription: 'Please enter the description',
    switchTabTimer: 'Auto Tab cicle time',
    switchTabTimerDescription:
      'Time in seconds that each tab will be displayed in TV mode',
    autoLoadingDescription:
      'Load graphics from all tabs when you open the dashboard',
    autoLoading: 'Automatically load',
    typeDescription: 'Dashboard Type',
    typeDefaultLabel: 'Standard Dashboard',
    typeAppLabel: 'APP Dashboard',
    typeHmiLabel: 'HMI Dashboard',
    userDashboardAccess: 'Users who will have access to the dashboard',
    lastUpdate: 'Last update',
    ll: 'LL',
    ul: 'UL',
    upper: 'Upper',
    lower: 'Lower',
    goal: 'Goal',
    componentDeleted: 'The component has been deleted',
    componentDeleteError: 'There was a problem trying to delete the component',
    updateData: 'Update data',
    editComponent: 'Edit component',
    deleteComponent: 'Delete component',
    noIndicators: 'No indicators',
    checkSettings: 'There was a problem, please check the settings',
    zIndexUpButton: 'Bring to front',
    zIndexDownButton: 'Send to back',
    alignTop: 'Align to top',
    alignLeft: 'Align to left',
    alignBottom: 'Align to bottom',
    alignRight: 'Align to right',
    alignCenterVertical: 'Align center vertical',
    alignCenterHorizontal: 'Align center horizontal',
    link: 'Link',
    componentType: 'Component type',
    subTitle: 'Subtitle',
    headerColor: 'Header color',
    prefix: 'Prefix',
    suffix: 'Suffix',
    refreshDelay: 'Data update cicle',
    refreshDelayDescription:
      'Time in seconds of the data update interval in TV mode',
    direction: 'Direction',
    aggregationPeriod: 'Aggregation period',
    aggregationExibition: 'Aggregation exibition',
    dateFormat: 'Date format',
    updateDate: 'Update date',
    legend: 'Legend',
    serieAmplitude: 'Series range',
    goalPercentage: 'Goal tolerance',
    goalPercentageInfo:
      'If the goal is between tracks, the percentage will be based on the difference between the tracks',
    currentShift: 'Current shift',
    currentWeek: 'Current week',
    currentMonth: 'Current month',
    currentHarvest: 'Current harvest (*)',
    currentYear: 'Current year',
    last: 'Last',
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
    useCompleteAmplitude: 'Use full range',
    useFullHour: 'Use full hour',
    componentForm: 'Component form',
    advancedOptions: 'Advanced options',
    unit: 'Unit',
    selectOtherUnitData: 'Select another unit to use its data',
    events: 'Events',
    lowerChartLimit: 'Lower limit of the chart',
    upperChartLimit: 'Upper limit of the chart',
    bulletChartOrderInfo:
      'In case of equal values, the ordering will be considered by the legend, code or description of the TAG',
    loadingFile: 'Generating file ...',
    isRequired: 'is required',
    ganttNewDataTitle: 'abc',
    colorCode: 'Color code',
    colorTypeLabel: 'Type',
    initialValue: 'Initial value',
    finalValue: 'Final value',
    color: 'Color',
    goalColor: 'Color when reaching the goal',
    cancelEditConfirmation: 'Do you want to cancel editing?',
    addColor: 'Add color',
    limitsColor: 'Border color',
    current: 'Current',
    indicator: 'Indicator',
    calculation: 'Calculation',
    tagsListFromUnit: 'This list contains tags from Unity',
    logbookListFromUnit: 'This list contains annotation groups from the unit',
    valuesToDisplay: 'Values to display',
    aggregation: 'Aggregation',
    serieValues: 'Series values',
    compareWith: 'Compare with',
    none: 'none',
    graphType: 'Chart type',
    textFormat: 'Text Format',
    textSize: 'Text size',
    displaySize: 'Display size',
    limitSize: 'Limit size',
    colors: 'Colors',
    backgroundColor: 'Background color',
    showUpperLimit: 'Displays upper limit',
    showLowerLimit: 'Displays lower limit',
    showGoal: 'Displays goal',
    showLabelGoal: 'Display goal value',
    showValue: 'Displays value',
    order: 'Order',
    indicatorExcluded: 'Indicator has been deleted',
    indicators: 'Indicators',
    addIndicator: 'Add Indicator',
    indicatorForm: 'Indicator form',
    inOutLimits: 'On/off limits',
    inOutReference: 'On/off reference',
    out: 'Out',
    in: 'Inside',
    motiveGroup: 'Reasons group',
    yAxisValue: 'y Axis value',
    hoursStop: 'Downtime',
    stop: 'Downtime',
    available: 'Available',
    showAccumulated: 'Cumulative display',
    importing: 'Importing ...',
    widgetImported: 'Widget imported successfully!',
    errorImportingFile:
      'There was an error importing, please review the file and try again',
    widgetDeleted: 'The widget has been deleted',
    errorDeletingWidget: 'There was a problem trying to delete the widget',
    widgetPermission:
      'This widget is only visible to users allowed to manage the dashboard',
    addWidget: 'Add widget',
    importWidget: 'Import widget',
    tabForm: 'Tab form',
    allDays: 'All days',
    tabImported: 'Tab imported successfully!',
    tabDeleted: 'The tab was deleted',
    errorDeletingTab: 'There was a problem trying to delete the tab',
    addTab: 'Add tab',
    importTab: 'Import tab',
    exportTab: 'Export tab',
    editTab: 'Edit tab',
    deleteTab: 'Delete tab',
    exportPdf: 'Export to PDF',
    noneTabs: 'There are no tabs created in the dashboard',
    createTab: 'Create tab',
    addComponent: 'Add component',
    exportWidget: 'Export widget',
    editWidget: 'Edit widget',
    deleteWidget: 'Exclude widget',
    widgetForm: 'Widget form',
    columnDataCalc: 'Column with calculated data',
    tagDescriptionWidth: 'Reference column width',
    meanAndSum: 'Average and sum',
    logbookGroup: 'Notes group',
    filterByTitle: 'Filter by title',
    openDashboard: 'Open dashboard',
    indicatorType: 'Indicator type',
    joinEvents: 'Merge indicator data',
    dashboardPermission:
      'This card is only visible to users allowed to manage the dashboard',
    icon: 'Icon',
    dynamicIndicator: 'Dynamic indicator',
    dynamicIndicatorDescription:
      "The dynamic indicator is used to change the indicators according to it's value",
    emissionDate: 'Emission date',
    referenceDate: 'Reference date',
    showAvailable: 'Displays (In operation)',
    showLegendDescription: 'Show description in legend',
    showAllMotives: 'Show all motives in legend',
    specificationLimit: 'Specification limits',
    actionLimit: 'Action plan limits',
    spc: 'SPC',
    reference: 'Reference',
    groupByCalculation: 'Required to group by for calculation tag',
    dynamicPeriod: 'Dynamic period',
    externalAggregation: 'External aggregation',
    externalAggregationHelp:
      'When enabling, an aggregation selector will be displayed in the component and by default the grouping of the sequence indicator 1 or the component will be obeyed',
    showControlLimits: 'Show control limits (SPC)',
    controlLimits: 'Show control limits (SPC)',
    zeroColor: 'Color range - 0%',
    fiftyColor: 'Color range - 50%',
    oneHundreadColor: 'Color range - 100%',
    controlLimitsInfo:
      'Lower control limit: {lowerLimit} - upper control limit: {upperLimit}',
    specificationLimitsInfo:
      'Lower specification limit: {lowerLimit} - upper specification limit: {upperLimit}',
    uniqueGoalInfo: 'Goal: {goal}',
    lowerUpperGoalInfo: 'Lower goal: {lowerGoal} - upper goal: {upperGoal}',
    noneToCompare: 'There is no current band',
    colorType: {
      free: 'Free',
      specificationLimit: 'Specification limit',
      goal: 'Goal',
    },
    componentTypes: {
      labels: {
        chart: 'Graphic',
        pareto: 'Stop management',
        pizza: 'Pie chart',
        gauge: 'Gauge',
        solidGauge: 'Gradient gauge',
        grid: 'Grid',
        imageConditional: 'Conditional image',
        staticImage: 'Static image',
        text: 'Text',
        gav: 'Visual management',
        status: 'Tag status',
        gantt: 'Gantt',
        eventManager: 'Event management',
        report: 'Report',
        managementCard: 'Performance management',
        logbook: 'Notes',
        bulletChart: 'Bullet Chart',
        paretoChart: 'Pareto chart',
        treemap: 'Treemap',
        operatorChart: 'Operator analysis chart',
        deviationManagement: 'Deviation management',
      },
      descriptions: {
        chart:
          'Displays the time series data points in the form of vertical bars or lines. The user must select the range of the series and whether to display aggregated data or individual points in the series',
        pareto:
          'Displays the pareto chart of the reasons pointed out in the selected equipment downtime event. The user must select the amplitude of the reasons indicated and the way that the values of hours of downtime will be presented (percentage or absolute)',
        pizza:
          'Displays the pie chart of the reasons pointed out in the selected equipment downtime event. The user must select the amplitude of the reasons mentioned',
        gauge:
          "Displays the selected indicator in the gauge format, requiring the user to define the ideal working limits (green region). The system will calculate the attention zone '(yellow region) and the out-of-specification zone' (red region)",
        solidGauge:
          "Displays the selected indicator in the gauge format, with the component going through the different colors of its gradient according to the definition of 'biggest best' or 'lowest best' defined by the user in the indicator configuration",
        grid: 'Displays the values of the indicator within the range selected by the user in the table format, being possible to select to display the aggregated points in a selected period or their individual values',
        image:
          'Displays a conditional image inserted into the system by the user from the result of an equation defined in the indicator',
        staticImage:
          'It shows a static image inserted by the user. Used to show equipment images, logo or objects.',
        text: 'Displays the result of the selected indicator within the range defined by the user in the numeric value format, and it is also possible for the user to enter equations without having to define new indicators',
        gav: 'Displays the value of the selected indicator and compares it to the target value, displaying a positive or negative sign depending on the result of this combination',
        status:
          "Displays the value of the selected indicator and compares it to the value of the control limits, defined in the 'setting of the indicator, displaying a positive or negative sign depending on the result of this combination",
        gantt:
          'Displays the gantt chart, which is used to illustrate the progress of the different stages of the data presented',
        eventManager:
          'Displays the graph of the selected event, showing the frames generated within the selected period',
        report:
          'Displays the aggregated or calculated values of the selected indicators for the periods defined in the report settings',
        managementCard:
          'Displays the last indicator value and compares it to the limits defined in the indicator settings. In addition, it displays the percentages of time that each shift worked outside the range of limits',
        logbook:
          'Display notes made within the defined period for the selected notes group',
        bulletChart:
          'Bullet charts allow readers to quickly compare a single value with a target value. The target value is defined in the tags goals configuration',
        paretoChart:
          'The pareto chart is a column chart that orders the frequencies of occurrences, from highest to lowest, allowing prioritization of problems',
        treemap:
          'The treemap orders the frequencies of occurrences, from highest to lowest, allowing prioritization of problems',
        operatorChart:
          'The operators and tags chart displays data that relates tags to operators and their scheduled shifts. This graph aims to show the aggregation of tags for operators, depending on the work shift',
        deviationManagement:
          'Displays the deviations of the statistical process control (CEP) indicators within the range selected by the user in a table format, being possible to select to display the aggregated points in a selected period or their individual values',
      },
    },
  },
  accessControl: {
    register: 'Register',
    operation: 'Operation',
    routine: 'Routine',
    initialPage: 'Initial Page',
    users: {
      login: 'Login',
      name: 'Name',
      type: 'Type',
      adm: 'Administrator',
      active: 'Active',
      maxLengthExceeded: 'Maximum size exceeded',
      email: 'Email',
      insertValidEmail: 'Enter a valid email',
      accountType: 'Account Type',
      activeUser: 'Active user',
      unblockUser: 'Unblock user',
      pngJpg: 'You can only upload PNG / JPG files!',
      lessTwoMb: 'The image must be less than 2MB!',
      passwordExtra: 'Type a new password to change the current one',
      passwordCorrection: 'Enter a new password to unblock the user',
      upload: 'Upload',
      validatePassword: {
        minimumCaracters: 'Password must be longer than 8 characters',
        upperCase: 'Password must be at least one capital letter',
        lowerCase: 'Password must be at least one lowercase letter',
        number: 'The password must have at least one number',
        specialCaracter: 'Password must be at least one special character',
      },
    },
  },
  calendar: {
    productiveHour: 'Productive Hour',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
    period: 'Period',
    startTime: 'Start Hour',
    endTime: 'End Hour',
    weekDays: 'Week Days',
  },
  dateHour: {
    dateAndHourSuccess: 'Times and dates changed successfully',
    shiftStart: 'Start of shift',
    endShift: 'End of shift',
    unitDateAndHourConfiguration: 'Setting the time and date for the unit',
    startDay: 'Start time of day',
    selectHour: 'Select hour',
    startWeek: 'Beginning of the week',
    selectDay: 'Select the day',
    sunday: 'Sunday',
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    harvestPeriod: 'Harvest period',
    timezone: 'Standard timezone',
    selectTimezone: 'Select standard timezone',
    shifts: 'Shifts',
    calendar: 'Calendar',
    addShift: 'Add shift',
    previousDay: 'Previous day',
    noProduction: 'No production',
  },
  tags: {
    inactiveTag:
      'The tag is inactive due to registration, so it is not possible to select it',
    inactivateConfirmation:
      'When you inactivate this tag, it will not be possible to use it on the platform. Are you sure you want to deactivate?',
    divisionByZero: 'Division by zero',
    syntaxError: 'Syntax error',
    hasComments: 'The calculation has comments',
    missingParentheses: 'The calculation is missing parentheses',
    hasFcm: 'The calculation has "fcm." instead of "tag."',
    missingQuotes: 'The calculation is missing quotes',
    invalidFunction: 'Invalid function',
    missingTags:
      'The calculation has tags that do not exist in the plant: {tags}',
    missingPoints:
      'The calculation has tags that do not have points in the selected period: {tags}',
    validated: 'The calculation was validated, result: {result}',
    validCalculation: 'The calculation needs to be validated',
    testConversion: 'Test conversion',
    csvExample: 'Temp ABC',
    importTags: 'Import tags',
    downloadExample: 'Download example',
    advanced: 'Advanced',
    tagCodeDuplicated: 'Duplicate code in the import structure',
    tagLimit: 'Tag limit',
    limitExceeded: 'The {limit} tag limit was exceeded on {exceed} tag(s)',
    duplication: 'Duplication',
    dataInput: 'Data entry',
    inactive: 'Inactive',
    laboratory: 'Laboratory',
    sensor: 'Sensor',
    automation: 'Automation',
    conversion: 'Conversion',
    aggregationType: 'Aggregation type',
    calculation: 'Calculation',
    validateCalculation: 'Validate calculation',
    validate: 'Validate',
    format: 'Formatting',
    tagType: 'Tag Type',
    greaterBetter: 'Bigger better',
    smallerBetter: 'Smaller better',
    decimalPlaces: 'Decimal',
    tagFilter: 'Filter tag',
    consolidateHour: 'Consolidate per hour',
    consolidateHourHelp:
      'Consolidation per hour of the original tag according with aggregation type and storage of the consolidated value in tag "_H", it can be hidden or not.',
    consolidateDay: 'Consolidate per day',
    consolidateDayHelp:
      'Consolidation per day of the original tag according with aggregation type and storage of the consolidated value in tag "_D", it can be hidden or not.',
    consolidateShift: 'Consolidate per shift',
    consolidateShiftHelp:
      'Consolidation per shift of the original tag according with aggregation type and storage of the consolidated value in tag "_T", it can be hidden or not.',
    consolidateWeek: 'Consolidate per week',
    consolidateWeekHelp:
      'Consolidation per week of the original tag according with aggregation type and storage of the consolidated value in tag "_S", it can be hidden or not.',
    consolidateMonth: 'Consolidate per month',
    consolidateMonthHelp:
      'Consolidation per month of the original tag according with aggregation type and storage of the consolidated value in tag "_M", it can be hidden or not.',
    tagInterpolation: 'Interpolation tag',
    duplicate: 'Duplicate',
    calculationValidity: 'Validity of calculation',
    defaultValidity: 'Default validity',
    validity: 'Validity',
    addNewValidity: 'Add new validity',
    duplicatedValidityError:
      'It is not possible to insert two equal validity, this validity date already exists.',
    needToSaveTheTag:
      'It is necessary to save the tag for the changes to take effect',
    conversionInterval: 'Conversion execution interval',
    actionPlanLimitInfo:
      'Limit that, when filled in, is used by the “Generate Action Plan” tool of the Action Plan Management module to release the action plan generation button.',
    especificationLimitInfo:
      'Limit used for display in dashboard components such as graph, gauge, grid and performance management. When the action plan limit is not filled in, it is also used to release the action plan generation button.',
    tagDescription: {
      decimalPlaces: 'Number of decimal places',
      upperFilter: 'Upper filter',
      lowerFilter: 'Lower filter',
      lowerEspecificationLimit: 'Lower specification limit',
      upperEspecificationLimit: 'Upper specification limit',
      lowerActionPlanLimit: 'Lower limit of action plan',
      upperActionPlanLimit: 'Upper limit of action plan',
      tagType: 'Tag type (smallest best (<), largest best (>)),',
      aggregationType: 'Aggregation type (AVERAGE, SUM, DIFF, CALC)',
      calcFunction: 'Calculation function (if the aggregation type is "CALC")',
      dataInput: 'Data entry (sensor, manual, calculation)',
      lowerValidLimit: 'Lower valid limit',
      upperValidLimit: 'Upper valid limit',
      calculationInput:
        'Conversion calculation (if the data entry is "calculation")', // calculation is what save in db and what user needs to put in "dataEntry"
      consolidateHour: 'Consolidate by hour',
      consolidateDay: 'Consolidate by day',
      consolidateShift: 'Consolidate by shift',
      consolidateWeek: 'Consolidate by week',
      consolidateMonth: 'Consolidate by month',
      conversionInterval: 'Conversion interval (minimum: 1)',
      tagCode: 'Tag code',
      tagDescription: 'Tag description',
    },
    goal: {
      title: 'Goal',
      uniqueGoal: 'Single goal',
      lowerGoal: 'Bottom goal',
      upperGoal: 'Superior goal',
      proportional: 'Proportional',
      alreadyExists: 'There is already a goal for this tag in this period',
    },
    group: {
      validTags: 'Valid tags',
      activate: 'Activate group',
      unregisteredTags: 'Unregistered tags',
      tagsInGroup: 'Tags already existing in the group',
      type: 'Group Type',
      integration: 'Integration',
      manualCollection: 'Manual Collection',
      alarmManagement: 'Alarm Management',
      rootCause: 'Root Cause',
      alreadySelected: 'All selected tags are already included in this tab',
      noReferenceValue:
        'There are unsaved changes or tags with no inactive alarm value',
      noSelectedTags: 'There are no tags selected',
      availableRuleTags: 'Available tags for defining rules',
      alarmManagementExplanation:
        'An alarm will be recorded when the values ​​of the active tags belonging to the group are different from the defined reference values. Deactivated tags will not be considered',
      deleteGroupError:
        "It is not possible to delete the group as there are associated rules. You can deactivate the group by clicking 'edit'",
      activeGroup: 'Active group',
      report: 'Report',
      titleTags: 'Tags',
      disassociateTagError:
        'It is not possible to disassociate tags that already have alarms registered',
      availableTags: 'Available tags',
      searchTag: 'Search tag',
      selectedTags: 'Selected tags',
      titleUsers: 'Operators',
      availableUsers: 'Available operators',
      searchUser: 'Search operator',
      selectedUsers: 'Selected operators',
      rules: 'Rules',
      selectedTagNotInCalc: 'Selected tag not in calculation',
      tagsNotInGroup:
        'There are tags in the calculation that do not belong to this group: {tags}',
      unsupportedLogicalOperators:
        'There are unsupported logical operators in the calculation: {operators}',
      syntaxError: 'Syntax error',
      validatedCalculation: 'Validated calculation',
      validSyntax:
        "Possible validations (>, <, ==, !=). Ex: 'TAG_RULE' > ('TAG1' + 'TAG2') / 'TAG3'",
      needAllValidations: 'All rules need to be validated',
      validateExpression: 'Validate expression',
      tagsWithoutValue:
        'There are tags in the calculation without value, please fill in: {tags}, calculation: {calculation}',
      valueNotValid: 'Entered value is not valid, calculation: {calculation}',
      consecutivePoints: 'Consecutive points',
      calcTagIsSelected:
        'Cannot select the "No grouping" option for calculation type tags',
    },
  },
  integration: {
    log: 'Log',
    onlyErrors: 'Show only requests with error',
    rerunButton: 'Rerun the requests',
    executionDate: 'Execution date',
    executionTime: 'Execution time',
    payload: 'Payload',
    result: 'Result',
    triggerType: 'Trigger type',
    eventGroup: 'Event group',
    eventType: 'Event Type',
    defaultEvent: 'Predefined event',
    anyEvent: 'Any event',
    anyTag: 'Any tag',
    anyMatrix: 'Any matrix',
    invalidCronjob: 'Invalid Cronjob',
    invalidJson: 'Invalid JSON',
    stepOne: 'Step #1',
    step: 'Step',
    subStep: 'SubStep',
    integrationSuccessUpdate: 'Integration changed successfully!',
    default: 'Default',
    header: 'Header',
    back: 'Back',
    saveChanges: 'Save Changes',
    dataError:
      'There was a problem querying the integration data, please try again',
    deleteConfirmation: 'Are you sure you want to delete this integration?',
    excludeIntegration: 'Delete integration',
    editRequests: 'Edit requests',
    addRequests: 'Add requisitions',
    subRequests: 'Sub-requisitions',
    loop: 'Repetition',
    delay: 'Delay',
    get: 'GET',
    post: 'POST',
    put: 'PUT',
    eventTypeTitle: {
      tagsGroup: 'Tags Group',
      approvalArea: 'Approval area',
      anyFrame: 'Any event',
      runEvery: 'Run every',
      onOpenFrame: 'When opening event',
      onCloseFrame: 'When closing event',
      onCreateEffect: 'When creating effect (root cause)',
      onCompleteEffect: 'When completing effect (root cause)',
      onInsertAction: 'When inserting action (root cause)',
      onCompleteAction: 'Upon completion of action (root cause)',
      onDelayAction: 'When delaying action (root cause)',
      onApproveStatus: 'When changing the approval status (root cause)',
      onSendToApproval: 'When submitting for approval (root cause)',
      onOpenProductionOrder: 'When opening production order',
      onCloseProductionOrder: 'When closing production order',
      onReopenProductionOrder: 'When reopening production order',
      onOperationalSecutitySendToApprove:
        'When submitting for approval (operational security)',
      onOperationalSecutityApproveStatus:
        'When changing the approval status (operational security)',
      onOperationalSecutityExtendSolicitation:
        'When Requesting impairment expansion (operational security)',
      onOperationalSecutityExtendSolicitationApprovalStatus:
        'When Requesting impairment expansion change status (operational security)',
      onOperationalSecutityExecution: 'When running SCI (operational security)',
      onOperationalSecutityRestoration:
        'When resetting the instrument (operational security)',
      onOperationalSecutityRestorationLate:
        'Overdue reinstatement (operational security)',
      onOperationalSecutityRoutineTestExecution:
        'When performing routine testing (operational security)',
      onOperationalSecutityRoutineTestRepeat:
        'Test repeat (operational security)',
      onOperationalSecutityRoutineTestImpairment:
        'Need for impairment (operational security)',
      onOperationalSecutityImpairmentWithoutApprovation:
        'Instrument deactivated without approval (operational security)',
      onOperationalSecutityManualOperating:
        'Operating in manual (operational security)',
      onOperationalSecutityManualInterlock:
        'Manual interlock (operational security)',
      onOperationalSecutityRoutineTestLate:
        'Routine test delay (operational security)',
      onManagementOfChangesSendToApprove:
        'When submitting for approval (management of changes)',
      onManagementOfChangesApproveStatus:
        'When changing the approval status (management of changes)',
      onManagementOfChangesExecution: 'When running (management of changes)',
      onManagementOfChangesRestoration:
        'When resetting the instrument (managementOfChanges)',
      onManagementOfChangesRestorationLate:
        'Overdue reinstatement (managementOfChanges)',
      onIntegrationFailure: 'Integration failure (integration)',
      onCreateUser: 'On create user',
      onUpdateUserPassword: 'On update user password',
      onClosePRP: 'At the end of PRP (integrated maintenance)',
    },
    title: {
      cronjob: 'Cronjob',
      rootCauseCreateEffect: 'Root cause - create effect',
      rootCauseEndEffect: 'Root cause - end effect',
      rootCauseInsertAction: 'Root cause - insert action',
      rootCauseConcludeAction: 'Root cause - complete action',
      rootCauseDelayAction: 'Root cause - delay action',
      rootCauseSendToApprove: 'Root cause - send to approval',
      rootCauseApprovalStatus: 'Root cause - change approval status',
      startEvent: 'Start event',
      endEvent: 'End event',
      openProductionOrder: 'Open production order',
      closeProductionOrder: 'Close production order',
      reopenProductionOrder: 'Reopen production order',
      manualCollect: 'Data entry',
      manualCollectCsv: 'Inserção de dados by CSV',
      manualCollectEdit: 'Data edit',
      manualCollectDelete: 'Data delete',
      operationalSecutitySendToApprove: 'SCI - Send to approval',
      operationalSecutityApprovalStatus: 'SCI - Change approval status',
      operationalSecutityExtendSolicitation:
        'SCI - Request for expansion of impairment',
      operationalSecutityExtendSolicitationApprovalStatus:
        'SCI - Change request for expansion of impairment status',
      operationalSecutityExecution: 'SCI - execution',
      operationalSecutityRestoration: 'SCI - Restoration',
      operationalSecutityRestorationLate: 'SCI - Overdue reinstatement',
      operationalSecutityRoutineTestExecution: 'SCI - Routine test',
      operationalSecutityRoutineTestRepeat: 'SCI - Test repeat',
      operationalSecutityRoutineTestImpairment: 'SCI - Need for impairment ',
      operationalSecutityImpairmentWithoutApprovation:
        'SCI - Instrument deactivated without approval',
      operationalSecutityManualOperating: 'SCI - Operating in manual',
      operationalSecutityManualInterlock: 'SCI - Manual interlock',
      operationalSecutityRoutineTestLate: 'SCI - Routine test delay',
      managementOfChangesSendToApprove: 'MOC - Send to approval',
      managementOfChangesApprovalStatus: 'MOC - Change approval status',
      managementOfChangesExecution: 'MOC - Execution',
      managementOfChangesRestoration: 'MOC - Restoration',
      managementOfChangesRestorationLate: 'MOC - Overdue reinstatement',
      integration: 'Integration',
      integrationFailure: 'Integration - Integration failure',
      createUser: 'User creation',
      updatePassword: 'User password update',
    },
    variables: {
      effectOne: 'T effect # 1',
      effectOneCommentary: 'This effect was completed on 2010-02-25',
      actionOne: 'Action A # 1',
      actionOneWhy: 'Because of action A # 1',
      actionOneHow: 'As of Action A # 1',
      actionOneWhat: 'What of action A # 1',
      actionOneCommentary: 'Comment on action A # 1',
      eventTitle: 'Event title',
      eventCode: 'Event ID',
      productionOrderCode: 'code # 123',
      productionOrderStatus: 'status',
      productionOrderExternalCode: 'external-code # 123',
      productionOrderItemCode: 'item-code',
      productionOrderExternalItemCode: 'external-code-item',
      tagCode: 'TAG_CODE',
      sciInstrumentDesc: 'Instrument description',
      mocDesc: 'Name of change',
      genericText:
        'Lorem ipsum dolor sit amet. Et eligendi pariatur sed deserunt quae eum nostrum galisum et eveniet nulla aut omnis voluptas.',
      integrationTitle: 'Integration title',
      eventType: 'Event type',
      step: 'Step #1',
      endpoint: 'http://localhost:9000/endpoint',
      payload: "{'name': 'joao', senha: 'senha123'}",
      response: "{'error':{'code': 400}}",
    },
    parameters: {
      errorCreateNew: 'Error creating a new integration parameter',
    },
    laboratory: {
      integrationType: 'Integration type',
      pimsLab: 'Lab entry',
      pimsWrite: 'Report closing - writing',
      pimsRead: 'Report closing - reading',
      pimsMassRead: 'Mass and energy balance - reading',
      pimsMassWrite: 'Mass and energy balance - writing',
      pimsMassAccomplished: 'Mass and energy balance - accomplished',
      originVariable: 'Source variable',
      destinyVariable: 'Target variable',
      hourVariable: 'Hour variable',
      ruleCollect: 'Acquisition rule',
      ruleZero: 'Rule 0',
      ruleZeroDescription: 'Time in the top line of the PIMS screen',
      ruleOne: 'Rule 1',
      ruleOneDescription:
        'Types the time in another PIMS variable without the subfont (define this variable in the Tag)',
      ruleTwo: 'Rule 2',
      ruleTwoDescription: '3 typings per day (1 per shift)',
      ruleThree: 'Rule 3',
      ruleThreeDescription: '6 typings per day (2 per shift)',
      ruleFour: 'Rule 4',
      ruleFourDescription:
        'First hour of the day incrementing every 1 hour (24 typings in the day)',
      ruleFive: 'Rule 5',
      ruleFiveDescription:
        'Returns the average calculated on the last PIMS column',
      ruleSix: 'Rule 6',
      ruleSixDescription:
        'Converts a text typed in PIMS to a numerical value from the record made in global variables',
      typeNotFound: 'Type not found',
      type: 'Type',
    },
  },
  integrationSystem: {
    production: 'Production and Process',
    enableApprove: 'Enable approve',
    enableInsertAppointment: 'Enable insert appointment',
    tagsGroup: 'Tag Group',
    showDayAggregation: 'Display aggregation by day',
    showShiftAggregation: 'Display aggregation by shift',
  },
  manualCollect: {
    appointmentsQuantity: 'Number of Entries',
    startHour: 'Start time',
    type: 'Type',
    createNewItem: 'Create a new item',
    tagGroup: 'Tag group',
    onlyShowManualInput: 'Only manual entry tags will be displayed',
    notShowButton: 'Add new hours button will not be displayed',
    showEndTime: 'Show end time',
    alreadyAssociated: 'Tag group already associated with a collection',
    group: {
      permissions: 'Permissions',
      userPermissions: 'Permission for users',
    },
  },
  oee: {
    title: 'OEE',
    available: 'Availability',
    quality: 'Quality',
    performance: 'Performance',
    dashboard: 'Dashboard',
    dashboardNotFound: 'Dashboard not found',
    dailyValues: 'Daily Values',
    resume: 'Resume',
    back: 'Back',
    next: 'Next',
    instructions: 'Instructions',
    availableFirstParagraph:
      'If the downtime is already calculated by the automation, select the tag to be collected. Otherwise, select or register a new event',
    availableSecondParagraph:
      'Note: If a new event is created, it is necessary to configure or point out the reason for the stop manually',
    availableTag: 'Availability tag',
    tagDescription: 'Tag description',
    tagCode: 'Code tag',
    availableOptionOne: 'Option 1 - registered tag',
    availableOptionTwo: 'Option 2 - new tag',
    availableOptionThree: 'Option 3 - event',
    stopsEvent: 'Downtime event',
    createNewFrame: 'Create new event',
    dashboardParagraph:
      'Define how the OEE will be displayed within the dashboard in the menu and the tab title. It is also possible to add an icon to better identify them',
    viewTitle: 'Dashboard title',
    tabTitle: 'Tab title',
    descriptionParagraph:
      "Enter a description title for the OEE to be created. This title will be displayed in the table of OEE's created",
    oeeTitle: 'Title to the OEE',
    oeeStepParagraph:
      "Enter a description title for the OEE to be created. This title will be displayed in the table of OEE's created",
    tagCalculation: 'Tag calculation',
    performanceParagraph:
      'Point out the performance of the equipment, defining the quantity of parts produced per cycle and define if the cycle time will be obtained through a tag or if it will be a fixed cycle time',
    tagQuality: 'Quality tag',
    tagPerformance: 'Performance tag',
    ciclePieces: 'Parts per cycle',
    cicleTime: 'Cycle time - tag',
    performanceOptionThree: 'Option 3 - fixed time',
    fixedCicleTime: 'Cycle time - fixed',
    inSeconds: 'In seconds',
    oeeAvailable: 'OEE - Availability',
    oeePerformance: 'OEE - Performance',
    oeeQuality: 'OEE - Quality',
    oeeGoodProduction: 'OEE - Good production',
    oeeBadProduction: 'OEE - Poor production',
    oeeTotalProduction: 'OEE - Total production',
    qualityParagraph:
      'Select the tags that correspond to each indicator. For fields that are left blank, the system will fill in an automatically generated tag. Select the tag that should be calculated by the system, the ones that are not selected will be considered as values to be collected',
    goodProduction: 'Good production',
    calculation: 'Calculation',
    manualCollectScreen: 'Manual collection screen',
    createNewItem: 'Create a new item',
    badProduction: 'Defective production',
    totalProduction: 'Total production',
    resumeFirstParagraph:
      'Tag information that will represent the quality of production on the dashboard:',
    resumeSecondParagraph:
      "Tag information that will represent the equipment's performance on the dashboard:",
    resumeThirdParagraph:
      'Tag information that will represent the availability of the equipment on the dashboard:',
    resumeFourthParagraph:
      'Information of the tag that will represent the result of the production, presenting the product of the multiplication of the three tags previously presented',
    resumeFifthParagraph:
      'A new dashboard will be created in the “Dashboard” menu, where it will be possible to view the result of the calculations made by the system and presented through a gauge type chart. The resulting values will be presented through the tags described in this summary',
    type: 'Type',
    selected: 'Selected',
    report: {
      externalCloseStatus: 'External close status',
      internalCloseStatus: 'Internal close status',
    },
  },
  token: {
    title: 'Token',
    successCreate: 'Token successfully created',
    showOnce: 'This token will only be displayed once, store in a safe place',
  },
  alarmManagement: {
    begin: 'Alarm start',
    group: 'Alarm area',
    duration: 'Alarm duration',
    report: {
      selectGroup: 'Select the tag groups to generate the chart',
      totalAlarmsByPeriod: 'Total alarms in the period',
      numberOfAlarmsByGroup: 'Number of alarms per group',
      mostRecurringAlarms: 'Ten most recurring alarms',
      listOfAlarmsByPeriod: 'Alarms list',
    },
  },
  integratedMaintenance: {
    annualWeek:
      '*Weeks are based on the weeks of the year and not the start of the week setting on the platform',
    lastAnnualWeek:
      '**S.O. that exceed the 52nd week are counted as belonging to the 52nd week',
    weekCalendarProgrammed: 'Scheduled',
    weekCalendarAccomplished: 'Accomplished',
    weekCalendarPercentage: 'Percentage',
    weekCalendar: 'Weekly calendar - preventive O.S.',
    plan: 'Plan',
    annualMap: 'Annual map of preventive O.S.',
    dateConfigError:
      'There are no shifts and times configured, please configure so that the report can be generated',
    materialsLoading:
      'Searching for materials may take a while, please wait...',
    materialsError:
      'No materials with this code were found, please try again...',
    typeMaterial: 'Enter the code or description of the material to search',
    serviceOrders: 'Service orders',
    from: 'From',
    cantEditPrp: 'Cannot edit PRP type S.O.',
    filterPerformers: 'Filter performers',
    filterBacklog: 'Filter backlog',
    cannotMovePrp: 'Cannot move PRP type S.O. to backlog',
    programPerformers: 'Program performers',
    performersHelp: 'Need to select {quantityResources} performers',
    differentUserGroup:
      'It was not possible to assign O.S. to this user, as it does not belong to the resource group of this route',
    performers: 'Performers',
    backlog: 'Backlog',
    programming: 'Programming',
    accomplished: 'Accomplished',
    programmed: 'Programmed',
    weekAndMonth:
      'For weeks, 7 days will be considered, and for months, 30 days',
    day: 'day(s)',
    week: 'week(s)',
    month: 'month(s)',
    year: 'year(s)',
    osGenerated: 'S.O. generated:',
    serviceOrderType: 'Service order type',
    observationNeeded: 'In case of disapproval, observation is mandatory',
    approveReprove: 'Are you sure you want to approve/repprove this S.O.?',
    initialStatus: 'Initial',
    inProgressStatus: 'In progress',
    waitingApprovalStatus: 'Waiting approval',
    finishedStatus: 'Finished',
    approvedStatus: 'Approved',
    reprovedStatus: 'Reproved',
    verification: 'Verification',
    blockServiceOrder: 'Block S.O.',
    initialServiceOrderStatus: 'Initial S.O. status',
    title: 'Integrated Maintenance',
    quantitativeVerification: 'Quantitative verification',
    qualitativeVerification: 'Qualitative verification',
    lubricationGrease: 'Lubrication/grease - quantitative',
    lubricationExecution: 'Lubrification/grease - execution',
    procedureExecution: 'Procedure execution',
    materialType: 'Material type',
    piece: 'Piece',
    lubricant: 'Lubricant',
    externalService: 'External service',
    lastPurchasePrice: 'Unit cost',
    quantityStock: 'Quantity stock',
    quantityReserved: 'Reserved quantity',
    quantityReservedService: 'Service reserved quantity',
    price: 'Price',
    serviceType: 'Service type',
    preventiveModality: 'Preventive modality',
    routineItems: 'Routine items',
    planning: 'Planning',
    schedule: 'Schedule',
    attachments: 'Attachments',
    resourceQuantity: 'Resource quantity',
    previousDays: 'Nº of days to anticipate the generation of O.S.',
    differentResources:
      'Number of resources different from the number of selected performers',
    elementTag: 'Element / Tag',
    service: 'Service',
    setFrequencyOnRoute: 'Set frequency and expected time on the route',
    frequency: 'Frequency',
    expectedTime: 'Expected time',
    expectedTimeMin: 'Expected time (min)',
    element: 'Element',
    elements: 'Elements',
    material: 'Material',
    maxLimit: 'Maximum limit',
    minLimit: 'Minimum limit',
    okNotOk: 'Ok/not ok',
    executedNotExecuted: 'Procedure performed/procedure not performed',
    prpCreated: 'PRP created with success!',
    selectOperator: 'Select responsible operator',
    selectShift: 'Select shift',
    selectOrder: 'Select order',
    createPrp: 'Create PRP',
    onClosePRP: 'At the end of PRP',
    seeCompleteOs: 'See complete S.O.',
    executed: 'Executed',
    notExecuted: 'Not executed',
    ok: 'Ok',
    notOk: 'Not ok',
    to: 'to',
    os: 'S.O.',
    requestDate: 'Request date',
    conclusionDate: 'Conclusion date',
    actualStartDate: 'Actual start date',
    actualEndDate: 'Actual end data',
    status: 'Status',
    verificationStatus: 'Verification status',
    noOsToShow: 'No S.O. to show',
    prp: 'Preventive and predictive',
    execution: 'Execution',
    obtained: 'Obtained',
    osByPrp: 'S.O. by PRP',
    mcu: 'Urgent corrective maintenance',
    mcp: 'Scheduled corrective maintenance',
    nm: 'Maintenance note',
    prpUc: 'PRP',
    mcuUc: 'UCM',
    mcpUc: 'UCP',
    planned: 'Planned',
    performed: 'Performed',
    hoursUsed: 'Hours used',
    inspectionRoutesHoursUsed: 'Inspection routes x hours used',
    plannedExecuted: 'Planned x executed',
    requester: 'Requester',
    responsable: 'Responsable',
    route: 'Route',
    responsableOperator: 'Responsable',
    approvedBy: 'Approved by',
    disapprovedBy: 'Disapproved by',
    approvedAt: 'Approved at',
    disapprovedAt: 'Disapproved at',
    approved: 'Approved',
    disapproved: 'Disapproved',
    maintenanceActivity: 'Maintenance activity',
    priority: 'Priority',
    searchOperator: 'Search operator',
    serviceOrder: 'Service Order',
    osType: 'S.O. Type',
    emission: 'Emission',
    criticality: 'Criticality',
    materialsUsed: 'Materials used',
    materialsForeseen: 'Materials foreseen',
    materialForeseen: 'Material foreseen',
    quantityUsed: 'Quantity used',
    quantityForeseen: 'Quantity foreseen',
    operator: 'Operator',
    urgent: 'Urgent',
    moderate: 'Moderate',
    itemsRoute: 'Items route',
    obtainedInInspection: 'Obtained in inspection',
    stopped: 'Stopped',
    stoppedEquipment: 'Stopped equipment',
    observation: 'Note',
    osItems: 'S.O. Services',
    executionDate: 'Execution date',
    performer: 'Performer',
    serviceOrderFinished: 'Service order finished',
    serviceOrderCanceled: 'Service order canceled',
    serviceOrderOpen: 'Service order open',
    wasFinished: 'This service order was finished',
    wasCanceled: 'This service order was canceled',
    clickToReopen: 'Click to re-open',
    attachmentSuccess: 'Attachment with succes',
    attachmentDeleteError:
      'Error when trying to delete service order attachment',
    finishServiceOrder: 'Finish service order',
    cancelServiceOrder: 'Cancel service order',
    clickToAddAttachment: 'Click to add attachment',
    situation: 'Situation',
    cost: 'Cost',
    serviceCost: 'Service cost',
    generateErpRequisition: 'Generate ERP requisition',
    typeCurrentStatus: 'Type current status',
    typeDetails: 'Type details',
    generateOs: 'Generate S.O.',
    sendApproval: 'Send for approval',
    generateNote: 'Generate note',
    startTime: 'Start time',
    endTime: 'End time',
    hoursPlanned: 'Hours planned',
    plannedStartTime: 'Planned start time',
    realStartTime: 'Real start time',
    plannedEndTime: 'Planned end time',
    realEndTime: 'Real end time',
    osCreationDate: 'S.O. creation date',
    serviceCreationDate: 'Service creation date',
    addService: 'Add service',
    labor: 'Labor',
    center: 'Center',
    deposit: 'Deposit',
    reservationNumber: 'Nº reservation',
    attendanceNumber: 'Nº attendance',
    estimatedCost: 'Estimated cost',
    realCost: 'Real cost',
    lastAppointment: 'Last appointment',
    serviceDescription: 'Service description',
    hoursAppointed: 'Appointed hours',
    appointLabor: 'Appoint labor',
    lastAppointmentInformed: 'Last appointment alrealdy informed',
    isActive: 'Active',
    expectedQuantity: 'Expected quantity',
    amountUsed: 'Amount used',
    serviceCode: 'Service code',
    resourceGroup: 'Resource group',
    numberOfOperators: 'Number of operators',
    realStartAndEnd: 'Actual start/ End date',
    elementAndEquipment: 'Element / Equipment',
    futureHoursNotAllowed: 'It is not allowed to enter future hours',
    betweenDaysNotAllowed:
      'It is not allowed to appoint between different days',
    betweenServiceOrdersNotAllowed:
      'It is not allowed to appoint between different services, S.O.: {serviceOrder}, service: {service}',
    betweenServiceOrdersNotAllowedWithoutService:
      'It is not allowed to appoint between different services, S.O.: {serviceOrder}',
    startTimeEqualEndTime:
      'It is not allowed to note the start time equal to the end time',
    maxTimeUninterruptedNotAllowed:
      'No entries greater than that are allowed {time} uninterrupted',
    maxTimeDayNotAllowed:
      'No entries greater than that are allowed {time} daily',
    maxTimeDayNotAllowedServiceOrder:
      'No entries greater than that are allowed {time} daily, S.O.: {serviceOrder}, service: {service}',
    maxTimeDayNotAllowedServiceOrderWithoutService:
      'No entries greater than that are allowed {time} daily, S.O.: {serviceOrder}',
    minBreakTimeDayNotAllowed:
      'No entries smaller than that are allowed {time} between days',
    historic: 'Equipment movement',
    sourceElement: 'Source element',
    destinationElement: 'Destination element',
    movementDate: 'Movement date',
    moveEquipment: 'Move equipment',
    substituteEquipment: 'Substitute equipment',
    associatedEquipment:
      'This equipment has preventive maintenance services associated with it, inform replacement equipment',
    sequenceAlreadyExists: 'Sequence already exists',
    sourceServiceOrder: 'Source S.O. code',
    standardMap: 'Standard map',
    satteliteMap: 'Sattelite map',
    rules: {
      maxTimeUninterrupted: 'Maximum time uninterrupted',
      maxTimeDay: 'Maximum daily time',
      minBreakTimeDay: 'Minimum break between days',
      appointFutureHours: 'Check appointment of future hours',
      appointBetweenDays: 'Check appointment between different days',
      appointBetweenServiceOrders:
        'Check appointment between different services',
      automaticRegistrationApp: 'Automatic labor registration via application',
    },
    integrationStatus: {
      label: 'Integration status',
      waiting: 'Waiting',
      success: 'Sucess',
      error: 'Error',
    },
    report: {
      numberOfOrdersPerDay: 'Number or orders progammed X accomplished',
      numberOfOrdersPerActivityType: 'Number of orders X order type',
      hourPerElementPerAcitivityType: 'Time allocation X resource group',
      numberOfOrdersPerExecutionStatus: 'Number of orders X execution status',
      numberPerElementPerStatusByDay: 'Status X period X resource group',
      percentOfOrdersPerUserGroups: 'Percentage of orders X resource group',
      numberOfOrdersPerUserGroups: 'Number of orders X resource group',
      costForeseenPerOsType: 'Foreseen labor X foressen materials X order type',
      costForeseenPerElement: 'Foreseen labor X foressen materials X element',
      costPerOsType: 'Utilized labor X used materials X order type',
      costPerElement: 'Utilized labor X used materials X element',
      numberOfOrderPerElement: 'Number of orders X element',
      numberOfOrdersPerElementPerActivityType:
        'Number of orders X order type X element',
      selectReport: 'Select a report to generate the chart',
      numberOfHours: 'Number of hours',
      numberOfOrders: 'Number of orders',
      withoutStatus: 'Without status',
      laborForeseen: 'Foreseen labor',
      materialsForeseen: 'Foreseen materials',
      laborUsed: 'Used labor',
      materialsUsed: 'Used materials',
      monday: 'Monday',
      tuesday: 'Tuesday',
      wednesday: 'Wednesday',
      thursday: 'Thursday',
      friday: 'Friday',
      saturday: 'Saturday',
      sunday: 'Sunday',
      january: 'January',
      february: 'February',
      march: 'March',
      april: 'April',
      may: 'May',
      june: 'June',
      july: 'July',
      august: 'August',
      september: 'September',
      october: 'October',
      november: 'November',
      december: 'December',
      programmedAccomplished:
        'Programmed: {total} - Accomplished: {completed} - Percentage: {percentage}%',
      total: 'Total: {total}',
      programmed: 'Total',
      accomplished: 'Accomplished',
    },
  },
  pdfTemplate: {
    headerImage: 'Header image',
    footerImage: 'Footer image',
    templates: 'Templates',
  },
  dataExport: {
    horizontalTag: 'Horizontal - Tag',
    horizontalDate: 'Horizontal - Date',
  },
  barcodeLabel: {
    content: 'Code Content',
    contentHelp: 'Use the reader to set a predefined content',
    module: 'Module',
    QRCodeDynamic: 'QR Code Dynamic',
    addStep: 'Add step',
    payload_id: 'Item',
    payload_value: 'Value',
  },
}

export default localeValues
