import { Menu } from 'antd'
import styled from 'styled-components'

export const StyledMenu = styled(Menu)`
  &.ant-menu-vertical {
    border-right: 0;
  }
  &.ant-menu {
    margin-top: 10px;
  }

  .ant-menu-submenu-title {
    display: flex;
    align-items: center;
  }
  .ant-menu-item {
    display: flex;
    align-items: center;
  }
`

export const SubMenuTitle = styled.span`
  font-size: 13px;
  transition: color 0.3s;
`

export const NavLink = styled.span`
  text-decoration: none;
  font-size: 13px;
  transition: color 0.3s;
`
