import { Select } from 'antd'
import styled from 'styled-components'

export const Container = styled.div`
  margin-left: 10px;
`

export const StyledSelect = styled(Select)`
  .ant-select-selection {
    border: none !important;
  }
`
