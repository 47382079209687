/* eslint-disable no-undef */
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import accessControl from './accessControl/reducers'
import auth from './auth/reducers'
import contract from './contract/reducers'
import dateConfig from './dateConfig/reducers'
import frames from './frames/reducers'
import locale from './locale/reducers'
import siderMenu from './siderMenu/reducers'
import tags from './tags/reducers'
import theme from './theme/reducers'
import units from './units/reducers'
import unitSelected from './unitSelected/reducers'
import user from './user/reducers'
import whiteLabel from './whiteLabel/reducers'

const themePersistConfig = {
  key: 'intelup@theme',
  storage,
}

const tagsPersistConfig = {
  key: 'intelup@tags',
  storage: globalThis.indexedDB // this is for test environment
    ? createIdbStorage({ name: 'intelup', storeName: 'intelup' })
    : storage,
  serialize: false,
  deserialize: false,
}

const framesPersistConfig = {
  key: 'intelup@frames',
  storage: globalThis.indexedDB // this is for test environment
    ? createIdbStorage({ name: 'intelup', storeName: 'intelup' })
    : storage,
  serialize: false,
  deserialize: false,
}

const unitsPersistConfig = {
  key: 'intelup@units',
  storage,
}

export default combineReducers({
  locale,
  auth,
  user,
  units: persistReducer(unitsPersistConfig, units),
  unitSelected,
  accessControl,
  siderMenu,
  contract,
  whiteLabel,
  theme: persistReducer(themePersistConfig, theme),
  tags: persistReducer(tagsPersistConfig, tags),
  frames: persistReducer(framesPersistConfig, frames),
  dateConfig,
})
