import React from 'react'
import { useSelector } from 'react-redux'

import { Capacitor } from '@capacitor/core'
import PropTypes from 'prop-types'

import DesktopView from './Desktop.view'
import MobileView from './Mobile.view'

function LayoutBase({ children }) {
  const whiteLabel = useSelector(state => state.whiteLabel)
  return Capacitor.isNativePlatform() || window._env_?.CAPACITOR_DEBUG ? (
    <MobileView whiteLabel={whiteLabel}>{children}</MobileView>
  ) : (
    <DesktopView whiteLabel={whiteLabel}>{children}</DesktopView>
  )
}

LayoutBase.propTypes = {
  children: PropTypes.element.isRequired,
}

export default LayoutBase
