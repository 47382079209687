import produce from 'immer'

const initialState = {
  token: null,
  signed: false,
  loading: false,
  error: null,
  domain: null,
  attempt: null,
  userDataPermission: null,
  forcePasswordChange: null,
}

export default (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const newDraft = draft

    switch (type) {
      case '@auth/SIGN_IN_REQUEST': {
        newDraft.error = null
        newDraft.loading = true
        break
      }
      case '@auth/SIGN_IN_REQUEST_ADFS': {
        newDraft.error = null
        newDraft.loading = true
        break
      }
      case '@auth/SIGN_IN_SUCCESS': {
        newDraft.token = payload.token
        newDraft.signed = true
        newDraft.loading = false
        newDraft.userDataPermission = payload.userDataPermission
        newDraft.forcePasswordChange = payload.forcePasswordChange
        break
      }
      case '@auth/SIGN_IN_FAILURE': {
        newDraft.loading = false
        newDraft.error = payload.error
        newDraft.attempt = payload.attempt
        break
      }
      case '@auth/CLEAN_ERROR': {
        newDraft.error = null
        newDraft.loading = false
        break
      }
      case '@auth/SIGN_OUT': {
        break
      }
      case '@auth/SIGN_OUT_SUCCESS': {
        newDraft.error = null
        newDraft.token = null
        newDraft.signed = false
        newDraft.domain = payload.domain
        break
      }
      default:
    }
  })
