import React, { useState } from 'react'
import { useIntl } from 'react-intl'

import { SettingOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import PropTypes from 'prop-types'

const ButtonConfig = ({ menu, onChangeMenu }) => {
  const [visible, setVisible] = useState(false)
  const intl = useIntl()
  return (
    <Tooltip
      visible={visible}
      placement="right"
      title={
        menu === 'config'
          ? intl.formatMessage({
              id: 'components.layout.menu.buttonConfig.exitConfiguration',
            })
          : intl.formatMessage({
              id: 'components.layout.menu.buttonConfig.configuration',
            })
      }
    >
      <Button
        onMouseEnter={() => setVisible(true)}
        onMouseLeave={() => setVisible(false)}
        type="primary"
        shape="circle"
        icon={menu === 'config' ? <ArrowLeftOutlined /> : <SettingOutlined />}
        onClick={() => {
          onChangeMenu()
          setVisible(false)
        }}
      />
    </Tooltip>
  )
}

ButtonConfig.propTypes = {
  menu: PropTypes.string.isRequired,
  onChangeMenu: PropTypes.func.isRequired,
}

export default ButtonConfig
