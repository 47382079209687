import { useMemo } from 'react'

import useViewport from './useViewport'

function useIsMobileWidth(breakpoint = 768) {
  const { width } = useViewport()

  const isMobile = useMemo(() => width <= breakpoint, [breakpoint, width])

  return [isMobile]
}

export default useIsMobileWidth
