export function checkWhiteLabelRequest() {
  return {
    type: '@whiteLabel/REQUEST',
  }
}

export function checkWhiteLabelSuccess(data) {
  return {
    type: '@whiteLabel/SUCCESS',
    payload: { data },
  }
}

export function checkWhiteLabelFailure(error) {
  return {
    type: '@whiteLabel/FAILURE',
    payload: { error },
  }
}
