import { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'

import axiosClient from 'services/api'

function useLogger() {
  const userProfile = useSelector(state => state.user.profile)
  const intl = useIntl()
  const [log, setLog] = useState('')

  function setValue(action, type, text = null) {
    try {
      const { id_usuario } = userProfile

      axiosClient.post('LOG/entities/DATA', {
        log_acao: action,
        log_texto: text,
        log_tipo: type,
        id_usuario,
      })

      setLog(intl.formatMessage({ id: 'global.success.messages.save' }))
    } catch (err) {
      console.log(err, log)
      setLog(intl.formatMessage({ id: 'global.error.messages.defaultError' }))
    }
  }

  function setValues(data) {
    try {
      const { id_usuario } = userProfile

      axiosClient.post('LOG/entities/DATA/ALL', {
        logs: data.map(item => ({
          ...item,
          id_usuario,
        })),
      })

      setLog(intl.formatMessage({ id: 'global.success.messages.save' }))
    } catch (err) {
      console.log(err, log)
      setLog(intl.formatMessage({ id: 'global.error.messages.defaultError' }))
    }
  }

  return [setValue, setValues]
}

export default useLogger
