import React, { useRef, useEffect } from 'react'

import { List } from 'antd'
import moment from 'moment'
import PropTypes from 'prop-types'

import FromNow from 'components/FromNow'

import { Scroll, StyledList, StyledListItem } from '../styles'

const Content = ({ items, currentAlert }) => {
  const scrollRef = useRef(null)

  const handleScroll = e => {
    e.preventDefault()
  }

  useEffect(() => {
    scrollRef.current.addEventListener('wheel', handleScroll)
    scrollRef.current.addEventListener('mousewheel', handleScroll)
    scrollRef.current.addEventListener('DOMMouseScroll', handleScroll)

    return () => {
      scrollRef.current.removeEventListener('wheel', handleScroll)
      scrollRef.current.removeEventListener('mousewheel', handleScroll)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      scrollRef.current.removeEventListener('DOMMouseScroll', handleScroll)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Scroll>
      <div ref={scrollRef}>
        <StyledList
          itemLayout="horizontal"
          dataSource={items}
          renderItem={item => (
            <StyledListItem
              read={item.dt_visualizado}
              onClick={() => {
                currentAlert(item)
              }}
            >
              <div>
                <List.Item.Meta
                  key={item.id_mvu}
                  title={item.mov_titulo}
                  description={item.mov_msg_alerta}
                />
              </div>
              <div style={{ fontSize: '12px', textAlign: 'end' }}>
                <span>
                  <FromNow
                    date={moment(item.mov_dt_alerta)}
                    formatDay="dddd HH:mm:ss "
                    formatMonth="DD MMMM HH:mm:ss"
                    formatYear="DD MMMM YYYY HH:mm:ss"
                  />
                </span>
              </div>
            </StyledListItem>
          )}
        />
      </div>
    </Scroll>
  )
}

Content.propTypes = {
  currentAlert: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
}

export default Content
