import { persistReducer } from 'redux-persist'
import createCompressor from 'redux-persist-transform-compress'
import storage from 'redux-persist/lib/storage'

export default reducers => {
  const compressor = createCompressor({
    blacklist: ['unitSelected'],
  })

  const persistedReducer = persistReducer(
    {
      key: 'intelup@platform',
      storage,
      transforms: [compressor],
      whitelist: [
        'auth',
        'user',
        'unitSelected',
        'accessControl',
        'dateConfig',
      ],
    },
    reducers
  )

  return persistedReducer
}
