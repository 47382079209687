import React from 'react'
import { useIntl } from 'react-intl'
import ReactQuill from 'react-quill'

import styled from 'styled-components'

import 'react-quill/dist/quill.snow.css'

const StyledReactQuill = styled(ReactQuill)`
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='small']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='small']::before {
    content: '${props => props.i18n.small || 'Small'}';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '${props => props.i18n.normal || 'Normal'}';
  }

  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='large']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='large']::before {
    content: '${props => props.i18n.large || 'Large'}';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value='huge']::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value='huge']::before {
    content: '${props => props.i18n.huge || 'Huge'}';
  }

  .ql-snow .ql-picker {
    color: rgba(106, 111, 123, 0.75) !important;
  }

  .ql-toolbar.ql-snow {
    border: 1px solid rgb(209, 209, 209);
    border-radius: 6px 6px 0 0;
  }

  .ql-container.ql-snow {
    border: 1px solid rgb(209, 209, 209);
    border-radius: 0 0 6px 6px;
    height: 150px;
  }

  .ql-snow .ql-stroke {
    stroke: rgba(106, 111, 123, 0.75) !important;
  }

  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: rgba(106, 111, 123, 0.75) !important;
  }
`

const RichTextInput = ({ ...props }) => {
  const intl = useIntl()

  return (
    <StyledReactQuill
      i18n={{
        small: intl.formatMessage({ id: 'components.richTextInput.small' }),
        normal: intl.formatMessage({ id: 'components.richTextInput.normal' }),
        large: intl.formatMessage({ id: 'components.richTextInput.large' }),
        huge: intl.formatMessage({ id: 'components.richTextInput.huge' }),
      }}
      theme="snow"
      modules={{
        toolbar: [
          [{ size: ['small', false, 'large', 'huge'] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote', 'code'],
          [{ color: [] }, { background: [] }],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['link'],
          ['clean'],
        ],
      }}
      formats={[
        'size',
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        'code',
        'background',
        'color',
        'list',
        'bullet',
        'indent',
        'link',
      ]}
      style={{
        height: 190,
      }}
      {...props}
    />
  )
}

RichTextInput.defaultProps = {
  visibleModal: false,
  toggleModal: null,
  width: '60%',
}

// RichTextInput.propTypes = {
//   children: PropTypes.oneOfType([
//     PropTypes.element,
//     PropTypes.shape({}),
//     PropTypes.arrayOf(
//       PropTypes.oneOfType([
//         PropTypes.element,
//         PropTypes.arrayOf(PropTypes.element),
//       ])
//     ),
//   ]).isRequired,
//   toggleModal: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
//   visibleModal: PropTypes.bool,
//   width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// }

export default RichTextInput
