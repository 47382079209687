import produce from 'immer'

const INITIAL_STATE = {
  isOpenMenuMobile: false,
  isCollapsedSider: false,
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const newDraft = draft

    switch (action.type) {
      case '@siderMenu/TOGGLE_MOBILE': {
        newDraft.isOpenMenuMobile = !newDraft.isOpenMenuMobile
        break
      }
      case '@siderMenu/TOGGLE_COLLAPSE_SIDER': {
        newDraft.isCollapsedSider = !newDraft.isCollapsedSider
        break
      }

      default:
    }
  })
