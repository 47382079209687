export function checkContractRequest() {
  return {
    type: '@contract/REQUEST',
  }
}

export function checkContractSuccess(isExpired, expiredDate) {
  return {
    type: '@contract/SUCCESS',
    payload: { isExpired, expiredDate },
  }
}

export function checkContractFailure(error) {
  return {
    type: '@contract/FAILURE',
    payload: { error },
  }
}
