import { REHYDRATE } from 'redux-persist/lib/constants'
import { takeLatest, call, put, all } from 'redux-saga/effects'

import api from 'services/api'

import { checkWhiteLabelSuccess, checkWhiteLabelFailure } from './actions'

export function* checkWhiteLabel() {
  try {
    const file_name = window.location.host
      .split('.')
      .join('_')
      .replace(':', '_')

    const { data } = yield call(api.get, `config/${file_name}`)

    yield put(checkWhiteLabelSuccess(data))
  } catch (error) {
    yield put(checkWhiteLabelFailure('Erro ao tentar consultar o contrato'))
  }
}

export default all([
  takeLatest('@whiteLabel/REQUEST', checkWhiteLabel),
  takeLatest(REHYDRATE, checkWhiteLabel),
])
