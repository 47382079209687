import { Preferences } from '@capacitor/preferences'
import adalProvider from 'pages/auth/ADFS/adalProvider'
import { takeLatest, call, fork, put, all } from 'redux-saga/effects'

import api from 'services/api'

import { accessControlSuccess } from '../accessControl/actions'
import { unitsSuccess } from '../units/actions'
import { setUnitSelected } from '../unitSelected/actions'
import { signOutSuccess, signInSuccess, signInFailure } from './actions'

export function* signIn({ payload }) {
  try {
    const { username, password } = payload

    const healthcheckResponse = yield call(
      api.get,
      'miscellaneous/signals/healthcheck'
    )

    if (healthcheckResponse.data.status !== 'pass') {
      throw new Error('healthcheck failed')
    }

    const response = yield call(api.post, 'CAC/actions/login', {
      username,
      password,
    })

    const {
      status,
      data: [data],
      units,
      blockUserSituation,
      tentativasLogin,
    } = response.data

    let userDataPermission = true
    let forcePasswordChange = null

    if (data) {
      userDataPermission = data.usu_permissao_dados
      forcePasswordChange = data.usu_forcar_nova_senha
    }

    if (status === 'UNAUTHORIZED_UNITS') {
      return yield put(signInFailure('auth.signin.unauthorized'))
    }

    if (status === 'UNAUTHORIZED') {
      return yield put(signInFailure('auth.signin.failure'))
    }

    if (status === 'UNCONTRATED_UNITS') {
      return yield put(signInFailure('auth.signin.withoutContract'))
    }

    if (status === 'UNAUTHORIZED_PASSWORD') {
      return yield put(
        signInFailure(
          'auth.signin.failurePassword',
          blockUserSituation - tentativasLogin
        )
      )
    }

    if (status === 'UNAUTHORIZED_PASSWORD_LIMIT') {
      return yield put(signInFailure('auth.signin.failurePasswordLimit'))
    }

    if (status === 'UNAUTHORIZED_INACTIVE_USER') {
      return yield put(signInFailure('auth.signin.inactiveUser'))
    }

    const { access_token: token, accessControl, ...user } = data

    user.hasAccess = user.usu_tipo === '1'
    user.pltCode = user.plt_codigo
    user.cliCode = user.cli_codigo

    api.defaults.headers.common.Authorization = `Bearer ${token}`
    api.defaults.headers.common['client-code'] = data.cli_codigo
    api.defaults.headers.common['plt-code'] = data.plt_codigo
    api.defaults.headers.common['id-element'] = data.id_elemento

    yield fork(api.post, 'LOG/entities/DATA', {
      log_acao: 'log.logged',
      log_texto: 'log.actions.login',
      log_tipo: 'login',
      id_usuario: user.id_usuario,
    })

    yield put(accessControlSuccess(accessControl))
    yield put(unitsSuccess(units))

    yield call(Preferences.set, {
      key: 'clientCode',
      value: data.cli_codigo,
    })

    yield call(Preferences.set, {
      key: 'units',
      value: JSON.stringify(units),
    })

    yield call(Preferences.set, {
      key: 'token',
      value: token,
    })

    yield put(
      signInSuccess(token, user, userDataPermission, forcePasswordChange)
    )
    yield put(setUnitSelected(data))
  } catch (error) {
    console.error(error)
    try {
      const healthcheckResponse = yield call(
        api.get,
        'miscellaneous/signals/healthcheck'
      )

      if (healthcheckResponse.data.status !== 'pass') {
        throw new Error('healthcheck failed')
      }

      yield put(signInFailure('auth.signin.failure'))
    } catch {
      yield put(signInFailure('global.error.messages.serverError'))
    }
  }

  return true
}
export function* signInADFS({ payload }) {
  try {
    const { username, id, client } = payload

    const healthcheckResponse = yield call(
      api.get,
      'miscellaneous/signals/healthcheck'
    )

    if (healthcheckResponse.data.status !== 'pass') {
      throw new Error('healthcheck failed')
    }

    const response = yield call(api.post, 'CAC/actions/loginADFS', {
      username,
      id,
      client,
    })
    const {
      status,
      data: [data],
      units,
    } = response.data

    if (status === 'UNAUTHORIZED') {
      return yield put(signInFailure('auth.signin.unauthorized'))
    }

    const { access_token: token, accessControl, ...user } = data

    user.hasAccess = user.usu_tipo === '1'
    user.pltCode = user.plt_codigo
    user.cliCode = user.cli_codigo

    api.defaults.headers.common.Authorization = `Bearer ${token}`
    api.defaults.headers.common['client-code'] = data.cli_codigo
    api.defaults.headers.common['plt-code'] = data.plt_codigo
    api.defaults.headers.common['id-element'] = data.id_elemento

    yield fork(api.post, 'LOG/entities/DATA', {
      log_acao: 'log.logged',
      log_texto: 'log.actions.login',
      log_tipo: 'login',
      id_usuario: user.id_usuario,
    })

    yield put(accessControlSuccess(accessControl))
    yield put(unitsSuccess(units))

    yield put(signInSuccess(token, user))
    yield put(setUnitSelected(data))
  } catch (error) {
    try {
      const healthcheckResponse = yield call(
        api.get,
        'miscellaneous/signals/healthcheck'
      )

      if (healthcheckResponse.data.status !== 'pass') {
        throw new Error('healthcheck failed')
      }

      yield put(signInFailure('auth.signin.failure'))
    } catch {
      yield put(signInFailure('global.error.messages.serverError'))
    }
  }

  return true
}

export function* signOut() {
  const adfs = JSON.parse(window.localStorage.getItem('persist:intelup@adfs'))

  yield window.localStorage.clear()
  yield window.sessionStorage.clear()

  if (adfs && Object.keys(adfs).length) {
    adalProvider.init({
      instance: adfs.instance,
      tenant: adfs.tenant,
      clientId: adfs.clientId,
    })

    adalProvider.logOutSilent()
  }

  if (adfs && adfs.domain) {
    yield put(signOutSuccess(adfs.domain))
  } else {
    yield put(signOutSuccess())
    // yield put(setUnitSelected(null))
  }
}

export default all([
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_IN_REQUEST_ADFS', signInADFS),
  takeLatest('@auth/SIGN_OUT', signOut),
])
