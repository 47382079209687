export function setAllFrames(data) {
  return {
    type: '@frames/SET_ALL',
    payload: { data },
  }
}

export function setAllFramesFailure(error) {
  return {
    type: '@frames/SET_ALL_FAILURE',
    payload: { error },
  }
}

export function setUnitFrames(data) {
  return {
    type: '@frames/SET_UNIT_FRAMES',
    payload: { data },
  }
}

export function setUnitFramesFailure(error) {
  return {
    type: '@frames/SET_UNIT_FRAMES_FAILURE',
    payload: { error },
  }
}

export function getAllFrames() {
  return {
    type: '@frames/GET_ALL',
  }
}

export function getAllFramesFailure(error) {
  return {
    type: '@frames/GET_ALL_FAILURE',
    payload: { error },
  }
}

export function getUnitFrames() {
  return {
    type: '@frames/GET_UNIT_FRAMES',
  }
}

export function getUnitFramesFailure(error) {
  return {
    type: '@frames/GET_UNIT_FRAMES_FAILURE',
    payload: { error },
  }
}

export function updateFrames(data) {
  return {
    type: '@frames/UPDATE',
    payload: { data },
  }
}

export function updateFramesFailure(error) {
  return {
    type: '@frames/UPDATE_FAILURE',
    payload: { error },
  }
}

export function addFrames(data) {
  return {
    type: '@frames/ADD',
    payload: { data },
  }
}

export function addFramesFailure(error) {
  return {
    type: '@frames/ADD_FAILURE',
    payload: { error },
  }
}

export function deleteFrames(data) {
  return {
    type: '@frames/UPDATE',
    payload: { data },
  }
}

export function deleteFramesFailure(error) {
  return {
    type: '@frames/DELETE_FAILURE',
    payload: { error },
  }
}
