export function setDateConfig(data) {
  return {
    type: '@dateConfig/SET',
    payload: { data },
  }
}

export function getDateConfig() {
  return {
    type: '@dateConfig/GET',
  }
}

export function getDateConfigRequest() {
  return {
    type: '@dateConfig/GET',
  }
}

export function dateConfigFailure(error) {
  return {
    type: '@dateConfig/FAILURE',
    payload: { error },
  }
}
