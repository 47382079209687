import { REHYDRATE } from 'redux-persist/lib/constants'
import {
  takeLatest,
  takeLeading,
  call,
  put,
  all,
  delay,
  fork,
} from 'redux-saga/effects'

import api from 'services/api'

import {
  getDateConfigRequest,
  setDateConfig,
  dateConfigFailure,
} from './actions'

export function* checkTick() {
  const minutes = 15
  const minutesToMs = m => m * 60 * 1000

  while (true) {
    yield delay(minutesToMs(minutes))
    yield put(getDateConfigRequest())
  }
}

function* checkTimer() {
  // starts the task in the background
  yield fork(checkTick)
}

export function* getDateConfig() {
  try {
    if (api.defaults.headers.common.Authorization) {
      const response = yield call(api.get, 'EST/entities/PERIODO')

      const data = response.data.data[0]

      let shifts = [
        {
          description: data?.per_t1_desc,
          start: data?.per_t1_ini,
          end: data?.per_t1_fim,
        },
        {
          description: data?.per_t2_desc,
          start: data?.per_t2_ini,
          end: data?.per_t2_fim,
        },
        {
          description: data?.per_t3_desc,
          start: data?.per_t3_ini,
          end: data?.per_t3_fim,
        },
        {
          description: data?.per_t4_desc,
          start: data?.per_t4_ini,
          end: data?.per_t4_fim,
        },
      ]

      shifts = shifts
        .filter(elem => elem.start && elem.end)
        .map((elem, index) => ({
          id: index,
          description: elem.description,
          start: elem.start,
          end: elem.end,
        }))

      const config = {
        timezone: data?.timezone,
        idPeriod: data?.id_periodo,
        idElement: data?.id_elemento,
        shifts,
        dayOffset: Math.abs(data?.day_offset),
        day: {
          startHour: data?.per_d_ini,
        },
        startWeek: parseInt(data?.per_s_tp, 10),
        harvest: {
          start: data?.per_sf_ini,
          end: data?.per_sf_fim,
        },
      }

      if (data) {
        yield put(setDateConfig(config))
      }
    }
  } catch (err) {
    yield put(
      dateConfigFailure(
        err.message ||
          'Houve um problema com a api, por favor, entre em contato conosco e ajude-nos a resolver.'
      )
    )
  }
}

export default all([
  takeLatest('@dateConfig/GET', getDateConfig),
  takeLeading(REHYDRATE, checkTimer),
])
