import produce from 'immer'

const INITIAL_STATE = {
  currentTheme: 'light',
}

export default (state = INITIAL_STATE, action) =>
  produce(state, draft => {
    const newDraft = draft

    switch (action.type) {
      case '@theme/SET_CURRENT_THEME': {
        newDraft.currentTheme = action.payload.currentTheme
        break
      }

      default:
    }
  })
