function getValidRoute(routes) {
  const flatRoutes = routes
    .map(r => (r.children ? r.children : r))
    .flat()
    .map(r => (r.children ? r.children : r))
    .flat()
    .map(r => r.to.pathname)

  return flatRoutes[0]
}

export default getValidRoute
