import produce from 'immer'

export const initialState = {
  data: {
    clientFrames: [],
    unitFrames: [],
  },
  error: null,
}

export default (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const newDraft = draft

    switch (type) {
      case '@frames/SET_ALL': {
        newDraft.data.clientFrames = payload.data
        break
      }
      case '@frames/SET_ALL_CHUNKS': {
        newDraft.data.clientFrames.push(...payload.data)
        break
      }
      case '@frames/SET_ALL_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@frames/SET_UNIT_FRAMES': {
        newDraft.data.unitFrames = payload.data
        break
      }
      case '@frames/SET_UNIT_FRAMES_CHUNKS': {
        newDraft.data.unitFrames.push(...payload.data)
        break
      }
      case '@frames/SET_UNIT_FRAMES_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@frames/GET_ALL': {
        newDraft.error = null
        break
      }
      case '@frames/GET_ALL_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@frames/GET_UNIT_FRAMES': {
        newDraft.error = null
        break
      }
      case '@frames/GET_UNIT_FRAMES_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@frames/UPDATE': {
        newDraft.data = payload.data
        break
      }
      case '@frames/UPDATE_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@frames/ADD': {
        newDraft.data = payload.data
        break
      }
      case '@frames/ADD_FAILURE': {
        newDraft.error = payload.error
        break
      }
      case '@frames/DELETE': {
        newDraft.data = payload.data
        break
      }
      case '@frames/DELETE_FAILURE': {
        newDraft.error = payload.error
        break
      }
      default:
    }
  })
