import styled from 'styled-components'

export const Logo = styled.img`
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
`

export const LogoPlaceholder = styled.div`
  max-width: 100%;
  height: 64px;
  margin-bottom: 20px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
`
