import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Grid } from 'antd'
import {
  toggleCollapseSider,
  toggleMenuMobile,
} from 'store/modules/siderMenu/actions'

// import useIsMobileWidth from 'hooks/useIsMobileWidth'

const { useBreakpoint } = Grid

function CollapseButton() {
  const dispatch = useDispatch()
  // const [isMobile] = useIsMobileWidth()
  const screens = useBreakpoint()

  const [isCollapsedSider, isOpenMenuMobile] = useSelector(state => [
    state.siderMenu.isCollapsedSider,
    state.siderMenu.isOpenMenuMobile,
  ])

  function collapse() {
    if (!screens.xl) {
      return dispatch(toggleMenuMobile())
    }

    return dispatch(toggleCollapseSider())
  }

  const conditionalMenu = !screens.xl ? isOpenMenuMobile : isCollapsedSider

  useEffect(() => {
    setTimeout(() => window.dispatchEvent(new Event('resize')), 50)
    setTimeout(() => window.dispatchEvent(new Event('resize')), 150)
    setTimeout(() => window.dispatchEvent(new Event('resize')), 500)
    setTimeout(() => window.dispatchEvent(new Event('resize')), 1500)
  }, [conditionalMenu])

  const style = {
    fontSize: '18px',
    lineHeight: '64px',
    padding: '0 15px',
    cursor: 'pointer',
    transition: 'color 0.3s',
  }

  return conditionalMenu ? (
    <MenuUnfoldOutlined data-testid="unfold" style={style} onClick={collapse} />
  ) : (
    <MenuFoldOutlined data-testid="fold" style={style} onClick={collapse} />
  )
}

export default CollapseButton
