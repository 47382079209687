import React from 'react'
import { useIntl } from 'react-intl'

import { Tooltip, Avatar } from 'antd'
import ColorHash from 'color-hash'
import PropTypes from 'prop-types'

import { Container, StyledAvatar, RestAvatar } from './styles'

const nameInitials = name => {
  const initials = name.match(/\b\w/g) || []
  return ((initials.shift() || '') + (initials.pop() || ''))
    .toUpperCase()
    .substring(0, 2)
}

const colorHash = new ColorHash()

const AvatarList = ({ list, listLimit, style }) => {
  const restList = list.filter((_item, index) => index + 1 > listLimit)
  const intl = useIntl()

  return (
    <Container style={style}>
      {list
        .filter((_item, index) => index + 1 <= listLimit)
        .map(item => (
          <Tooltip
            key={item.id}
            title={
              item.usu_login
                ? `${item.title} - ${item.usu_login}`
                : item.title || '-'
            }
          >
            {item.src ? (
              <StyledAvatar alt={item.title} icon={item.icon} src={item.src} />
            ) : (
              <Avatar
                style={{
                  backgroundColor: colorHash.hex(item.title),
                  verticalAlign: 'middle',
                }}
              >
                {item.title && nameInitials(item.title)}
              </Avatar>
            )}
          </Tooltip>
        ))}

      {!!restList.length && (
        <Tooltip
          title={`${intl.formatMessage({ id: 'global.labels.more' })} +${
            restList.length
          }`}
        >
          <RestAvatar>+{restList.length}</RestAvatar>
        </Tooltip>
      )}
    </Container>
  )
}

AvatarList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      icon: PropTypes.element,
      src: PropTypes.string,
    })
  ),
  listLimit: PropTypes.number,
  style: PropTypes.shape({}),
}

AvatarList.defaultProps = {
  list: [],
  listLimit: 10,
  style: {},
}

export default AvatarList
