export function unitsRequest() {
  return {
    type: '@units/UNITS_REQUEST',
  }
}

export function unitsSuccess(units) {
  return {
    type: '@units/UNITS_SUCCESS',
    payload: { units },
  }
}

export function unitsFailure(error) {
  return {
    type: '@units/UNITS_FAILURE',
    payload: { error },
  }
}
