import React, { createContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

import PropTypes from 'prop-types'

const ThemeContext = createContext({
  currentTheme: null,
})

export const ThemeProvider = ({ children }) => {
  const theme = useSelector(state => state.theme.currentTheme)

  const dataContext = useMemo(() => {
    const currentTheme = theme

    return { currentTheme }
  }, [theme])

  return (
    <ThemeContext.Provider value={dataContext}>
      {children}
    </ThemeContext.Provider>
  )
}

ThemeProvider.propTypes = {
  children: PropTypes.element.isRequired,
}

export default ThemeContext
