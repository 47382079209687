import produce from 'immer'

const initialState = {
  isExpired: false,
  expiredDate: null,
  loading: false,
  error: null,
}

export default (state = initialState, { type, payload }) =>
  produce(state, draft => {
    const newDraft = draft

    switch (type) {
      case '@contract/REQUEST': {
        newDraft.error = null
        newDraft.loading = true
        break
      }
      case '@contract/SUCCESS': {
        newDraft.isExpired = payload.isExpired
        newDraft.expiredDate = payload.expiredDate
        newDraft.loading = false
        break
      }
      case '@contract/FAILURE': {
        newDraft.error = payload.error
        newDraft.loading = false
        break
      }
      default:
    }
  })
