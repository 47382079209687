import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useSelector, useDispatch } from 'react-redux'

import { Input, Button, Row, Col, message, Form, Space } from 'antd'
import PropTypes from 'prop-types'

import AntModal from 'components/AntModal'

import axiosClient from 'services/api'

import { signInSuccess } from '../../../../store/modules/auth/actions'

const ChangePassword = ({ visibleFormModal, setVisibleFormModal }) => {
  const { token } = useSelector(state => state.auth)
  const [loading, setLoading] = useState(false)
  const userProfile = useSelector(state => state.user?.profile)
  const intl = useIntl()
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const handleSubmit = async formValues => {
    setLoading(true)

    try {
      await axiosClient.put(`CAC/entities/USUARIO/${userProfile.id}`, {
        usu_senha: formValues.newPassword,
        usu_forcar_nova_senha: 0,
      })

      const userSituation = await axiosClient.get(
        `CAC/entities/USUARIO/${userProfile.id}`
      )

      dispatch(
        signInSuccess(
          token,
          userSituation.data.data,
          userSituation.data.data.usu_permissao_dados,
          userSituation.data.data.usu_forcar_nova_senha
        )
      )

      message.success(
        intl.formatMessage({ id: 'global.success.messages.update' })
      )
    } catch (error) {
      console.log(error)
      message.error(
        intl.formatMessage({ id: 'global.error.messages.defaultError' })
      )
    } finally {
      setLoading(false)
      setVisibleFormModal(false)
    }
  }

  const compareToFirstPassword = (rule, value, callback) => {
    if (value && value !== form.getFieldValue('newPassword')) {
      callback(
        intl.formatMessage({ id: 'auth.changePassword.comparePassword' })
      )
    } else {
      callback()
    }
  }

  const validatePassword = (rule, value, callback) => {
    if (value && value.length < 8) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.minimumCaracters',
        })
      )
    } else if (value && !/[A-Z]/.test(value)) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.upperCase',
        })
      )
    } else if (value && !/[a-z]/.test(value)) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.lowerCase',
        })
      )
    } else if (value && !/\d/.test(value)) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.number',
        })
      )
    } else if (value && !/[^a-zA-Z0-9]/.test(value)) {
      callback(
        intl.formatMessage({
          id: 'accessControl.users.validatePassword.specialCaracter',
        })
      )
    } else {
      callback()
    }
  }

  return (
    <AntModal
      visible={visibleFormModal}
      width="40%"
      title={intl.formatMessage({
        id: 'auth.changePassword.newPasswordNecessity',
      })}
      onOk={() => setVisibleFormModal(false)}
      onCancel={() => setVisibleFormModal(false)}
      closable={false}
      keyboard={false}
      maskClosable={false}
      footer={
        <Space>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            onClick={() => form.submit()}
          >
            {intl.formatMessage({ id: 'global.button.save' })}
          </Button>
        </Space>
      }
    >
      <Row justify="center">
        <Col span={16}>
          <Form
            onFinish={handleSubmit}
            colon={false}
            form={form}
            layout="vertical"
          >
            <Form.Item
              label={intl.formatMessage({
                id: 'auth.changePassword.newPassword',
              })}
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'global.labels.requiredField',
                  }),
                },
                {
                  validator: validatePassword,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label={intl.formatMessage({
                id: 'auth.changePassword.confirmPassword',
              })}
              name="confirmNewPassword"
              rules={[
                {
                  required: true,
                  message: intl.formatMessage({
                    id: 'global.labels.requiredField',
                  }),
                },
                {
                  validator: compareToFirstPassword,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </AntModal>
  )
}

ChangePassword.propTypes = {
  visibleFormModal: PropTypes.bool.isRequired,
  setVisibleFormModal: PropTypes.func.isRequired,
}

export default ChangePassword
