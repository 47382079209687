/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useCallback, useEffect, useRef } from 'react'

import PropTypes from 'prop-types'

import './onHoverStyle.css'
import useUpdateEffect from 'hooks/useUpdateEffect'

const SCROLL_BOX_MIN_HEIGHT = 20

function Scrollbar({ children, className, ...restProps }) {
  const [hovering, setHovering] = useState(false)
  const [scrollBoxHeight, setScrollBoxHeight] = useState(SCROLL_BOX_MIN_HEIGHT)
  const [scrollBoxTop, setScrollBoxTop] = useState(0)
  const [lastScrollThumbPosition, setScrollThumbPosition] = useState(0)
  const [isDragging, setDragging] = useState(false)
  const scrollHostRef = useRef()

  const handleMouseOver = useCallback(() => {
    if (!hovering) setHovering(true)
  }, [hovering])

  const handleMouseOut = useCallback(() => {
    if (hovering) setHovering(false)
  }, [hovering])

  const handleDocumentMouseUp = useCallback(
    e => {
      if (isDragging) {
        e.preventDefault()
        setDragging(false)
      }
    },
    [isDragging]
  )

  const handleDocumentMouseMove = useCallback(
    e => {
      if (isDragging) {
        e.preventDefault()
        e.stopPropagation()
        const scrollHostElement = scrollHostRef.current
        const { scrollHeight, offsetHeight } = scrollHostElement

        const deltaY = e.clientY - lastScrollThumbPosition
        const percentage = deltaY * (scrollHeight / offsetHeight)
        setScrollThumbPosition(e.clientY)
        setScrollBoxTop(
          Math.min(
            Math.max(0, scrollBoxTop + deltaY),
            offsetHeight - scrollBoxHeight
          )
        )
        scrollHostElement.scrollTop = Math.min(
          scrollHostElement.scrollTop + percentage,
          scrollHeight - offsetHeight
        )
      }
    },
    [isDragging, lastScrollThumbPosition, scrollBoxHeight, scrollBoxTop]
  )

  const handleScrollThumbMouseDown = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
    setScrollThumbPosition(e.clientY)
    setDragging(true)
  }, [])

  const handleScroll = useCallback(() => {
    if (!scrollHostRef) {
      return
    }
    const scrollHostElement = scrollHostRef.current
    const { scrollTop, scrollHeight, offsetHeight } = scrollHostElement

    let newTop =
      (parseInt(scrollTop, 10) / parseInt(scrollHeight, 10)) * offsetHeight
    newTop = Math.min(newTop, offsetHeight - scrollBoxHeight)
    setScrollBoxTop(newTop)
  }, [scrollBoxHeight])

  useUpdateEffect(() => {
    const scrollHostElement = scrollHostRef.current
    const { clientHeight, scrollHeight } = scrollHostElement
    const scrollThumbPercentage = clientHeight / scrollHeight
    const scrollThumbHeight = Math.max(
      scrollThumbPercentage * clientHeight,
      SCROLL_BOX_MIN_HEIGHT
    )

    setScrollBoxHeight(scrollThumbHeight)
    scrollHostElement.addEventListener('scroll', handleScroll, true)
    return function cleanup() {
      scrollHostElement.removeEventListener('scroll', handleScroll, true)
    }
  }, [handleScroll, scrollHostRef])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDocumentDragStart = _ => {
    if (!isDragging) {
      setDragging(true)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDocumentDragStop = _ => {
    if (isDragging) {
      setDragging(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousemove', handleDocumentMouseMove)
    document.addEventListener('mouseup', handleDocumentMouseUp)
    document.addEventListener('mouseleave', handleDocumentMouseUp)
    document.addEventListener('dragstart', handleDocumentDragStart, false)
    document.addEventListener('dragend', handleDocumentDragStop, false)

    return function cleanup() {
      document.removeEventListener('mousemove', handleDocumentMouseMove)
      document.removeEventListener('mouseup', handleDocumentMouseUp)
      document.removeEventListener('mouseleave', handleDocumentMouseUp)
      document.removeEventListener('dragstart', handleDocumentDragStart, false)
      document.removeEventListener('dragend', handleDocumentDragStop, false)
    }
  }, [
    handleDocumentDragStart,
    handleDocumentDragStop,
    handleDocumentMouseMove,
    handleDocumentMouseUp,
  ])

  return (
    <div
      className="scrollhost-container"
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      <div
        ref={scrollHostRef}
        className={`scrollhost ${className}`}
        {...restProps}
      >
        {children}
      </div>
      <div className="scroll-bar" style={{ opacity: hovering ? 1 : 0 }}>
        <div
          aria-hidden="true"
          className="scroll-thumb"
          style={{
            height: scrollBoxHeight,
            top: scrollBoxTop,
          }}
          onMouseDown={handleScrollThumbMouseDown}
        />
      </div>
    </div>
  )
}

Scrollbar.propTypes = {
  children: PropTypes.element.isRequired,
  className: PropTypes.string.isRequired,
}

export default Scrollbar
