import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment'

const FromNow = ({
  date,
  formatDay = 'DDD',
  formatMonth = 'MM',
  formatYear = 'YYYY',
}) => {
  const [currentDate, setCurrentDate] = useState(null)
  const localeKey = useSelector(state => state.locale.key)

  function useInterval(callback, delay) {
    const savedCallback = useRef()

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current()
      }
      if (delay !== null) {
        const id = setInterval(tick, delay)
        return () => clearInterval(id)
      }

      return null
    }, [delay])
  }

  const checkDate = () => {
    const now = moment()

    if (now.diff(date, 'days', true) < 1) {
      setCurrentDate(date.locale(localeKey).fromNow())
    } else if (now.diff(date, 'days', true) < 7) {
      setCurrentDate(date.locale(localeKey).format(formatDay))
    } else if (date.format('YYYY') === now.format('YYYY')) {
      setCurrentDate(date.locale(localeKey).format(formatMonth))
    } else {
      setCurrentDate(date.locale(localeKey).format(formatYear))
    }
  }

  useInterval(() => {
    checkDate()
  }, 1000)

  return currentDate
}

export default FromNow
