import PerfectScrollBar from 'react-perfect-scrollbar'

import { Timeline } from 'antd'
import styled from 'styled-components'

export const LeftTimeline = styled(Timeline)`
  .ant-timeline-item-label {
    text-align: left !important;
  }

  .ant-timeline-item-head {
    left: 17% !important;
  }

  .ant-timeline-item-tail {
    left: 17% !important;
  }

  .ant-timeline-item-content {
    left: calc(17% - 4px) !important;
    width: calc(85% - 14px) !important;
    text-align: left !important;
  }
`

export const ContainerScroll = styled(PerfectScrollBar)`
  height: 250px;

  .ps__rail-x {
    display: none !important;
  }
`

export const ContainerSpin = styled.div`
  position: absolute;
  bottom: 40px;
  width: 100px;
  text-align: center;
`
